import * as React from 'react';
import {FC} from 'react';

interface IHelpProps {
}

const Help: FC<IHelpProps> = (props) => {
  return <>Help</> ;
};

export default Help;