import * as React from 'react';
import { Box, Button, Container, Grid, Typography, Link } from '@material-ui/core';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { post08Detail } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../../AppConfig';

// import your fontawesome library

interface IExpertBusinessOwnersProps {}

const WhySmallBusinessOwners: FC<IExpertBusinessOwnersProps> = (props) => {
    return (
        <>
            <Box className="top-inner-banner top-lg mb-0">
                <figure className="h-100">
                    <img src={post08Detail} alt="img" />
                </figure>
            </Box>
            <Box className="container">
                <Box className="row">
                    <Box className="col-12">
                        <Box className="insight-content-top d-flex justify-content-end">
                            <div className="insight-share">
                                {/* https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fglginsights.com%2Farticles%2Fis-inflation-coming-to-the-united-states%2F&amp;title=Is%20Inflation%20Coming%20to%20the%20United%20States%3F */}
                                <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fmarketlync.com/insights/Why-Small-Business-Owners-Need-To-Be-Good-Leaders%2F&amp;title=Why%20Small%20Business%20Owners%20Need%20To%20Be%20Good%20Leaders" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-linkedin-in fa-w-14">
                                        <path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                                    </svg>
                                </a>
                                <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmarketlync.com/insights/Why-Small-Business-Owners-Need-To-Be-Good-Leaders" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-facebook-f fa-w-10">
                                        <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                    </svg>
                                </a>
                                <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fmarketlync.com/insights/Why-Small-Business-Owners-Need-To-Be-Good-Leaders%2F&amp;via=MarketLync&amp;text=Why%20Small%20Business%20Owners%20Need%20To%20Be%20Good%20Leaders" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-twitter fa-w-16">
                                        <path
                                            fill="currentColor"
                                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                        ></path>
                                    </svg>
                                </a>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box component="section" className="bpost-wrapper">
                <Box className="container">
                    <Box className="row">
                        <Box className="col-12 col-md-5 col-lg-4">
                            <div className="insight-content-header">
                                <h1 className="insight-content-title">Why Small Business Owners Need To Be Good Leaders (And Delegators)</h1>
                                <p className="insight-content-author">Amit Sachdeva</p>
                                <div className="insights-list-item-tags">
                                    <span>
                                        <span className="insights-list-item-tag">Leadership &amp; Management</span>
                                    </span>
                                </div>
                            </div>
                        </Box>
                        <Box className="col-12 col-md-7 col-lg-8">
                            <Box className="insight-content-body long-form-text">
                                <p>
                                    Have you heard that startup founders generally don’t make for the best CEOs? There are a{' '}
                                    <a href="https://www.entrepreneur.com/article/333712" target="_blank" rel="noreferrer" className="underline">
                                        few theories about why
                                    </a>{' '}
                                    , but one is that the skills required to get a business off the ground are very different from those needed to maintain it in the later stages. Above all, some owners are so happy wearing all the hats that they just can’t “let go” and delegate their tasks later on.
                                </p>
                                <p>Fortunately, you can overcome this common trap by developing your leadership and delegation skills — here’s how you can do it.</p>
                                <h2>
                                    <strong>Evaluate your strengths and weaknesses</strong>
                                </h2>

                                <p>
                                    There are only 24 hours in a day, and you need to sleep for at least some of them. You can’t expect yourself to do everything that needs doing in your business — and even if you had the time, it probably wouldn’t be the best way to use it. After all, nobody is good at
                                    <em>everything</em>.
                                </p>
                                <p>If you think you’re skilled at sales, marketing, operations, and just about everything else involved in running a business, you’re either superhuman or deluded. The second option is far more likely. </p>
                                <p>This means it’s time to take a step back and figure out the most effective way to use your time. What’s your competitive advantage? If you’re not sure, there are two main ways to find out: ask the people around you (e.g., your coworkers or family) or reflect on it yourself for the answer.</p>
                                <h4>
                                    <strong>Why not both?</strong>
                                </h4>

                                <p>
                                    One resource you might find useful is the{' '}
                                    <a href="https://www.gallup.com/cliftonstrengths/en/254033/strengthsfinder.aspx" target="_blank" rel="noreferrer" className="underline">
                                        StrengthsFinder
                                    </a>{' '}
                                    (now known as CliftonStrengths), an assessment that gives you a personalized report of what your strengths are and how to use them. You might also want to{' '}
                                    <a href="https://blog.mycorporation.com/2017/09/identify-play-leadership-strengths/" target="_blank" rel="noreferrer" className="underline">
                                        figure out whether you’re a
                                    </a>{' '}
                                    strategic, relationship-based, or results-driven leader.
                                </p>

                                <hr />
                                <h2>
                                    <strong>Delegate, delegate, delegate</strong>
                                </h2>
                                <p>Once you know what you don’t want to do, it’s time to figure out who’s going to do it instead.</p>

                                <p>In an ideal world, you’d already have employees in your business that make the perfect candidates for every task you want to delegate. Perfect — you just need to identify them and train them to be even better at their existing strengths.</p>
                                <p>But the more likely scenario is that it’s time to prepare for a recruitment drive.</p>

                                <p>If you don’t want to hire full-time employees, you could also consider hiring contractors or freelancers. Platforms like Upwork and Fiverr are the obvious solutions, and they’re certainly convenient, but they don’t always do the best job of vetting the top professionals. That’s problematic when you’re looking for people you can your business with.</p>

                                <hr />
                                <h2>
                                    <strong>Stop trying to be superman </strong>
                                </h2>
                                <p>Whether you believe leaders are born or made, it’s time to work with what you have to get your business to the best point possible. So, leave your ego at home and accept you can’t be good at everything.</p>

                                <p>
                                    And once you’ve done that, don’t forget to check out{' '}
                                    <a href="https://www.marketlync.com/" rel="noreferrer" className="underline">
                                        MarketLync
                                    </a>{' '}
                                    to find the experts you need to make informed business decisions.
                                </p>
                                <hr />
                            </Box>
                            <div className="ctct-inline-form" data-form-id="e43177e8-a8fc-4ecf-889c-c7e1a5d74b31"></div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box className="ml-home-action">
                <Container maxWidth="lg">
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                            <Box className="ml-hm">
                                <Typography variant="h2" className="heading-1 mb-5 text-capitalize">
                                    Sustain &amp; grow your small business with MarketLync
                                </Typography>
                                <Box className="d-flex flex-wrap justify-content-center">
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <RouterLink to="/contact/product-demo-request">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" /> Schedule a Free Demo{' '}
                                                </Button>
                                            </RouterLink>
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <Link href={`${BASE_URL}/auth/signup`} rel="noreferrer">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> Join MarketLync{' '}
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default WhySmallBusinessOwners;
