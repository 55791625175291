import * as React from 'react';
import { Box, Container, Grid, Link, Typography } from '@material-ui/core';
import { FC } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { imgBanner2 } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
// import your fontawesome library

interface ILearinganddiscoveryProps {}

const Learinganddiscovery: FC<ILearinganddiscoveryProps> = (props) => {
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
    return (
        <>
            <Box className="top-inner-banner">
                <figure className="h-100">
                    <img src={imgBanner2} alt="img" />
                </figure>
                <Box className="inner-banner-content">
                    <Typography variant="h1" className="text-display">
                        Learn &amp; Share Knowledge
                    </Typography>
                </Box>
            </Box>
            <Container maxWidth="lg">
                <Grid container justify="center" spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                        {/* <Typography variant="h2" className="ml-display-2">
                        One line of benefits sentence goes here
                    </Typography> */}
                        <Typography className="text-center mb-4 ">Our service thrives on collaboration between experts and users. Built on a powerful suite of core values: transparency, honesty, knowledge, and communication, our platform helps users ask questions, learn from the content posted by others, and grow collectively. Explore a multitude of experts from all walks of businesses, offering diverse insights, packed with immense value. By asking a question, you are opening a portal of knowledge that will help everyone to learn, grow, and succeed.</Typography>
                    </Grid>
                </Grid>

                {/* <Grid container justify="center">
                <Grid item xs={12} md={6} className="d-flex justify-content-center align-items-center mb-5">
                    <img src={imgVideo} alt="img" />
                </Grid>
            </Grid> */}
            </Container>
            <Grid container justify="center">
                <Grid item xs={12} md={6} className="typo-center">
                    <Typography variant="h2" className="ml-display-3">
                        Trending Insights
                    </Typography>
                </Grid>{' '}
            </Grid>

            <Container maxWidth="lg" className="trending-insights">
                <Grid container justify="center" spacing={4}>
                    <Grid item xs={12} md={4} lg={4}>
                        <Box className="trending-box">
                            <Typography variant="h2">Leadership</Typography>
                        </Box>{' '}
                        <List component="ul" className="trending-list">
                            <ListItem component="li" className="flex-column justify-content-start align-items-start" disableGutters>
                                <Link href="#" onClick={preventDefault}>
                                    <Box className="trnd-que">Why is good UI design so hard for some Why is good UI design so hard for some</Box>
                                    <Box className="trnd-ans">Improving on this does not begin with fgdff Improving on this does not begin with fgdff </Box>
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link href="#" onClick={preventDefault}>
                                    <Box className="trnd-que">Why is good UI design so hard for some Why is good UI design so hard for some</Box>
                                    <Box className="trnd-ans">Improving on this does not begin with fgdff Improving on this does not begin with fgdff </Box>
                                </Link>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Box className="trending-box">
                            <Typography variant="h2">Strategy</Typography>
                        </Box>{' '}
                        <List component="ul" className="trending-list">
                            <ListItem component="li" className="flex-column justify-content-start align-items-start" disableGutters>
                                <Link href="#" onClick={preventDefault}>
                                    <Box className="trnd-que">Why is good UI design so hard for some Why is good UI design so hard for some</Box>
                                    <Box className="trnd-ans">Improving on this does not begin with fgdff Improving on this does not begin with fgdff </Box>
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link href="#" onClick={preventDefault}>
                                    <Box className="trnd-que">Why is good UI design so hard for some Why is good UI design so hard for some</Box>
                                    <Box className="trnd-ans">Improving on this does not begin with fgdff Improving on this does not begin with fgdff </Box>
                                </Link>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Box className="trending-box">
                            <Typography variant="h2">R&amp;D</Typography>
                        </Box>{' '}
                        <List component="ul" className="trending-list">
                            <ListItem component="li" className="flex-column justify-content-start align-items-start" disableGutters>
                                <Link href="#" onClick={preventDefault}>
                                    <Box className="trnd-que">Why is good UI design so hard for some Why is good UI design so hard for some</Box>
                                    <Box className="trnd-ans">Improving on this does not begin with fgdff Improving on this does not begin with fgdff </Box>
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link href="#" onClick={preventDefault}>
                                    <Box className="trnd-que">Why is good UI design so hard for some Why is good UI design so hard for some</Box>
                                    <Box className="trnd-ans">Improving on this does not begin with fgdff Improving on this does not begin with fgdff </Box>
                                </Link>
                            </ListItem>
                        </List>
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                        <Box className="trending-box">
                            <Typography variant="h2">Marketing</Typography>
                        </Box>{' '}
                        <List component="ul" className="trending-list">
                            <ListItem component="li" className="flex-column justify-content-start align-items-start" disableGutters>
                                <Link href="#" onClick={preventDefault}>
                                    <Box className="trnd-que">Why is good UI design so hard for some Why is good UI design so hard for some</Box>
                                    <Box className="trnd-ans">Improving on this does not begin with fgdff Improving on this does not begin with fgdff </Box>
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link href="#" onClick={preventDefault}>
                                    <Box className="trnd-que">Why is good UI design so hard for some Why is good UI design so hard for some</Box>
                                    <Box className="trnd-ans">Improving on this does not begin with fgdff Improving on this does not begin with fgdff </Box>
                                </Link>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Box className="trending-box">
                            <Typography variant="h2">Product Management</Typography>
                        </Box>{' '}
                        <List component="ul" className="trending-list">
                            <ListItem component="li" className="flex-column justify-content-start align-items-start" disableGutters>
                                <Link href="#" onClick={preventDefault}>
                                    <Box className="trnd-que">Why is good UI design so hard for some Why is good UI design so hard for some</Box>
                                    <Box className="trnd-ans">Improving on this does not begin with fgdff Improving on this does not begin with fgdff </Box>
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link href="#" onClick={preventDefault}>
                                    <Box className="trnd-que">Why is good UI design so hard for some Why is good UI design so hard for some</Box>
                                    <Box className="trnd-ans">Improving on this does not begin with fgdff Improving on this does not begin with fgdff </Box>
                                </Link>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Box className="trending-box">
                            <Typography variant="h2">Sales &amp; Service</Typography>
                        </Box>{' '}
                        <List component="ul" className="trending-list">
                            <ListItem component="li" className="flex-column justify-content-start align-items-start" disableGutters>
                                <Link href="#" onClick={preventDefault}>
                                    <Box className="trnd-que">Why is good UI design so hard for some Why is good UI design so hard for some</Box>
                                    <Box className="trnd-ans">Improving on this does not begin with fgdff Improving on this does not begin with fgdff </Box>
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link href="#" onClick={preventDefault}>
                                    <Box className="trnd-que">Why is good UI design so hard for some Why is good UI design so hard for some</Box>
                                    <Box className="trnd-ans">Improving on this does not begin with fgdff Improving on this does not begin with fgdff </Box>
                                </Link>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
                <Grid container justify="center" className="mb-5">
                    <Link className="loadMore pointer">
                        View More
                        <FontAwesomeIcon icon={faCaretDown} size="lg" className="ml-2" />
                    </Link>
                </Grid>
            </Container>
        </>
    );
};

export default Learinganddiscovery;
