import * as React from 'react';
import { Box, Button, Container, Grid, Typography, Link } from '@material-ui/core';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { post01Detail } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../../AppConfig';

// import your fontawesome library

interface IExpertconsultationsProps {}

const MarketingForSmallBusinesses: FC<IExpertconsultationsProps> = (props) => {
    return (
        <>
            <Box className="top-inner-banner top-lg mb-0">
                <figure className="h-100">
                    <img src={post01Detail} alt="img" />
                </figure>
            </Box>
            <Box className="container">
                <Box className="row">
                    <Box className="col-12">
                        <Box className="insight-content-top d-flex justify-content-end">
                            <div className="insight-share">
                                {/* <Link href={`${BASE_URL}/auth/signup`} rel="noreferrer">
                                    <FontAwesomeIcon icon={faLinkedin} className="mr-2" /> Join MarketLync{' '}
                                </Link> */}
                                <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fmarketlync.com/insights/3-Key-Types-of-Digital-and-Social-Media-Marketing-for-Small-Businesses%2F&amp;title=3%20Key%20Types%20Of%20Digital%20And%20Social%20Media%20Marketing%20For%20Small%20Businesses" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-linkedin-in fa-w-14">
                                        <path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                                    </svg>
                                </a>
                                <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmarketlync.com/insights/3-Key-Types-of-Digital-and-Social-Media-Marketing-for-Small-Businesses" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-facebook-f fa-w-10">
                                        <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                    </svg>
                                </a>
                                <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fmarketlync.com/insights/3-Key-Types-of-Digital-and-Social-Media-Marketing-for-Small-Businesses%2F&amp;via=MarketLync&amp;text=3%20Key%20Types%20Of%20Digital%20And%20Social%20Media%20Marketing%20For%20Small%20Businesses" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-twitter fa-w-16">
                                        <path
                                            fill="currentColor"
                                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                        ></path>
                                    </svg>
                                </a>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box component="section" className="bpost-wrapper">
                <Box className="container">
                    <Box className="row">
                        <Box className="col-12 col-md-5 col-lg-4">
                            <div className="insight-content-header">
                                <h1 className="insight-content-title">3 Key Types of Digital and Social Media Marketing for Small Businesses</h1>
                                <p className="insight-content-author">Amit Sachdeva</p>
                                <div className="insights-list-item-tags">
                                    <span>
                                        <span className="insights-list-item-tag">Digital &amp; Social Media Marketing</span>
                                    </span>
                                </div>
                            </div>
                        </Box>
                        <Box className="col-12 col-md-7 col-lg-8">
                            <Box className="insight-content-body long-form-text">
                                <p>If (like most small business owners) you’re short on time and resources, it’s tempting to think of marketing as nothing more than a nice-to-have. But how do you expect to grow your business if your target market doesn’t even know it exists?</p>
                                <p>Advertising is a must, and thanks to the internet and social media, it’s more accessible than ever before. Here’s a quick primer to get you up to speed with the world of digital marketing.</p>
                                <h2>
                                    <strong>What is Digital Marketing?</strong>
                                </h2>
                                <p>Marketing is a broad term encompassing any strategies that organizations or individuals use to promote themselves. Digital marketing simply narrows this down to anything that takes place online.</p>

                                <p>The most popular tactics include:</p>
                                <ul>
                                    <li>Social media</li>
                                    <li>Content marketing</li>
                                    <li>Online advertising campaigns</li>
                                    <li>SEO</li>
                                    <li>Email marketing</li>
                                </ul>
                                <p>
                                    SEO is all about{' '}
                                    <a href="https://mailchimp.com/marketing-glossary/email-marketing/" target="_blank" rel="noreferrer" className="underline">
                                        building a site that ranks well
                                    </a>{' '}
                                    on search engines, while{' '}
                                    <a href="https://mailchimp.com/marketing-glossary/email-marketing/" target="_blank" rel="noreferrer" className="underline">
                                        email marketing involves
                                    </a>{' '}
                                    keeping leads interested through email outreach. As for the other three aspects, we’ll be covering them below.
                                </p>
                                <hr />
                                <h2>
                                    <strong>Content Marketing</strong>
                                </h2>
                                <p>What do you do when you want the answer to a burning question? You Google it, of course. By figuring out what questions potential customers are asking search engines before they make a purchase, you can get on their radar at the perfect time.</p>
                                <p>Content marketing involves publishing blog posts, videos, and other types of content that aim to educate and/or engage consumers — all with the aim of nudging them toward buying from you.</p>
                                <p>
                                    If you’re serious about your content marketing strategy, it’s worth investing in a tool like{' '}
                                    <a href="https://ahrefs.com/" target="_blank" rel="noreferrer" className="underline">
                                        Ahrefs
                                    </a>{' '}
                                    or{' '}
                                    <a href="https://www.semrush.com/" target="_blank" rel="noreferrer" className="underline">
                                        Semrush
                                    </a>
                                    , but you can get some free info about what people are searching for through sites like{' '}
                                    <a href="https://answerthepublic.com/" target="_blank" rel="noreferrer" className="underline">
                                        AnswerThePublic
                                    </a>
                                    .
                                </p>
                                <p>Social media is another part of content marketing, which we’ll get to now.</p>
                                <hr />
                                <h2>
                                    <strong>Social Media and Online Advertising</strong>
                                </h2>
                                <p>
                                    <a href="https://backlinko.com/social-media-users" target="_blank" rel="noreferrer" className="underline">
                                        50.64% of the entire world
                                    </a>{' '}
                                    is on social media. Why wouldn’t you want to take advantage of that?
                                </p>
                                <p>The first step is figuring out which platform to use, which depends on your target market — are they startup founders who spend all day on Twitter on Gen Z-ers who can’t get enough of TikTok?</p>
                                <p>Once you’ve decided on your social media site of choice, it’s all about posting consistently high-quality content.</p>
                                <p>Another option is to run advertising campaigns on social media. Speaking of which…</p>
                                <hr />
                                <h2>
                                    <strong>Running Advertising Campaigns </strong>
                                </h2>
                                <p>Although social media posting and content marketing can be very effective, they’re slow burners (unless you get very lucky).</p>
                                <p>If you want quick results, running an advertising campaign could be a better option. In a world of short attention spans and a sea of content, advertising guarantees that your message will be seen by the people you want to see it.</p>
                                <p>You’ll come across Pay-Per-Click (PPC) a lot in the world of social media and online advertising — it’s used by everyone from Google to Instagram. Essentially, this means you’ll only pay when a user clicks on your advert (instead of a fixed cost for running the campaign).</p>
                                <hr />
                                <h2>
                                    <strong>Make Your Digital Marketing Plan</strong>
                                </h2>
                                <p>The potential of digital and social media marketing is infinite, but your time isn’t. If you want to get as much out of advertising as possible, bringing in athird party is your best bet.</p>
                                <p>
                                    On{' '}
                                    <a href="https://www.marketlync.com/" rel="noreferrer" className="underline">
                                        MarketLync
                                    </a>
                                    , we connect small business owners with experts in specific industries and business functions — including (you guessed it) digital marketing and social media advertising. Why not check us out to find out if there’s a specialist who can help you reach your goals?
                                </p>
                                <hr />
                            </Box>
                            <div className="ctct-inline-form" data-form-id="e43177e8-a8fc-4ecf-889c-c7e1a5d74b31"></div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box className="ml-home-action">
                <Container maxWidth="lg">
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                            <Box className="ml-hm">
                                <Typography variant="h2" className="heading-1 mb-5 text-capitalize">
                                    Sustain &amp; grow your small business with MarketLync
                                </Typography>
                                <Box className="d-flex flex-wrap justify-content-center">
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <RouterLink to="/contact/product-demo-request">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" /> Schedule a Free Demo{' '}
                                                </Button>
                                            </RouterLink>
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <Link href={`${BASE_URL}/auth/signup`} rel="noreferrer">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> Join MarketLync{' '}
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default MarketingForSmallBusinesses;
