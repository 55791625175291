import * as React from 'react';
import { Box, Button, Container, Grid, Typography, Link } from '@material-ui/core';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { post04Detail } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../../AppConfig';
// import your fontawesome library

interface IExpertconsultationsProps {}

const YourCustomerExperience: FC<IExpertconsultationsProps> = (props) => {
    return (
        <>
            <Box className="top-inner-banner top-lg mb-0">
                <figure className="h-100">
                    <img src={post04Detail} alt="img" />
                </figure>
            </Box>
            <Box className="container">
                <Box className="row">
                    <Box className="col-12">
                        <Box className="insight-content-top d-flex justify-content-end">
                            <div className="insight-share">
                                <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fmarketlync.com/insights/3-Ways-To-Quickly-Improve-Your-Customer-Experience%2F&amp;title=3%20Ways%20To%20Quickly%20Improve%20Your%20Customer%20Experience" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-linkedin-in fa-w-14">
                                        <path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                                    </svg>
                                </a>
                                <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmarketlync.com/insights/3-Ways-To-Quickly-Improve-Your-Customer-Experience" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-facebook-f fa-w-10">
                                        <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                    </svg>
                                </a>
                                <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fmarketlync.com/insights/3-Ways-To-Quickly-Improve-Your-Customer-Experience%2F&amp;via=MarketLync&amp;text=3%20Ways%20To%20Quickly%20Improve%20Your%20Customer%20Experience" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-twitter fa-w-16">
                                        <path
                                            fill="currentColor"
                                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                        ></path>
                                    </svg>
                                </a>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box component="section" className="bpost-wrapper">
                <Box className="container">
                    <Box className="row">
                        <Box className="col-12 col-md-5 col-lg-4">
                            <div className="insight-content-header">
                                <h1 className="insight-content-title">3 Ways To Quickly Improve Your Customer Experience</h1>
                                <p className="insight-content-author">Amit Sachdeva</p>
                                <div className="insights-list-item-tags">
                                    <span>
                                        <span className="insights-list-item-tag">Customer Service &amp; Experience</span>
                                    </span>
                                </div>
                            </div>
                        </Box>
                        <Box className="col-12 col-md-7 col-lg-8">
                            <Box className="insight-content-body long-form-text">
                                <p>Customer service once meant a smiling face greeting you by the door on a shop’s premises, but in the digital era, its meaning has expanded. From email responses within 24 hours to replying to social media comments, customer service has become all-encompassing — no matter how small your business is, nobody can afford to overlook it anymore. Still, with all the differences, one thing remains unchanged; the importance of providing your customers with a great experience so that they keep returning to you. </p>
                                <p>Wondering how to maximize your impact on customers with limited resources and staff? We’ve got you covered.</p>
                                <h2>
                                    <strong>1. Send out a customer experience survey</strong>
                                </h2>
                                <p>Ever received an email from a company you recently purchased from, asking you to fill out a quick survey about your experience buying from them? If you think the answer to that question is “no,” you probably haven’t been paying enough attention to your inbox — this is a classic strategy for gauging customer satisfaction, and to make improvements in your service.</p>
                                <p>
                                    What you might not realize is just how easy it is for you to replicate the same tactic. Services like{' '}
                                    <a href="https://www.surveymonkey.co.uk/" target="_blank" rel="noreferrer" className="underline">
                                        SurveyMonkey
                                    </a>{' '}
                                    and{' '}
                                    <a href="https://www.google.co.uk/forms/about/" target="_blank" rel="noreferrer" className="underline">
                                        GoogleForms
                                    </a>{' '}
                                    allow anyone to create, deploy, and analyze a survey for free in a matter of clicks (although SurveyMonkey has a premium version with more features and GoogleForms is only free for personal use).
                                </p>

                                <hr />
                                <h2>
                                    <strong>2. Provide customer service through live chat</strong>
                                </h2>
                                <p>Whenever you visit a company’s website, there’s a high chance you’ll see a pop-up inviting you to take part in a live chat with an agent — everyone from Amazon to your local butcher’s is getting involved!</p>
                                <p>
                                    Again, this doesn’t have to be complicated. You can{' '}
                                    <a href="https://odondo.co/blog/best-customer-service-outsourcing-companies/" target="_blank" rel="noreferrer" className="underline">
                                        outsource live chat
                                    </a>{' '}
                                    through a range of services — there’s no need to hire someone internally.
                                </p>
                                <p>
                                    As a starting point, why not set up a{' '}
                                    <a href="https://en-gb.facebook.com/business/help/321167622310680" target="_blank" rel="noreferrer" className="underline">
                                        chatbot on Facebook
                                    </a>{' '}
                                    for free? It’s not quite as effective as a real person providing support through live chat, but it can improve customer experience while making life easier for your staff.
                                </p>
                                <hr />
                                <h2>
                                    <strong>3. Don’t lose the personal touch</strong>
                                </h2>
                                <p>Small business owners tend to want to model their customer service practices on larger organizations. While this can be a great way to learn from their mistakes before you have to make them yourself, be careful you don’t lose your greatest advantage as a small business — the ability to give a personal touch.</p>
                                <p>This is all about the difference between buying a dress from a national chain that comes in the standard commercial packaging and buying from a local dressmaker that sends it to you with a handwritten note.</p>
                                <p>Think back to your company’s mission statement and values. What makes you different, and how can you put that message across in your customer service efforts? The way you do this will depend on the nature of your company, but it could be as simple as providing related information for free or remembering personal details about your customer.</p>
                                <hr />
                                <h3>Find the right customer success experts for your small business</h3>
                                <p>Although the strategies outlined above are fairly straightforward to carry out, small businesses often have a lot on their plate. If you don’t have the time to train your staff or handle them yourself, why not look for someone else who can do the hard work for you?</p>

                                <p>
                                    At{' '}
                                    <a href="https://www.marketlync.com/" className="underline">
                                        MarketLync
                                    </a>
                                    , we provide a platform connecting small business owners with experts in a range of services, including customer support. Why not give us a go?
                                </p>
                                <hr />
                            </Box>
                            <div className="ctct-inline-form" data-form-id="e43177e8-a8fc-4ecf-889c-c7e1a5d74b31"></div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box className="ml-home-action">
                <Container maxWidth="lg">
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                            <Box className="ml-hm">
                                <Typography variant="h2" className="heading-1 mb-5 text-capitalize">
                                    Sustain &amp; grow your small business with MarketLync
                                </Typography>
                                <Box className="d-flex flex-wrap justify-content-center">
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <RouterLink to="/contact/product-demo-request">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" /> Schedule a Free Demo{' '}
                                                </Button>
                                            </RouterLink>
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <Link href={`${BASE_URL}/auth/signup`} rel="noreferrer">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> Join MarketLync{' '}
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default YourCustomerExperience;
