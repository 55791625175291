import * as React from 'react';
import { Box, Button, Container, Grid, Typography, Link } from '@material-ui/core';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { post03Detail } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../../AppConfig';
// import your fontawesome library

interface IExpertconsultationsProps {}

const FundingForSmallBusinesses: FC<IExpertconsultationsProps> = (props) => {
    return (
        <>
            <Box className="top-inner-banner top-lg mb-0">
                <figure className="h-100">
                    <img src={post03Detail} alt="img" />
                </figure>
            </Box>
            <Box className="container">
                <Box className="row">
                    <Box className="col-12">
                        <Box className="insight-content-top d-flex justify-content-end">
                            <div className="insight-share">
                                <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fmarketlync.com/insights/4-Small-Business-Funding-Options-to-Fuel-Your-Growth%2F&amp;title=4%20Small%20Business%20Funding%20Options%20To%20Fuel%20Your%20Growth" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-linkedin-in fa-w-14">
                                        <path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                                    </svg>
                                </a>
                                <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmarketlync.com/insights/4-Small-Business-Funding-Options-to-Fuel-Your-Growth" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-facebook-f fa-w-10">
                                        <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                    </svg>
                                </a>
                                <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fmarketlync.com/insights/4-Small-Business-Funding-Options-to-Fuel-Your-Growth%2F&amp;via=MarketLync&amp;text=4%20Small%20Business%20Funding%20Options%20To%20Fuel%20Your%20Growth" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-twitter fa-w-16">
                                        <path
                                            fill="currentColor"
                                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                        ></path>
                                    </svg>
                                </a>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box component="section" className="bpost-wrapper">
                <Box className="container">
                    <Box className="row">
                        <Box className="col-12 col-md-5 col-lg-4">
                            <div className="insight-content-header">
                                <h1 className="insight-content-title">4 Small Business Funding Options to Fuel Your Growth</h1>
                                <p className="insight-content-author">Amit Sachdeva</p>
                                <div className="insights-list-item-tags">
                                    <span>
                                        <span className="insights-list-item-tag">Funding, Loans, &amp; Investor Relations</span>
                                    </span>
                                </div>
                            </div>
                        </Box>
                        <Box className="col-12 col-md-7 col-lg-8">
                            <Box className="insight-content-body long-form-text">
                                <p>Getting funding for your small business is no easy feat, especially if you’re just getting started building your company. Many banks are hesitant to loan money to businesses that don’t yet have a credit history. But businesses need money to grow, and you don’t always want to be (or can’t be) the sole financer. </p>
                                <p>Let’s look at some better options than your own pockets or high-interest loans.</p>
                                <h2>
                                    <strong>Unsecured Debt</strong>
                                </h2>
                                <p>
                                    {' '}
                                    <a href="https://loans.usnews.com/unsecured-business-loans" target="_blank" rel="noreferrer" className="underline">
                                        Unsecured debt
                                    </a>{' '}
                                    is a type of loan where the creditor does not receive collateral in exchange for funding. If the borrower cannot repay the loan, then the creditor cannot seize any property to pay for the debt. This type of funding can give small business owners peace of mind knowing their assets are secured if they fall behind on goals and growth.{' '}
                                </p>

                                <hr />
                                <h2>
                                    <strong>SBA Grants &amp; Loans</strong>
                                </h2>
                                <p>
                                    The Small Business Administration sets aside special funds for small businesses in the form of grants. These grants are gifts that do not need to be repaid, which can significantly help small business owners.{' '}
                                    <a href="https://www.sba.gov/funding-programs/loans" target="_blank" rel="noreferrer" className="underline">
                                        SBA loans
                                    </a>{' '}
                                    don’t come from the SBA itself, but rather through its partnership with lenders. If you’ve been turned down for a direct loan, the SBA might provide a better path.
                                </p>
                                <hr />
                                <h2>
                                    <strong>Investor Relations</strong>
                                </h2>
                                <p>
                                    Finding someone to invest in your business isn’t just helpful in terms of funding. It’s also a testament that someone loves your idea just as much as you do! Many investors actively seek out opportunities to invest in small businesses. To connect with investors, try online fundraising platforms like{' '}
                                    <a href="https://angel.co/" target="_blank" rel="noreferrer" className="underline">
                                        AngelList
                                    </a>
                                    ,{' '}
                                    <a href="https://www.seedinvest.com/" target="_blank" rel="noreferrer" className="underline">
                                        Seedinvest
                                    </a>
                                    , or{' '}
                                    <a href="https://circleup.com/" target="_blank" rel="noreferrer" className="underline">
                                        CircleUp
                                    </a>
                                    .
                                </p>
                                <hr />
                                <h2>
                                    <strong>Vendor Credit</strong>
                                </h2>
                                <p>
                                    One of the most overlooked opportunities to fund your business is to leverage vendor credit. This type of credit comes directly from the vendors you buy from, usually with 30, 60, or 90-day repayment options.{' '}
                                    <a href="https://www.nav.com/resource/net-30-accounts/" target="_blank" rel="noreferrer" className="underline">
                                        Vendor credit
                                    </a>{' '}
                                    is a great option if you need cash fast to run a promotion and grow your profits.{' '}
                                </p>

                                <p>Use vendor credit to buy things for your business (or even for your home) so you can use the cash in your pocket to fund your marketing. Then, when your promotion is over and you’ve gotten your ROI, you can repay for vendors without upsetting your cash flow.</p>
                                <hr />
                            </Box>
                            <div className="ctct-inline-form" data-form-id="e43177e8-a8fc-4ecf-889c-c7e1a5d74b31"></div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box className="ml-home-action">
                <Container maxWidth="lg">
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                            <Box className="ml-hm">
                                <Typography variant="h2" className="heading-1 mb-5 text-capitalize">
                                    Sustain &amp; grow your small business with MarketLync
                                </Typography>
                                <Box className="d-flex flex-wrap justify-content-center">
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <RouterLink to="/contact/product-demo-request">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" /> Schedule a Free Demo{' '}
                                                </Button>
                                            </RouterLink>
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <Link href={`${BASE_URL}/auth/signup`} rel="noreferrer">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> Join MarketLync{' '}
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default FundingForSmallBusinesses;
