import * as React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { FC } from 'react';

import { imgBanner3 } from '../../assets/images';
// import your fontawesome library

interface IPolicyProps {}

const Policy: FC<IPolicyProps> = () => {
    return (
        <>
            <Box className="top-inner-banner">
                <figure className="h-100">
                    <img src={imgBanner3} alt="img" />
                </figure>
                <Box className="inner-banner-content">
                    <Typography variant="h1" className="text-display">
                        Privacy Policy
                    </Typography>
                </Box>
            </Box>
            <Grid container justify="center">
                <Grid item xs={12} md={8} className="typo-center fs-16">
                    <Typography className="text-center mb-4 fs-16">
                        This Privacy Policy (“Privacy Policy”) describes our policies and procedures on the collection, use, disclosure, and sharing of your personal information when you use the MarketLync<sup>&reg;</sup>, Inc. Platform.
                    </Typography>
                    <Typography className="text-center mb-4 fs-16">
                        This Privacy Policy applies to activities by MarketLync<sup>&reg;</sup>, Inc. and its affiliates and subsidiaries (collectively “MarketLync<sup>&reg;</sup>,” “we” or “us”). Capitalized terms that are not defined in this Privacy Policy have the meaning given to them in our Terms of Service.
                    </Typography>
                    <hr />
                    <ul>
                        <li>
                            <p>
                                <strong>Notice for California Residents</strong>
                            </p>
                            <p>If you are a California resident, please see the section below titled Additional Information for California Residents.</p>
                        </li>
                        <li>
                            <p>
                                <strong>The Information We Collect</strong>
                            </p>
                            <p>
                                We collect information directly from individuals, from third parties, and automatically through the MarketLync<sup>&reg;</sup> Platform.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Account and Profile Information. </strong>
                            </p>
                            <p>
                                When you create an account and profile on the MarketLync<sup>&reg;</sup> Platform, we collect your name, contact information, demographic information, and other information you provide, such as topics that you know about or find interesting. Your name, photo, and any other information that you choose to add to your public-facing profile will be available for viewing by the public and other users of the MarketLync<sup>&reg;</sup> Platform. Once you create a profile, others will be able to see in your profile certain information about your activity on the MarketLync<sup>&reg;</sup> Platform, such as the questions and answers you post, your followers and who you follow, topics of interest to you, the information you list as credentials, and your edits to your
                                content. For more information about your choices for publicly displayed information, see the section below about Your Choices.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Your Content.</strong>
                            </p>
                            <p>
                                We collect the information and content that you post to the MarketLync<sup>&reg;</sup> Platform, including your questions, answers, and photos..Your content, date and time stamps, and all associated comments are publicly viewable on the MarketLync<sup>&reg;</sup> Platform, along with your name. This also may be indexed by search engines and be republished elsewhere on the Internet in accordance with our Terms of Service. For more information about what you can change, see the below section on Your Choices.{' '}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Integrated Service Provider and Linked Networks. </strong>
                            </p>
                            <p>
                                You can connect your existing MarketLync<sup>&reg;</sup> account with certain third-party networks like Twitter or Google, for example (each a “Linked Network”). You can also elect to sign in or sign up to the MarketLync<sup>&reg;</sup> Platform through a Linked Network. If you elect to sign up through or connect a Linked Network, we receive certain profile and account information about you from the Linked Network. These Linked Networks may also appear in your profile, so that people can find you on these Linked Networks. The specific information provided to us by Linked Networks is determined by you and such Linked Networks, and may vary by network. In all cases, the permissions page for the Linked Network will describe the information being shared. You
                                should consult their respective privacy policies for information about their practices. You may elect to use information from the Linked Network to populate your profile on the MarketLync<sup>&reg;</sup> Platform or to import your contacts to help you find and connect with them on the MarketLync<sup>&reg;</sup> Platform or to invite them to join. For information on your choices, including how to disconnect a Linked Network from your MarketLync<sup>&reg;</sup> profile, see the Your Choices section below. You may also elect to connect and make and receive payments to and from use through third-party networks (“Integrated Service Provider”); if you do so, you will be allowing us to pass to and receive from the Integrated Service Provider your login information
                                and other user data for payment purposes.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Automatically Collected Information About Your Activity. </strong>
                            </p>
                            <p>
                                We use cookies, log files, pixel tags, local storage objects, and other tracking technologies to automatically collect information about your activities, such as your searches, page views, date and time of your visit, and other information about your use of the MarketLync<sup>&reg;</sup> Platform. We also collect information that your computer or mobile device provides to us in connection with your use of the MarketLync<sup>&reg;</sup> Platform such as your browser type, type of computer or mobile device, browser language, IP address, mobile carrier, unique device identifier, location, and requested and referring URLs. We also receive information when you view content on or otherwise interact with the MarketLync<sup>&reg;</sup> Platform, even if you have not
                                created an account. For more information, see the “Cookies, Pixels and Tracking” section below and our Cookie Policy.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Engagement. </strong>
                            </p>
                            <p>
                                We collect browsing information – such as IP address and location, date and time stamp, user agent, MarketLync<sup>&reg;</sup> cookie ID (if applicable), URL, unique advertising or content identifiers (if applicable) and time zone, and other information about user activities on the MarketLync<sup>&reg;</sup> Platform, as well as on third-party sites and services that have embedded our MarketLync<sup>&reg;</sup> pixels (“Pixels”), widgets, plug-ins, buttons, or related services. See the section below about MarketLync<sup>&reg;</sup> Ads and Personalization for more detailed information about how our Pixels may be used by publishers or users of our advertising services (“Ad Services”) on the MarketLync<sup>&reg;</sup> Platform to enable personalization, as
                                well as your choices related to advertising and personalization. We may also receive information about you from third parties, such as other users, partners (including ad partners), or our affiliated companies.{' '}
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>How we Use Your Information</strong>
                            </p>
                            <p>
                                MarketLync<sup>&reg;</sup> uses the information we collect for the following purposes:
                            </p>
                            <ul className="bullet-list">
                                <li>
                                    <p>
                                        <strong>Notify you regarding services,</strong> changes to services, new services, or any other matter related to MarketLync<sup>&reg;</sup> services.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Increase the usability of services.</strong>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Provide our Services.</strong>
                                        To provide you the services we offer on the MarketLync<sup>&reg;</sup> Platform and make the MarketLync<sup>&reg;</sup> Platform available to the public, communicate with you about your use of the MarketLync<sup>&reg;</sup> Platform, respond to your inquiries, provide troubleshooting, and for other customer service purposes.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Personalization. </strong>
                                        To tailor the content and information that we may send or display to you in the MarketLync<sup>&reg;</sup> Platform, to suggest followers and content, to help others find your profile, to offer location customization, and personalized help and instructions, and to otherwise personalize your experiences while using the MarketLync<sup>&reg;</sup> Platform.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Advertising. </strong>
                                        To display interest-based advertising to you in the MarketLync<sup>&reg;</sup> Platform, to improve our advertising and measurement systems so we can show you relevant ads, to pre-fill forms in ads, and to measure the effectiveness and reach of ads and services. For more information, see the Ad Services section below about MarketLync<sup>&reg;</sup> Ads and Personalization.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Marketing and Promotions.</strong>
                                        For marketing and promotional purposes, such as to send you news and newsletters, special offers, and promotions, or to otherwise contact you about products or information we think may interest you, including information about third-party products and services.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Analytics.</strong>
                                        To gather metrics to better understand how users access and use the MarketLync<sup>&reg;</sup> Platform; to evaluate and improve the MarketLync<sup>&reg;</sup> Platform, including the Ad Services and personalization, and to develop new products and services.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Comply with Law. </strong>
                                        To comply with legal obligations, as part of our general business operations, and for other business administration purposes.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Prevent Misuse.</strong>
                                        Where we believe necessary to investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person or violations of our Terms of Service or this Privacy Policy.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        To carry on any aspect of our business, as determined by MarketLync<sup>&reg;</sup>.
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>
                                <strong>How We Share Your Information</strong>
                            </p>
                            <p>We share information as set forth below, and where individuals have otherwise consented:</p>
                            <ul className="bullet-list">
                                <li>
                                    <p>
                                        <strong>Service Providers. </strong> We may share your information with third-party service providers who use this information to perform services for us, such as payment processors, hosting providers, auditors, advisors, consultants, customer service and support providers, as well as those who assist us in providing the Ad Services.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Affiliates. </strong> The information collected about you may be accessed by or shared with subsidiaries and affiliates of MarketLync<sup>&reg;</sup>, Inc., whose use and disclosure of your personal information is subject to this Privacy Policy.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Business Transfers.</strong>
                                        We may disclose or transfer information, including personal information as part of any merger, sale, and transfer of our assets, acquisition or restructuring of all or part of our business, bankruptcy, or similar event.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Legally Required. </strong>
                                        We may disclose your information if we are required to do so by law.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Protection of Rights. </strong>
                                        We may disclose information where we believe it necessary to respond to claims asserted against us or, comply with legal process (e.g., subpoenas or warrants), enforce or administer our agreements and terms, for fraud prevention, risk assessment, investigation, and protect the rights, property or safety of MarketLync<sup>&reg;</sup>, its users, or others.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Your Content and Public Information.</strong>
                                        Your content, including your name, profile picture, profile information, and certain associated activity information is available to other users of the MarketLync<sup>&reg;</sup> Platform and may also be viewed publicly. Public viewing includes availability to non-registered visitors and can occur when users share your content across other sites or services. In addition, your content may be indexed by search engines. In some cases, we may charge for access to your content and public information on the MarketLync<sup>&reg;</sup> Platform. See the section below about Your Choices for information about how you may change how certain information is shared or viewed by others.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Metrics.</strong>
                                        We may share with our advertisers or publishers aggregate statistics, metrics and other reports about the performance of their ads or content in the MarketLync<sup>&reg;</sup> Platform such as the number of unique user views, demographics about the users who saw their ads or content, conversion rates, and date and time information. We do not share IP addresses or personal information, but certain features may allow you to share your personal information with advertisers on our platform if you choose to do so. We may also allow our advertisers or publishers to use Pixels on the MarketLync<sup>&reg;</sup> Platform in order to collect information about the performance of their ads or content.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Anonymized and Aggregated Data. </strong>
                                        We may share aggregated or de-identified information with third parties for research, marketing, analytics and other purposes, provided such information does not identify a particular individual.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>As Directed by You. </strong>
                                        We may also share data as otherwise directed by you (for example, to fulfill a transaction).
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Other Companies and Affiliates. </strong>
                                        When you have enabled us to share your personal date with another company or affiliate, such as sending you offers and promotions about their products and services; or allowing us to share your personal data with third parties or linked sites, such as social media profiles the information received by the other company becomes subject to the other company’s privacy practices.
                                    </p>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <p>
                                <strong>Cookies, Pixels and Tracking</strong>
                            </p>
                            <p>
                                We and our third-party providers use cookies, clear GIFs/pixel tags, JavaScript, local storage, log files, and other mechanisms to automatically collect and record information about your usage and browsing activities on the MarketLync<sup>&reg;</sup> Platform and across third-party sites or online services. We may combine this information with other information we collect about users. Below, we provide a brief summary of these activities.{' '}
                            </p>
                            <ul className="bullet-list">
                                <li>
                                    <p>
                                        <strong>Pixels, web beacons, clear GIFs. </strong> These are tiny graphics with a unique identifier, similar in function to cookies, which we use to track the online movements of users of the MarketLync<sup>&reg;</sup> Platform and the web pages of users of our Ad Services, and to personalize content. We also use these in our emails to let us know when they have been opened or forwarded, so we can gauge the effectiveness of our communications.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Analytics Tools. </strong>We may use internal and third-party analytics tools. The third-party analytics companies we work with may combine the information collected with other information they have independently collected from other websites and/or other online products and services. Their collection and use of information is subject to their own privacy policies.
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>
                                <strong>Do-Not-Track Signals.</strong>
                                Please note we do not change system behavior within the MarketLync<sup>&reg;</sup> Platform in response to browser requests not to be tracked. You may, however, disable certain tracking by third parties as discussed in the MarketLync<sup>&reg;</sup> Ads and Personalization section below. You may also opt out of tracking by MarketLync<sup>&reg;</sup> Pixels, as described below in MarketLync<sup>&reg;</sup> Ads and Personalization.
                            </p>
                        </li>
                    </ul>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">
                            MarketLync<sup>&reg;</sup> Ads and Personalization
                        </h2>
                        <p>
                            We may display personalized content (including from third-party content publishers) and personalized ads (including sponsored content), based on information that we have collected via the MarketLync<sup>&reg;</sup> Platform, and through our Pixels, widgets, and buttons embedded on third- party sites. We also may report aggregated or de-identified information about the number of users that saw a particular ad or content and related audience engagement information to users of our Ad Services and to publishers of content on the MarketLync<sup>&reg;</sup> Platform.
                        </p>
                        <p>Advertisers who use our Ad Services may also provide us with information as part of their ad campaigns, including customer information (e.g., email addresses, phone numbers, or other contact information, demographic or interest data) in order to create custom audiences for personalizing their ad campaigns or for measuring the effectiveness of their ads; we only use this information to facilitate the particular advertiser’s campaign (including ad metrics and reporting to that advertiser); and we do not disclose this information to third parties (other than our service providers) unless required by law. We also do not disclose to the advertisers who uses our Ad Services, the names, or contact information of their customers that were successfully reached.</p>
                    </Box>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">
                            Third-Party Ads on MarketLync<sup>&reg;</sup>
                        </h2>
                        <p>We may also work with third parties such as network advertisers to server ads on the Platform and on third-party websites or other media (e.g., social networking platforms), such as Google AdSense Facebook Audience Network. These third parties may use cookies, JavaScript, web beacons (including clear GIFs), Flash LSOs and other tracking technologies to measure the effectiveness of their ads and to personalize advertising content to you.</p>
                        <p>In addition to opting out of tracking across sites by our Pixels (see Your Choices section below), you also may opt out of much interest-based advertising on third-party sites and through third-party ad networks (including Facebook Audience Network and Google AdSense). See Your Choices section below for more information about opting out of third- party ad networks.</p>
                    </Box>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">How We Protect Your Information</h2>
                        <p>
                            The security of your information is important to us. MarketLync<sup>&reg;</sup> has implemented safeguards to protect the information we collect. However, no website or Internet transmission is completely secure. We urge you to take steps to keep your personal information safe, such as choosing a strong password and keeping it private, as well as logging out of your user account, and closing your web browser when finished using the MarketLync<sup>&reg;</sup> Platform on a shared or unsecured device.
                        </p>
                    </Box>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">Access and Amend Your Information</h2>
                        <p>You may update or correct your account information at any time by logging in to your account. You may also make a number of other adjustments to settings or the display of information about you as described in more detail in the following section about Your Choices.</p>
                    </Box>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">Your Choices</h2>
                        <p>
                            You may, of course, decline to submit information through the MarketLync<sup>&reg;</sup> Platform, in which case we may not be able to provide certain services to you. You may also control the types of notifications and communications we send, limit the information shared within the MarketLync<sup>&reg;</sup> Platform about you, and otherwise amend certain privacy settings. Here is some further information about some of your choices:
                        </p>
                    </Box>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">Your Content</h2>
                        <p>
                            Any information you post on the MarketLync<sup>&reg;</sup> Platform may not be deleted or edited. If you wish to have something removed you can email contact@MarketLync.com and request that the content be hidden. Any shared content via MarketLync<sup>&reg;</sup>’s standard sharing features will not be hidden from any third party site. MarketLync<sup>&reg;</sup> has no control over deletions or changes to your content if it has been shared manually by others.{' '}
                        </p>
                    </Box>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">Emails and Notifications</h2>
                        <p>
                            When you join the MarketLync<sup>&reg;</sup> Platform by signing up for an account or creating a profile, as part of the service, you will receive email digests containing content from the MarketLync<sup>&reg;</sup> Platform that we believe may match your interests. You may also receive notices about other content from the MarketLync<sup>&reg;</sup> Platform that may be of interest to you, such as Spaces that you follow or that match your interests. You can manage all your email and notice preferences in your account profile settings, under your Emails and Notifications settings. If you opt out of receiving emails or other notifications about recommendations or other information we think may interest you, we may still send you transactional emails about your
                            account or any services you have requested or received from us. There are certain notifications that cannot be turned off, these are listed under Settings.
                        </p>
                    </Box>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">Credentials.</h2>
                        <p>You can change your credentials that are displayed in your profile at any time. </p>
                    </Box>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">Deleted or Deactivated Account. </h2>
                        <p>
                            If you chose to delete your account in your privacy settings then all of your content will be removed from public visibility on the MarketLync<sup>&reg;</sup> Platform. You will not be able to interact on the MarketLync<sup>&reg;</sup> platform or with other users, however your content will remain on the MarketLync<sup>&reg;</sup> Platform. Once you deactivate your account, you can reactivate it any time by choosing to log in.
                        </p>
                    </Box>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">Pixels.</h2>
                        <p>
                            Opting out of Pixels tracking may impact our ability to personalize ads and content tailored to your interests. This setting is tied to your cookies, and, unless you are logged in to the MarketLync<sup>&reg;</sup> Platform, will only be effective on the browser for which you have performed the opt-out.
                        </p>
                    </Box>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">Linked Networks. </h2>
                        <p>
                            You may connect or disconnect your Linked Networks, such as Google, through the Account Settings tab in your profile settings, and you may access, amend and delete much of your profile information through your profile settings. Once you disconnect a Linked Network, we will not receive information from that Linked Network going forward unless you choose to reconnect it. You may also control whether the Linked Network is visible in your profile and, if you enable contact imports, how you message your contacts to find and connect with you on the MarketLync<sup>&reg;</sup> Platform.
                        </p>
                        <p>
                            MarketLync<sup>&reg;</sup> is headquartered in the United States, and has operations, entities and service providers in the United States. As such, we and our service providers may transfer your personal information to, or access it in, jurisdictions (including the United States) that may not provide equivalent levels of data protection as your home jurisdiction. We will take steps to ensure that your personal information receives an adequate level of protection in the jurisdictions in which we process it.
                        </p>
                    </Box>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">Children’s Privacy</h2>
                        <p>
                            We do not knowingly collect or solicit personal information from anyone under the age of 13 or knowingly allow such persons to register. If we become aware that we have collected personal information from a child under the relevant age without parental consent, we take steps to delete that information. We expressly disclaim, and you expressly release us from, any and all liability whatsoever for any controversies, claims, suits, injuries, harm, loss, penalties, damages, arising from and/or in any way related to any misrepresentations regarding the age of any User. We reserve the right to suspend and/or terminate with or without notice the Account of User who we believe has provided false information when registering for and/or using the Services and each User
                            agrees to make no further use of the Services after termination and/or during suspension.{' '}
                        </p>
                    </Box>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">Links to Other Websites</h2>
                        <p>
                            The MarketLync<sup>&reg;</sup> Platform may contain links to third-party sites or online services. We are not responsible for the practices of such third parties, whose information practices are subject to their own policies and procedures, not to this Privacy Policy.
                        </p>
                    </Box>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">Additional Information for California Residents</h2>
                        <p>This section provides information organized in accordance with the California Consumer Privacy Act (“CCPA”) for residents of California about how we handle certain personal information we have collected over the past 12 months. </p>
                    </Box>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">Categories of Personal Information. </h2>
                        <p>This Privacy Policy discloses the categories of personal information that we collect, use, and disclose in order to operate our business over the past 12 months.</p>
                        <ul>
                            <li>
                                <p>
                                    <strong>Collect :</strong>
                                    The section above entitled The Information We Collect discloses the categories of personal information that we collect, which include your account and profile information, your content, your communications with us, your information you provide from linked networks, information about your activity on MarketLync<sup>&reg;</sup>, and how you engage with our MarketLync<sup>&reg;</sup>.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Use :</strong>
                                    The section above entitled How We Use Your Information discloses the that the categories of personal information we collect are used to provide our services, for personalization, to provide advertising in order to generate income to operate MarketLync<sup>&reg;</sup>, to promote our services to you, to analyze how our services are used, to comply with law, and to prevent misuse of MarketLync<sup>&reg;</sup>.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Disclose :</strong>
                                    The section above entitled How We Share Your Information discloses that we share your personal information with, service providers, affiliates, law enforcement authorities, and as needed with third parties to provide our services.
                                </p>
                            </li>
                        </ul>
                    </Box>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">We do not sell your personal information to third parties. </h2>
                        <p>
                            MarketLync<sup>&reg;</sup> may share your personal information with third parties and third parties may collect your personal information as described above in the How We Share Your Information section. In addition we may also work with third parties such as network advertisers to serve ads on the MarketLync<sup>&reg;</sup> Platform and on third-party websites or other media (e.g., social networking platforms), such as Google AdSense. Facebook Audience Network. These third parties may use cookies, JavaScript, web beacons (including clear GIFs), Flash LSOs and other tracking technologies to measure the effectiveness of their ads and to personalize advertising content to you.
                        </p>
                        <p>We do not collect personal information from consumers that we know are younger than 16 years old.</p>
                    </Box>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">Requests for Deletion, Copy and Right to Know Your Information. </h2>
                        <p>California consumers have the right to make the following requests, which we endeavor to honor from non-California residents as well:</p>
                        <ul>
                            <li>
                                <p>
                                    <strong>Deletion :</strong>
                                    You have the right to request deletion of personal information that we have collected about you.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Copy and Right to Know :</strong>
                                    You have the right to request a copy of the specific pieces of personal information that we have collected about you over the past 12 months, including the categories of information, sources, and purposes of collection, as well as categories of third parties we have shared it with.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Designated Agent :</strong>
                                    You may designate an agent to make a request on your behalf. That agent must have access to your account in order for us to verify the request.
                                </p>
                            </li>
                        </ul>
                    </Box>

                    <Box className="mb-5">
                        <h2 className="hdd3 line">Non-Discrimination</h2>
                        <p>
                            MarketLync<sup>&reg;</sup> will not discriminate against you, including by denying or providing a different level or quality of goods or services should you choose to exercise your options under the CCPA.
                        </p>
                    </Box>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">Your California Privacy Rights under California’s Shine-the-Light Law.</h2>
                        <p>California law permits users who are California residents to request and obtain from us once a year, free of charge, a list of the third parties to whom we have disclosed their personal information (if any) for their direct marketing purposes in the prior calendar year, as well as the types of personal information disclosed to those parties. If you are a California resident and would like to request this information, please submit your request in an email to contact@MarketLync.com </p>
                    </Box>
                    <Box className="mb-5">
                        <h2 className="hdd3 line">Contact Us</h2>
                        <p>If you have any questions about our practices or this Privacy Policy, please contact us at contact@MarketLync.com.</p>
                    </Box>

                    <Box className="mb-5">
                        <h2 className="hdd3 line">Changes to Our Privacy Policy</h2>
                        <p>If we change our privacy policies and procedures, we will post those changes on this page. If we make any changes to this Privacy Policy that materially change how we treat your personal information, we will endeavor to provide you with reasonable notice of such changes, such as via prominent notice in the MarketLync Platform or to your email address of record, and where required by law, we will obtain your consent or give you the opportunity to opt out of such changes. </p>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default Policy;
