import { Box, Button, Container, Grid, Link, Typography } from '@material-ui/core';
import React, { FC, useRef } from 'react';
import { Link as RouterLink} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

import { mainBanner1, mainBanner2, mainBanner3, mainBanner4, mainBanner5, mainBanner6, mainBanner7, mainBanner8, mainBanner9, mainBanner10, mainBanner11, mainBanner12, mainBanner13, mainBanner14, mainBanner15, mainBanner16, mainBanner17, mainBanner18, mainBanner19, mainBanner20, mainBanner21, mainBanner22, mainBanner23, mainBanner24, mainBanner25, mainBanner26, mainBanner27, mainBanner28, AudioVideoConference, MultipleAnswersToaQuestion, Newsfeed, FinancialDashboard, MarketLyncIntroduction } from '../../assets/images';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCalendarCheck, faCheckCircle, faCommentDollar, faPiggyBank, faSignInAlt, faStarHalfAlt, faTachometerAlt, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../../AppConfig';

interface IHomeProps {}
const Transition = React.forwardRef(function Transition(props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Home: FC<IHomeProps> = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const images = [mainBanner1, mainBanner2, mainBanner3, mainBanner4, mainBanner5, mainBanner6, mainBanner7, mainBanner8, mainBanner9, mainBanner10, mainBanner11, mainBanner12, mainBanner13, mainBanner14, mainBanner15, mainBanner16, mainBanner17, mainBanner18, mainBanner19, mainBanner20, mainBanner21, mainBanner22, mainBanner23, mainBanner24, mainBanner25, mainBanner26, mainBanner27, mainBanner28];
    const randomIndex = Math.floor(Math.random() * images.length);
    const selectedPicture = images[randomIndex];
    const ChipStyles = useRef({
        backgroundImage: `url(${selectedPicture})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    });

    return (
        <>
            <Box component="section" className="banner-section">
                <figure style={ChipStyles.current}>{/* <img src={mainBanner1} alt="img" /> */}</figure>
                <Box className="banner-content">
                    <Container maxWidth="lg">
                        <Typography variant="h1">
                            Sustain &amp; Grow <br /> Your Small Business
                        </Typography>
                        <Typography variant="h6" className="text-white mb-3">
                            Get insights from experts who understand your industry and business
                        </Typography>
                        <div>
                            <Grid container>
                                <Grid item xs={6} sm={5} md={4} lg={2} xl={2} className="px-1 px-lg-3 mb-3 mb-md-0">
                                    <Button type="button" variant="contained" color="primary" size="large" fullWidth onClick={handleClickOpen}>
                                        Watch Video
                                    </Button>
                                </Grid>
                                <Grid item xs={6} sm={5} md={4} lg={2} xl={2} className="px-1 px-lg-3 ">
                                    <Button type="button" variant="contained" color="primary" size="large" fullWidth href={`${BASE_URL}/auth/signup`} rel="noreferrer">
                                        Join MarketLync
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </Box>
            </Box>
            <Container maxWidth="lg">
                <Grid container justify="center" spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                        <Box className="ml-hm">
                            <Typography variant="h2" className="heading-1 text-capitalize">
                                Actionable insights from small business experts
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <div className="section-one">
            <Container maxWidth="lg" className="proposition">
                    <Grid container spacing={0} className="two-sections justify-content-between">
                        <Grid item xs={12} md={6} className="order-last">
                            <figure className="h-100">
                                <img src={Newsfeed} alt="img" className="shadow-sm" />
                            </figure>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box className="content" display="flex" alignItems="center" alignContent="center" height="100%" flexDirection="column">
                                <Box marginY="auto">
                                    <Typography variant="h2" className="title-one">
                                        Interactions with peers
                                    </Typography>
                                    <p>Use our open and free Q&amp;A platform to post a question about your business situtation publicly and get advice from the MarketLync small business community.</p>
                                    <RouterLink to="/community-interaction" color="primary">
                                        Learn More <FontAwesomeIcon icon={faAngleRight} className="lm-icon" />
                                    </RouterLink>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="lg" className="proposition">
                    <Grid container spacing={0} className="two-sections justify-content-between">
                        <Grid item xs={12} md={6} className="order-last order-lg-0 ">
                            <figure className="h-100">
                                <img src={AudioVideoConference} alt="img" className="shadow-sm" />
                            </figure>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box className="content" display="flex" alignItems="center" alignContent="center" height="100%" flexDirection="column">
                                <Box marginY="auto">  
                                <span className="badge badge-num mb-2">COMING SOON</span>
                                    <Typography variant="h2" className="title-one">
                                        1:1 Consultations with Vetted Experts
                                      
                                    </Typography>

                                    {/* <Typography variant="h3" className="title-two">
                                    Connect with consultants having years of experience in a multitude of industries, offering their undivided attention to your questions, delivering a seamless decision-making experience!
                                </Typography> */}

                                    <p>Find and contact the right experts for your business situation and connect confidentially over 1:1 audio/video conference.</p>
                                    {/* <Button variant="contained" color="primary" href="/signin" size="large">
                                        Read More
                                    </Button> */}
                                    <RouterLink to="/one-on-one-consultations" color="primary">
                                        Learn More <FontAwesomeIcon icon={faAngleRight} className="lm-icon" />
                                    </RouterLink>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="lg" className="proposition">
                    <Grid container spacing={0} className="two-sections justify-content-between">
                        <Grid item xs={12} md={6} className="order-last">
                            <figure className="h-100">
                                <img src={MultipleAnswersToaQuestion} alt="img" className="shadow-sm" />
                            </figure>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box className="content" display="flex" alignItems="center" alignContent="center" height="100%" flexDirection="column">
                                <Box marginY="auto">
                                <span className="badge badge-num mb-2">COMING SOON</span>
                                    <Typography variant="h2" className="title-one">
                                        Insights from multiple experts – privately
                                    </Typography>

                                    <p>Don’t have time to connect 1:1 with an expert? Post a question privately and only experts vetted by MarketLync will answer your questions.</p>
                                    <RouterLink to="/multi-expert-insights" color="primary">
                                        Learn More <FontAwesomeIcon icon={faAngleRight} className="lm-icon" />
                                    </RouterLink>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
               
                <Container maxWidth="lg" className="proposition">
                    <Grid container spacing={0} className="two-sections justify-content-between">
                        <Grid item xs={12} md={6} className="order-last order-lg-0 ">
                            <figure className="h-100">
                                <img src={FinancialDashboard} alt="img" className="shadow-sm" />
                            </figure>
                        </Grid>
                        <Grid item xs={12} md={5} className="align-items-center">
                            <Box className="content" display="flex" alignItems="center" alignContent="center" height="100%" flexDirection="column">
                                <Box marginY="auto">
                                    <Typography variant="h2" className="title-one">
                                        Become An Expert
                                    </Typography>
                                    <p>Become an expert with MarketLync and share your business expertise. MarketLync experts can potentially earn money and contribute to the growth of small businesses.</p>
                                    <RouterLink to="/become-an-expert" color="primary">
                                        Learn More <FontAwesomeIcon icon={faAngleRight} className="lm-icon" />
                                    </RouterLink>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Box className="ml-whyus">
                <Container maxWidth="lg">
                    <h2 className="heading-1">Why MarketLync</h2>
                    <Grid container spacing={2} className="justify-content-between">
                        <Grid item xs={12} md={6} lg={6} className="why-ml-single d-flex align-items-start">
                            <div className="d-flex align-items start mb-5">
                                <figure className="mr-5">
                                    <FontAwesomeIcon icon={faCheckCircle} size="5x" />
                                </figure>
                                <div>
                                    <h3>Actionable Insights</h3>
                                    <p>MarketLync experts come from the small business community with years of vetted experience.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} className="why-ml-single d-flex align-items-start">
                            <div className="d-flex align-items start mb-5">
                                <figure className="mr-5">
                                    <FontAwesomeIcon icon={faPiggyBank} size="5x" />
                                </figure>
                                <div>
                                    <h3>Cost Effective</h3>
                                    <p>Connect with experts &amp; pay only for the time you engage them. Or post a question &amp; get insights from the MarketLync network!</p>
                                    {/* <p>Connect with experts and pay only for the time you engage them. Or post a question for free and get insights from the MarketLync community!</p> */}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} className="why-ml-single d-flex align-items-start">
                            <div className="d-flex align-items start mb-5">
                                <figure className="mr-5">
                                    <FontAwesomeIcon icon={faStarHalfAlt} size="5x" />
                                </figure>
                                <div>
                                    <h3>Reliable Insights</h3>
                                    <p>View ratings on MarketLync experts from other members of the community before engaging one.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} className="why-ml-single d-flex align-items-start">
                            <div className="d-flex align-items start mb-5">
                                <figure className="mr-5">
                                    <FontAwesomeIcon icon={faUserShield} size="5x" />
                                </figure>
                                <div>
                                    <h3>Secure</h3>
                                    <p>With a strong legal framework, and our leading-edge platform technology, your information is always secure.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} className="why-ml-single d-flex align-items-start">
                            <div className="d-flex align-items start mb-5">
                                <figure className="mr-5">
                                    <FontAwesomeIcon icon={faTachometerAlt} size="5x" />
                                </figure>
                                <div>
                                    <h3>Timely Responses</h3>
                                    <p>A fast-growing network of experts, combined with our AI technologies, we help you find insights fast!</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} className="why-ml-single d-flex align-items-start">
                            <div className="d-flex align-items start mb-5">
                                <figure className="mr-5">
                                    <FontAwesomeIcon icon={faCommentDollar} size="5x" />
                                </figure>
                                <div>
                                    <h3>Market Yourself</h3>
                                    <p>Become a MarketLync expert and potentially earn money for sharing your expertise.</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box className="ml-home-action">
                <Container maxWidth="lg">
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                            <Box className="ml-hm">
                                <Typography variant="h2" className="heading-1 mb-5 text-capitalize">
                                    Sustain &amp; grow your small business with MarketLync
                                </Typography>
                                <Box className="d-flex flex-wrap justify-content-center">
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <RouterLink to="/contact/product-demo-request">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" /> Schedule a Free Demo{' '}
                                                </Button>
                                            </RouterLink>
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                        <Link href={`${BASE_URL}/auth/signup`} rel="noreferrer">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> Join MarketLync{' '}
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                                {/* <Typography className="text-center mb-4 fs-16">
                            MarketLync provides a platform where small business owners can connect with vetted experts who understand their business and can assist in their growth journeys.
                        </Typography> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Dialog maxWidth="lg" open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogContent>
                    {/* <video className="w12" src="https://s3.amazonaws.com/codecademy-content/courses/React/react_video-fast.mp4" controls></video> */}
                    <video className="w10" src={MarketLyncIntroduction} controls>
                        {' '}
                    </video>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Disagree
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Agree
                    </Button>
                </DialogActions> */}
            </Dialog>
        </>
    );
};

export default Home;
