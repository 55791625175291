import * as React from 'react';
import { Box, Button, Container, Grid, Typography, Link  } from '@material-ui/core';
import { FC } from 'react';
import { Link as RouterLink} from 'react-router-dom';

import { Newsfeed, BookmarkedTopic, Notifications, UserProfile, ImgCommunityInteraction } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../../AppConfig';
// import your fontawesome library

interface IExpertconsultationsProps {}

const CommunityInteraction: FC<IExpertconsultationsProps> = (props) => {
    return (
        <>
            <Box className="top-inner-banner mb-0">
                <figure className="h-100">
                    <img src={ImgCommunityInteraction} alt="img" />
                </figure>
            </Box>

            <div className="section-one">
                <Container maxWidth="lg" className="proposition">
                    <Grid container spacing={0} className="two-sections justify-content-between">
                        <Grid item xs={12} md={6} className="order-last">
                            <figure className="h-100">
                                <img src={Newsfeed} alt="img" className="shadow-sm" />
                            </figure>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box className="content" display="flex" alignItems="center" alignContent="center" height="100%" flexDirection="column">
                                <Box marginY="auto">
                                    <Typography variant="h2" className="title-one">
                                        Interact With The Small Business Community Through Q&amp;A
                                    </Typography>
                                    <p>Ask and answer questions, receive answers, and learn from others like you in the small business community - for free.</p>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="lg" className="proposition">
                    <Grid container spacing={0} className="two-sections justify-content-between">
                        <Grid item xs={12} md={6} className="order-last order-lg-0 ">
                            <figure className="h-100">
                                <img src={BookmarkedTopic} alt="img" className="shadow-sm" />
                            </figure>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box className="content" display="flex" alignItems="center" alignContent="center" height="100%" flexDirection="column">
                                <Box marginY="auto">
                                    <Typography variant="h2" className="title-one">
                                        Bookmark interesting topics
                                    </Typography>
                                    <p>Easily follow interesting topics started by other members of the community with bookmarks. </p>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="lg" className="proposition">
                    <Grid container spacing={0} className="two-sections justify-content-between">
                        <Grid item xs={12} md={6} className="order-last">
                            <figure className="h-100">
                                <img src={Notifications} alt="img" className="shadow-sm" />
                            </figure>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box className="content" display="flex" alignItems="center" alignContent="center" height="100%" flexDirection="column">
                                <Box marginY="auto">
                                    <Typography variant="h2" className="title-one">
                                        Get Notified About New Activity
                                    </Typography>
                                    <p>Our notification engine keeps you informed about relevant activity on MarketLync, while providing you privacy and control on how you get notified.</p>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="lg" className="proposition">
                    <Grid container spacing={0} className="two-sections justify-content-between">
                        <Grid item xs={12} md={6} className="order-last order-lg-0 ">
                            <figure className="h-100">
                                <img src={UserProfile} alt="img" className="shadow-sm" />
                            </figure>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box className="content" display="flex" alignItems="center" alignContent="center" height="100%" flexDirection="column">
                                <Box marginY="auto">
                                    <Typography variant="h2" className="title-one">
                                        Showcase Your Skills &amp; Experience
                                    </Typography>
                                    <p>With your own profile page, showcase your accomplishments to the MarketLync small business community.</p>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <Box className="ml-home-action">
                <Container maxWidth="lg">
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                            <Box className="ml-hm">
                                <Typography variant="h2" className="heading-1 mb-5 text-capitalize">
                                    Sustain &amp; grow your small business with MarketLync
                                </Typography>
                                <Box className="d-flex flex-wrap justify-content-center">
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                        <RouterLink to="/contact/product-demo-request">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" /> Schedule a Free Demo{' '}
                                                </Button>
                                            </RouterLink>
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                        <Link href={`${BASE_URL}/auth/signup`} rel="noreferrer">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> Join MarketLync{' '}
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default CommunityInteraction;
