import * as React from 'react';
import { Box, Button, Container, Grid, Typography, Link  } from '@material-ui/core';
import { FC } from 'react';
import { Link as RouterLink} from 'react-router-dom';

import { connect1o1, ConsultationCharges, CalendarAppointment, AudioVideoConference, SearchResultsUsers, OneOnOneConsultations } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../../AppConfig';
// import your fontawesome library

interface IExpertconsultationsProps {}

const OneonOneConsultations: FC<IExpertconsultationsProps> = (props) => {
    return (
        <>
            <Box className="top-inner-banner mb-0">
                <figure className="h-100">
                    <img src={OneOnOneConsultations} alt="img" />
                </figure>
            </Box>
            <Container maxWidth="lg">
                <Grid container justify="center" spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                        <Box className="ml-hm">
                            <Typography variant="h2" className="heading-1 text-capitalize">
                                Connect 1-on-1 With Vetted Small Business Experts
                            </Typography>
                            <Box className="text-center mt-2">
                            <span className="badge badge-num mb-2">COMING SOON</span>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth="lg">
                {/* <Grid container justify="center" spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                        <Typography variant="h2" className="ml-display-2">
                        One line of benefits sentence goes here
                    </Typography>
                        <Typography className="text-center mb-4 fs-16">
                            Harness the power of 1:1 consultations to jumpstart your decisions effectively. If you need a private consultation to tackle your business challenges in a more discreet manner, feel free to ask your questions privately, and get expert advice from a true professional, verified with MarketLync<sup>&reg;</sup> - guaranteed to share valuable insights. For a small fee, you will be assigned a vetted consultant who has the right skills to offer comprehensive, actionable advice. Schedule an audio or video conference to facilitate in-depth discussions.
                        </Typography>
                    </Grid>
                </Grid> */}
                <Grid container justify="center">
                    <Grid item xs={12} md={8} className="d-flex justify-content-center align-items-center mb-lg-5">
                        <img src={connect1o1} alt="img" className="shadow-sm" />
                    </Grid>
                </Grid>
            </Container>

            <div className="section-one">
                <Container maxWidth="lg" className="proposition">
                    <Grid container spacing={0} className="two-sections justify-content-between">
                        <Grid item xs={12} md={6} className="order-last">
                            <figure className="h-100">
                                <img src={SearchResultsUsers} alt="img" className="shadow-sm" />
                            </figure>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box className="content" display="flex" alignItems="center" alignContent="center" height="100%" flexDirection="column">
                                <Box marginY="auto">
                                    <Typography variant="h2" className="title-one">
                                        Find The Right Experts
                                    </Typography>

                                    <p>Search our growing network of small business experts across diverse markets and practice areas. Use ratings to narrow your search.</p>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="lg" className="proposition">
                    <Grid container spacing={0} className="two-sections justify-content-between">
                        <Grid item xs={12} md={6} className="order-last order-lg-0 ">
                            <figure className="h-100">
                                <img src={CalendarAppointment} alt="img" className="shadow-sm" />
                            </figure>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box className="content" display="flex" alignItems="center" alignContent="center" height="100%" flexDirection="column">
                                <Box marginY="auto">
                                    <Typography variant="h2" className="title-one">
                                        Schedule Calls With Ease
                                    </Typography>
                                    <p>Easily find and request a mutually convenient date &amp; time to connect with experts by accessing their calendar.</p>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="lg" className="proposition">
                    <Grid container spacing={0} className="two-sections justify-content-between">
                        <Grid item xs={12} md={6} className="order-last">
                            <figure className="h-100">
                                <img src={AudioVideoConference} alt="img" className="shadow-sm" />
                            </figure>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box className="content" display="flex" alignItems="center" alignContent="center" height="100%" flexDirection="column">
                                <Box marginY="auto">
                                    <Typography variant="h2" className="title-one">
                                        Use Built-In Conferencing
                                    </Typography>
                                    <p>Connect with experts via our secure audio/video conferencing tool without leaving the platform.</p>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="lg" className="proposition">
                    <Grid container spacing={0} className="two-sections justify-content-between">
                        <Grid item xs={12} md={6} className="order-last order-lg-0 ">
                            <figure className="h-100">
                                <img src={ConsultationCharges} alt="img" className="shadow-sm" />
                            </figure>
                        </Grid>
                        <Grid item xs={12} md={5} className="align-items-center">
                            <Box className="content" display="flex" alignItems="center" alignContent="center" height="100%" flexDirection="column">
                                <Box marginY="auto">
                                    <Typography variant="h2" className="title-one">
                                        Get Cost Effective Consultations
                                    </Typography>
                                    <p>With transparent, time-based pricing and no hidden or recurring fees, engage experts with complete peace of mind.</p>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <Box className="ml-home-action">
                <Container maxWidth="lg">
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                            <Box className="ml-hm">
                                <Typography variant="h2" className="heading-1 mb-5 text-capitalize">
                                    Sustain &amp; grow your small business with MarketLync
                                </Typography>
                                <Box className="d-flex flex-wrap justify-content-center">
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                        <RouterLink to="/contact/product-demo-request">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" /> Schedule a Free Demo{' '}
                                                </Button>
                                            </RouterLink>
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                        <Link href={`${BASE_URL}/auth/signup`} rel="noreferrer">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> Join MarketLync{' '}
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default OneonOneConsultations;
