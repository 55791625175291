import emailjs from 'emailjs-com';
export  const SendEmail = (e: React.FormEvent<HTMLFormElement> , callback:(result:any, error:any) => any) => {
    e.preventDefault();

    emailjs.sendForm('service_vz9s1rf', 'template_ttxt8na', "contact_form", 'user_c1P3qrBVo1r4sbHnNgYan')
      .then((result: { text: any; }) => {
        //console.log(result.text);
        callback(result,undefined)
      }, (error: { text: any; }) => {
        //console.log(error.text);
        callback(undefined,error)
      });
  }