import { Box, Container, Grid, Typography } from '@material-ui/core';
import { FC } from 'react';

import { imgBanner1, imgVideo, imgUser } from '../../assets/images';

// import your fontawesome library

interface IAboutusProps {}

const Aboutus: FC<IAboutusProps> = () => {
    return (
        <>
            <Box className="top-inner-banner">
                <figure className="h-100">
                    <img src={imgBanner1} alt="img" />
                </figure>
                <Box className="inner-banner-content">
                    <Typography variant="h1" className="text-display">
                        About Us
                    </Typography>
                </Box>
            </Box>
            <Grid container justify="center">
                <Grid item xs={12} md={6} className="typo-center">
                    <Typography variant="h2" className="ml-display-2">
                        One line of benefits sentence goes here
                    </Typography>
                    <Typography className="text-center mb-4 fs-16">Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor ac odio mauris justo. Luctus arcu, urna praesent at id quisque ac. Arcu es massa vestibulum malesuada, integer vivamus elit eu mauris eus, cum eros quis aliquam wisi. Nulla wisi laoreet suspendisse integer vivamus elit eu mauris hendrerit facilisi, mi mattis pariatur aliquam pharetra eget.</Typography>
                </Grid>
            </Grid>
            <Grid container justify="center">
                <Grid item xs={12} md={6} className="d-flex justify-content-center align-items-center mb-5">
                    <img src={imgVideo} alt="img" />
                </Grid>
            </Grid>
            <Grid container justify="center">
                <Grid item xs={12} md={6} className="typo-center">
                    <Typography variant="h2" className="ml-display-2">
                        Meet The Team
                    </Typography>
                </Grid>{' '}
            </Grid>

            <Container maxWidth="lg" className="meet-the-team">
                <Grid container justify="center" spacing={4}>
                    <Grid item xs={12} md={4} lg={4}>
                        <Box className="member">
                            <figure>
                                <img src={imgUser} alt="userName" />
                            </figure>
                            <Typography variant="h3">Tylor Swiss</Typography>
                            <Typography variant="h4">Senior Advisor</Typography>
                            <Typography>Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor ac odio mauris justo. Luctus arcu, urna praesent at id quisque ac.</Typography>
                        </Box>{' '}
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Box className="member">
                            <figure>
                                <img src={imgUser} alt="userName" />
                            </figure>
                            <Typography variant="h3">Tylor Swiss</Typography>
                            <Typography variant="h4">Senior Advisor</Typography>
                            <Typography>Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor ac odio mauris justo. Luctus arcu, urna praesent at id quisque ac.</Typography>
                        </Box>{' '}
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Box className="member">
                            <figure>
                                <img src={imgUser} alt="userName" />
                            </figure>
                            <Typography variant="h3">Tylor Swiss</Typography>
                            <Typography variant="h4">Senior Advisor</Typography>
                            <Typography>Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor ac odio mauris justo. Luctus arcu, urna praesent at id quisque ac.</Typography>
                        </Box>{' '}
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Box className="member">
                            <figure>
                                <img src={imgUser} alt="userName" />
                            </figure>
                            <Typography variant="h3">Tylor Swiss</Typography>
                            <Typography variant="h4">Senior Advisor</Typography>
                            <Typography>Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor ac odio mauris justo. Luctus arcu, urna praesent at id quisque ac.</Typography>
                        </Box>{' '}
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Box className="member">
                            <figure>
                                <img src={imgUser} alt="userName" />
                            </figure>
                            <Typography variant="h3">Tylor Swiss</Typography>
                            <Typography variant="h4">Senior Advisor</Typography>
                            <Typography>Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor ac odio mauris justo. Luctus arcu, urna praesent at id quisque ac.</Typography>
                        </Box>{' '}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default Aboutus;
