import React from 'react';
import './App.scss';
import {AppRouter} from './AppRouter';
import LogRocket from 'logrocket';


function App() {
    LogRocket.init('jppjgn/marketlynccom');
    return <AppRouter />;
}

export default App;
