import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#3392c3',
            main: '#0077b5',
            dark: '#00537e',
            contrastText: '#fff',
        },
        secondary: {
            light: '#53646e',
            main: '#283e4a',
            dark: '#1c2b33',
            contrastText: '#FFF',
        },
        background: {
            paper: '#fff',
            default: '#fff',
        },
    },
    typography: {
        fontFamily: '"Roboto","Helvetica Neue",Arial,sans-serif',
        fontSize: 14,
        htmlFontSize: 14,
        body1: {
            fontSize: 14,
        },
        button: {
            textTransform: 'none',
        },
    },

    overrides: {
        //Style sheet name ⚛️
        MuiButton: {
            // Name of the rule
            containedPrimary: {
                color: 'white',
            },
        },
    },
});
