import * as React from 'react';
import { Box, Container, Grid, Link, Typography } from '@material-ui/core';
import { FC } from 'react';

import { post01, post02, post03, post04, post05, post06, post07, post08, post09, post10, post11, post12, OneOnOneConsultations } from '../../assets/images';
// import your fontawesome library

interface IExpertInsightsProps {}

const Insights: FC<IExpertInsightsProps> = (props) => {
    return (
        <>
            <Box className="top-inner-banner mb-0">
                <figure className="h-100">
                    <img src={OneOnOneConsultations} alt="img" />
                </figure>
            </Box>
            <Container maxWidth="lg">
                <Grid container justify="center" spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                        <Box className="ml-hm">
                            <Typography variant="h2" className="heading-1 text-capitalize">
                                MarketLync<sup>®</sup> Insights For Small Businesses
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Box className="insights-wrapper">
                <Box className="insight-filter post-archive-filter mb-5">
                    <Box className="block-accent"></Box>
                </Box>
                <Container maxWidth="lg">
                    <Grid container justify="center" spacing={4} className="mb-5">
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Box className="post-archive-list-item-wrapper h-100">
                                <div className="post-archive-list-item">
                                    <Link href={`/insights/3-Key-Types-of-Digital-and-Social-Media-Marketing-for-Small-Businesses`} className="post-archive-list-item-image">
                                        <figure>
                                            <img src={post01} alt="img" className="shadow-sm" />
                                        </figure>
                                    </Link>
                                    <div className="post-archive-list-item-content">
                                        <span className="insights-list-item-type">Article</span>
                                        <h2 className="post-archive-list-item-title">
                                            <Link href={`/insights/3-Key-Types-of-Digital-and-Social-Media-Marketing-for-Small-Businesses`}>
                                                <span>3 Key Types of Digital and Social Media Marketing for Small Businesses</span>
                                            </Link>
                                        </h2>
                                        <div className="insights-list-item-tags">
                                            <span>
                                                <span className="insights-list-item-tag">Digital &amp; Social Media Marketing</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Box className="post-archive-list-item-wrapper h-100">
                                <div className="post-archive-list-item">
                                    <Link href={`/insights/The-4-Step-Small-Biz-Marketing-Strategy-to-Beat-Your-Competition`} className="post-archive-list-item-image">
                                        <figure>
                                            <img src={post02} alt="img" className="shadow-sm" />
                                        </figure>
                                    </Link>
                                    <div className="post-archive-list-item-content">
                                        <span className="insights-list-item-type">Article</span>
                                        <h2 className="post-archive-list-item-title">
                                            <Link href={`/insights/The-4-Step-Small-Biz-Marketing-Strategy-to-Beat-Your-Competition`}>
                                                <span>The 4-Step Small Biz Marketing Strategy to Beat Your Competition</span>
                                            </Link>
                                        </h2>
                                        <div className="insights-list-item-tags">
                                            <span>
                                                <span className="insights-list-item-tag">Marketing Strategy &amp; Competition</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Box className="post-archive-list-item-wrapper h-100">
                                <div className="post-archive-list-item">
                                    <Link href={`/insights/4-Small-Business-Funding-Options-to-Fuel-Your-Growth`} className="post-archive-list-item-image">
                                        <figure>
                                            <img src={post03} alt="img" className="shadow-sm" />
                                        </figure>
                                    </Link>
                                    <div className="post-archive-list-item-content">
                                        <span className="insights-list-item-type">Article</span>
                                        <h2 className="post-archive-list-item-title">
                                            <a href={`/insights/4-Small-Business-Funding-Options-to-Fuel-Your-Growth`}>
                                                <span>4 Small Business Funding Options to Fuel Your Growth</span>
                                            </a>
                                        </h2>
                                        <div className="insights-list-item-tags">
                                            <span>
                                                <span className="insights-list-item-tag">Funding, Loans, &amp; Investor Relations</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Box className="post-archive-list-item-wrapper h-100">
                                <div className="post-archive-list-item">
                                    <Link href={`/insights/3-Ways-To-Quickly-Improve-Your-Customer-Experience`} className="post-archive-list-item-image">
                                        <figure>
                                            <img src={post04} alt="img" className="shadow-sm" />
                                        </figure>
                                    </Link>
                                    <div className="post-archive-list-item-content">
                                        <span className="insights-list-item-type">Article</span>
                                        <h2 className="post-archive-list-item-title">
                                            <Link href={`/insights/3-Ways-To-Quickly-Improve-Your-Customer-Experience`}>
                                                <span>3 Ways To Quickly Improve Your Customer Experience</span>
                                            </Link>
                                        </h2>
                                        <div className="insights-list-item-tags">
                                            <span>
                                                <span className="insights-list-item-tag">Customer Service &amp; Experience</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Box className="post-archive-list-item-wrapper h-100">
                                <div className="post-archive-list-item">
                                    <Link href={`/insights/Financial-Management-and-Accounting-for-Small-Businesses`} className="post-archive-list-item-image">
                                        <figure>
                                            <img src={post05} alt="img" className="shadow-sm" />
                                        </figure>
                                    </Link>
                                    <div className="post-archive-list-item-content">
                                        <span className="insights-list-item-type">Article</span>
                                        <h2 className="post-archive-list-item-title">
                                            <Link href={`/insights/Financial-Management-and-Accounting-for-Small-Businesses`}>
                                                <span>Financial Management and Accounting for Small Businesses</span>
                                            </Link>
                                        </h2>
                                        <div className="insights-list-item-tags">
                                            <span>
                                                <span className="insights-list-item-tag"> Financial Management &amp; Accounting </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Box className="post-archive-list-item-wrapper h-100">
                                <div className="post-archive-list-item">
                                    <Link href={`/insights/Managing-Operations-in-a-Small-Business-What-Are-Your-Priorities`} className="post-archive-list-item-image">
                                        <figure>
                                            <img src={post06} alt="img" className="shadow-sm" />
                                        </figure>
                                    </Link>
                                    <div className="post-archive-list-item-content">
                                        <span className="insights-list-item-type">Article</span>
                                        <h2 className="post-archive-list-item-title">
                                            <Link href={`/insights/Managing-Operations-in-a-Small-Business-What-Are-Your-Priorities`}>
                                                <span>Managing Operations in a Small Business: What Are Your Priorities?</span>
                                            </Link>
                                        </h2>
                                        <div className="insights-list-item-tags">
                                            <span>
                                                <span className="insights-list-item-tag">Operations &amp; Logistics</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Box className="post-archive-list-item-wrapper h-100">
                                <div className="post-archive-list-item">
                                    <Link href={`/insights/4-Types-Of-Legal-Entities-To-Setup-Your-Business-Pros-&-Cons-Of-Each`} className="post-archive-list-item-image">
                                        <figure>
                                            <img src={post07} alt="img" className="shadow-sm" />
                                        </figure>
                                    </Link>
                                    <div className="post-archive-list-item-content">
                                        <span className="insights-list-item-type">Article</span>
                                        <h2 className="post-archive-list-item-title">
                                            <Link href={`/insights/4-Types-Of-Legal-Entities-To-Setup-Your-Business-Pros-&-Cons-Of-Each`}>
                                                <span>4 Types Of Legal Entities To Setup Your Business: Pros &amp; Cons Of Each</span>
                                            </Link>
                                        </h2>
                                        <div className="insights-list-item-tags">
                                            <span>
                                                <span className="insights-list-item-tag">Legal</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Box className="post-archive-list-item-wrapper h-100">
                                <div className="post-archive-list-item">
                                    <Link href={`/insights/Why-Small-Business-Owners-Need-To-Be-Good-Leaders`} className="post-archive-list-item-image">
                                        <figure>
                                            <img src={post08} alt="img" className="shadow-sm" />
                                        </figure>
                                    </Link>
                                    <div className="post-archive-list-item-content">
                                        <span className="insights-list-item-type">Article</span>
                                        <h2 className="post-archive-list-item-title">
                                            <Link href={`/insights/Why-Small-Business-Owners-Need-To-Be-Good-Leaders`}>
                                                <span>Why Small Business Owners Need To Be Good Leaders (And Delegators)</span>
                                            </Link>
                                        </h2>
                                        <div className="insights-list-item-tags">
                                            <span>
                                                <span className="insights-list-item-tag">Leadership &amp; Management</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Box className="post-archive-list-item-wrapper h-100">
                                <div className="post-archive-list-item">
                                    <Link href={`/insights/6-Automations-You-Can-Implement-In-Your-Small-Business-This-Week`} className="post-archive-list-item-image">
                                        <figure>
                                            <img src={post09} alt="img" className="shadow-sm" />
                                        </figure>
                                    </Link>
                                    <div className="post-archive-list-item-content">
                                        <span className="insights-list-item-type">Article</span>
                                        <h2 className="post-archive-list-item-title">
                                            <Link href={`/insights/6-Automations-You-Can-Implement-In-Your-Small-Business-This-Week`}>
                                                <span>6 Automations You Can Implement In Your Small Business This Week  </span>
                                            </Link>
                                        </h2>
                                        <div className="insights-list-item-tags">
                                            <span>
                                                <span className="insights-list-item-tag">Technology &amp; Automation</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Box className="post-archive-list-item-wrapper h-100">
                                <div className="post-archive-list-item">
                                    <Link href={`/insights/Small-Business-Hiring-4-Priorities-When-Investing-In-Human-Resources`} className="post-archive-list-item-image">
                                        <figure>
                                            <img src={post10} alt="img" className="shadow-sm" />
                                        </figure>
                                    </Link>
                                    <div className="post-archive-list-item-content">
                                        <span className="insights-list-item-type">Article</span>
                                        <h2 className="post-archive-list-item-title">
                                            <Link href={`/insights/Small-Business-Hiring-4-Priorities-When-Investing-In-Human-Resources`}>
                                                <span>Small Business Hiring: 4 Priorities When Investing In Human Resources</span>
                                            </Link>
                                        </h2>
                                        <div className="insights-list-item-tags">
                                            <span>
                                                <span className="insights-list-item-tag">HR &amp; Staffing</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Grid>

                        
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Box className="post-archive-list-item-wrapper h-100">
                                <div className="post-archive-list-item">
                                    <Link href={`/insights/6-Simple-Steps-to-Help-You-Grow-Strategize-and-Analyze-Your-Small-Business`} className="post-archive-list-item-image">
                                        <figure>
                                            <img src={post11} alt="img" className="shadow-sm" />
                                        </figure>
                                    </Link>
                                    <div className="post-archive-list-item-content">
                                        <span className="insights-list-item-type">Article</span>
                                        <h2 className="post-archive-list-item-title">
                                            <Link href={`/insights/6-Simple-Steps-to-Help-You-Grow-Strategize-and-Analyze-Your-Small-Business`}>
                                                <span>6 Simple Steps to Help You Grow, Strategize, and Analyze Your Small Business  </span>
                                            </Link>
                                        </h2>
                                        <div className="insights-list-item-tags">
                                            <span>
                                                <span className="insights-list-item-tag">Strategy &amp; Business Analytics</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Box className="post-archive-list-item-wrapper h-100">
                                <div className="post-archive-list-item">
                                    <Link href={`/insights/Why-Sales-and-Customer-Relationship-Management-Matter-for-Small-Businesses`} className="post-archive-list-item-image">
                                        <figure>
                                            <img src={post12} alt="img" className="shadow-sm" />
                                        </figure>
                                    </Link>
                                    <div className="post-archive-list-item-content">
                                        <span className="insights-list-item-type">Article</span>
                                        <h2 className="post-archive-list-item-title">
                                            <Link href={`/insights/Why-Sales-and-Customer-Relationship-Management-Matter-for-Small-Businesses`}>
                                                <span>Why Sales and Customer Relationship Management Matter for Small Businesses</span>
                                            </Link>
                                        </h2>
                                        <div className="insights-list-item-tags">
                                            <span>
                                                <span className="insights-list-item-tag">Sales &amp; Customer Relations</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Grid>
                        {/* <div className="mt-5">
                        <Pagination count={10} />
                    </div> */}
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default Insights;
