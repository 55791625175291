import * as React from 'react';
import { Box, Button, Container, Grid, Typography , Link } from '@material-ui/core';
import { FC } from 'react';
import { Link as RouterLink} from 'react-router-dom';

import { ApplicationForm, ExpertProfile, AudioVideoConference, managepayements, AnsweraQuestion, Payments, ImgBecomeAnExpert } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../../AppConfig';
// import your fontawesome library

interface IExpertconsultationsProps {}

const BecomeAnExpert: FC<IExpertconsultationsProps> = (props) => {
    return (
        <>
            <Box className="top-inner-banner mb-0">
                <figure className="h-100">
                    <img src={ImgBecomeAnExpert} alt="img" />
                </figure>
            </Box>
            <Container maxWidth="lg">
                <Grid container justify="center" spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                        <Box className="ml-hm">
                            <Box className="heading-1 text-capitalize ">
                                <div className="d-md-flex align-items-center justify-content-center mt-3">
                                    Small Businesses Are Our Engine For{' '}
                                    <Box component="div" className="text-blue d-block d-md-inline-block punchline position-relative overflow-hidden ml-md-3">
                                        <Box className="content">
                                            <Box className="content__container">
                                                <ul className="content__container__list">
                                                    <li className="content__container__list__item">Growth</li>
                                                    <li className="content__container__list__item">Jobs</li>
                                                    <li className="content__container__list__item">Prosperity</li>
                                                    <li className="content__container__list__item">Innovation</li>
                                                </ul>
                                            </Box>
                                        </Box>
                                    </Box>
                                </div>
                                <div>
                                    <span className="text-blue">YOU</span> Have The Expertise To <span className="text-blue">Boost</span> It!
                                </div>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            {/* <Container maxWidth="lg">
                <Grid container justify="center">
                    <Grid item xs={12} md={6} className="d-flex justify-content-center align-items-center mb-5">
                        <video src="https://s3.amazonaws.com/codecademy-content/courses/React/react_video-fast.mp4" autoPlay muted loop></video>                       
                    </Grid>
                </Grid>
            </Container> */}
            <div className="section-one">
                <Container maxWidth="lg" className="proposition">
                    <Grid container spacing={0} className="two-sections justify-content-between">
                        <Grid item xs={12} md={6} className="order-last">
                            <figure className="h-100">
                                <img src={ApplicationForm} alt="img" className="shadow-sm" />
                            </figure>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box className="content" display="flex" alignItems="center" alignContent="center" height="100%" flexDirection="column">
                                <Box marginY="auto">
                                    <Typography variant="h2" className="title-one">
                                        Apply to Become a MarketLync Expert
                                    </Typography>
                                    <p>Use our simple application form to apply and become an expert on the MarketLync platform.</p>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="lg" className="proposition">
                    <Grid container spacing={0} className="two-sections justify-content-between">
                        <Grid item xs={12} md={6} className="order-last order-lg-0 ">
                            <figure className="h-100">
                                <img src={ExpertProfile} alt="img" className="shadow-sm" />
                            </figure>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box className="content" display="flex" alignItems="center" alignContent="center" height="100%" flexDirection="column">
                                <Box marginY="auto">
                                    <Typography variant="h2" className="title-one">
                                        Market Your Expertise
                                    </Typography>
                                    <p>With your own profile page, ratings from other members, and active promotion by MarketLync, start showcasing your expertise and contributing to the growth of small businesses.</p>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="lg" className="proposition">
                    <Grid container spacing={0} className="two-sections justify-content-between">
                        <Grid item xs={12} md={6} className="order-last">
                            <figure className="h-100">
                                <img src={AudioVideoConference} alt="img" className="shadow-sm" />
                            </figure>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box className="content" display="flex" alignItems="center" alignContent="center" height="100%" flexDirection="column">
                                <Box marginY="auto">
                                    <Typography variant="h2" className="title-one">
                                        Provide 1-on-1 Consultations
                                    </Typography>
                                    <p>Get paid for providing secure audio/video consultations to other members at your own schedule without leaving the platform. Use the in-built calendar to manage your availability.</p>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="lg" className="proposition">
                    <Grid container spacing={0} className="two-sections justify-content-between">
                        <Grid item xs={12} md={6} className="order-last order-lg-0 ">
                            <figure className="h-100">
                                <img src={AnsweraQuestion} alt="img" className="shadow-sm" />
                            </figure>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box className="content" display="flex" alignItems="center" alignContent="center" height="100%" flexDirection="column">
                                <Box marginY="auto">
                                    <Typography variant="h2" className="title-one">
                                        Share Invaluable Insights by Answering Questions
                                    </Typography>
                                    <p>Answer questions from other members of the MarketLync small business community and potentially get paid for your insights.</p>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="lg" className="proposition">
                    <Grid container spacing={0} className="two-sections justify-content-between">
                        <Grid item xs={12} md={6} className="order-last">
                            <figure className="h-100">
                                <img src={managepayements} alt="img" className="shadow-sm" />
                            </figure>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box className="content" display="flex" alignItems="center" alignContent="center" height="100%" flexDirection="column">
                                <Box marginY="auto">
                                    <Typography variant="h2" className="title-one">
                                        Manage Activity With Expert Dashboard
                                    </Typography>
                                    <p>Answer questions, share insights privately, manage and provide consultations through your personal expert dashboard.</p>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="lg" className="proposition">
                    <Grid container spacing={0} className="two-sections justify-content-between">
                        <Grid item xs={12} md={6} className="order-last order-lg-0 ">
                            <figure className="h-100">
                                <img src={Payments} alt="img" className="shadow-sm" />
                            </figure>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Box className="content" display="flex" alignItems="center" alignContent="center" height="100%" flexDirection="column">
                                <Box marginY="auto">
                                    <Typography variant="h2" className="title-one">
                                        Manage Payments Through Secure Platform
                                    </Typography>
                                    <p>View your financial transactions on MarketLync and transfer funds to your bank account through our integrated payments management service with Stripe – the World’s leading online payments processing platform.</p>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <Box className="ml-home-action">
                <Container maxWidth="lg">
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                            <Box className="ml-hm">
                                <Typography variant="h2" className="heading-1 mb-5 text-capitalize">
                                    <Box component="span" className="text-blue">
                                        Empower
                                    </Box>{' '}
                                    Small Businesses With{' '}
                                    <Box component="span" className="text-blue">
                                        Your
                                    </Box>{' '}
                                    Expertise
                                </Typography>
                                <Box className="d-flex flex-wrap justify-content-center">
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                        <RouterLink to="/contact/product-demo-request">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" /> Schedule a Free Demo{' '}
                                                </Button>
                                            </RouterLink>
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            
                                             <Link href={`${BASE_URL}/auth/signup`} rel="noreferrer">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> Join MarketLync{' '}
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default BecomeAnExpert;
