import * as React from 'react';
import { Box, Button, Container, Grid, Typography, Link } from '@material-ui/core';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { post02Detail } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../../AppConfig';
// import your fontawesome library

interface IExpertconsultationsProps {}

const BeatYourCompetition: FC<IExpertconsultationsProps> = (props) => {
    return (
        <>
            <Box className="top-inner-banner top-lg mb-0">
                <figure className="h-100">
                    <img src={post02Detail} alt="img" />
                </figure>
            </Box>
            <Box className="container">
                <Box className="row">
                    <Box className="col-12">
                        <Box className="insight-content-top d-flex justify-content-end">
                            <div className="insight-share">
                                <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fmarketlync.com/insights/The-4-Step-Small-iz-Marketing-Strategy-to-Beat-Your-Competition%2F&amp;title=The%204-Step%20Small%20Biz%20Marketing%20Strategy%20To%20Beat%20Your%20Competition" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-linkedin-in fa-w-14">
                                        <path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                                    </svg>
                                </a>
                                <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmarketlync.com/insights/The-4-Step-Small-iz-Marketing-Strategy-to-Beat-Your-Competition" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-facebook-f fa-w-10">
                                        <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                    </svg>
                                </a>
                                <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fmarketlync.com/insights/The-4-Step-Small-iz-Marketing-Strategy-to-Beat-Your-Competition%2F&amp;via=MarketLync&amp;text=The%204-Step%20Small%20Biz%20Marketing%20Strategy%20To%20Beat%20Your%20Competition" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-twitter fa-w-16">
                                        <path
                                            fill="currentColor"
                                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                        ></path>
                                    </svg>
                                </a>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box component="section" className="bpost-wrapper">
                <Box className="container">
                    <Box className="row">
                        <Box className="col-12 col-md-5 col-lg-4">
                            <div className="insight-content-header">
                                <h1 className="insight-content-title">The 4-Step Small Biz Marketing Strategy to Beat Your Competition</h1>
                                <p className="insight-content-author">Amit Sachdeva</p>
                                <div className="insights-list-item-tags">
                                    <span>
                                        <span className="insights-list-item-tag">Marketing Strategy &amp; Competition</span>
                                    </span>
                                </div>
                            </div>
                        </Box>
                        <Box className="col-12 col-md-7 col-lg-8">
                            <Box className="insight-content-body long-form-text">
                                <p>Ever wonder why customers choose your competitors over you? It might not be because of better products or prices. It might all come down to how they market.</p>
                                <p>If you want to show you’re the best in your industry, start with this four-step small biz marketing strategy.</p>

                                <h2>
                                    <strong>Define Your Value </strong>
                                </h2>
                                <p>Before you start looking at your competitors, it’s important to understand what you bring to the table. Why should people choose you? What do you do better than anyone else?</p>
                                <p>
                                    This is what we call your{' '}
                                    <Link href="https://www.shopify.com/blog/unique-selling-proposition" target="_blank" rel="noreferrer" className="underline">
                                        Unique Selling Proposition
                                    </Link>
                                    , or USP. It’s the value you provide that gives people a reason to choose you. If two businesses are just alike, one isn’t really needed.
                                </p>

                                <p>Your USP will drive your marketing strategy. You’ll want to communicate it in your messaging, whether it’s lower prices, better brands, warranties, return policies, free shipping, or anything else that will attract customers.</p>

                                <hr />
                                <h2>
                                    <strong>Know Your Competition</strong>
                                </h2>
                                <p>
                                    You can’t beat your competition until you know who your competitors are. By definition, a competitor is any business that shares your audience and can potentially take sales away from you. This includes direct competition, such as a business with a similar product offering as yours, as well as{' '}
                                    <Link href="https://www.wixanswers.com/post/direct-indirect-competition-business" target="_blank" rel="noreferrer" className="underline">
                                        indirect competition
                                    </Link>
                                    , in which products may differ but may be an alternative choice for the customer.
                                </p>

                                <p>For example, a soda company and a water company are indirect competitors. They sell different products, but both can serve the same customer needs.</p>

                                <hr />
                                <h2>
                                    <strong>Build an Offer Around Competitor Weaknesses</strong>
                                </h2>
                                <p> Take stock of your competitors and how they’re marketing themselves so you can fine-tune your own strategy. Specifically, look for areas where they appear to be weak and see how you might fill the gap. </p>

                                <p>Social media and online reviews are great places to sniff out competitors’ weaknesses. Customers will willingly share what they dislike about a company. Tailor your offer and messaging to make you look like the better choice.</p>

                                <hr />
                                <h2>
                                    <strong>Choose Your Marketing Channels</strong>
                                </h2>
                                <p>Got an offer? It’s time to share it! Choose the right channels to reach your target audience: social media platforms, email, paid ads, direct mail, or other means of connecting. </p>
                                <p>Marketing your small business is a key part of your growth. Not only will it help you get more sales, but it can also mean stealing customers away from your biggest competitors.</p>

                                <p>
                                    Need help putting your small biz marketing strategy into motion? {/* <a href="https://knowledge.marketlync.com/auth/signup">sd</a> */}
                                    <Link href={`${BASE_URL}/auth/signup`} rel="noreferrer" target="_blank" className="underline">
                                        Connect with an industry expert today!
                                    </Link>
                                </p>
                                <hr />
                            </Box>
                            <div className="ctct-inline-form" data-form-id="e43177e8-a8fc-4ecf-889c-c7e1a5d74b31"></div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box className="ml-home-action">
                <Container maxWidth="lg">
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                            <Box className="ml-hm">
                                <Typography variant="h2" className="heading-1 mb-5 text-capitalize">
                                    Sustain &amp; grow your small business with MarketLync
                                </Typography>
                                <Box className="d-flex flex-wrap justify-content-center">
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <RouterLink to="/contact/product-demo-request">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" /> Schedule a Free Demo{' '}
                                                </Button>
                                            </RouterLink>
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <Link href={`${BASE_URL}/auth/signup`} rel="noreferrer">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> Join MarketLync{' '}
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default BeatYourCompetition;
