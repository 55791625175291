// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons

import { fab } from '@fortawesome/free-brands-svg-icons';

import { faGithubAlt, faGoogle, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

library.add(fab, faGithubAlt, faGoogle, faFacebook, faTwitter, faAngleRight);
