import * as React from 'react';
import { Box, Button, Container, Grid, Typography, Link } from '@material-ui/core';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { post05Detail } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../../AppConfig';

// import your fontawesome library

interface IExpertconsultationsProps {}

const FinancialManagementAndAccounting: FC<IExpertconsultationsProps> = (props) => {
    return (
        <>
            <Box className="top-inner-banner top-lg mb-0">
                <figure className="h-100">
                    <img src={post05Detail} alt="img" />
                </figure>
            </Box>
            <Box className="container">
                <Box className="row">
                    <Box className="col-12">
                        <Box className="insight-content-top d-flex justify-content-end">
                            <div className="insight-share">
                                <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fmarketlync.com/insights/Financial-Management-and-Accounting-for-Small-Businesses%2F&amp;title=Financial%20Management%20And%20Accounting%20For%20Small%20Businesses" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-linkedin-in fa-w-14">
                                        <path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                                    </svg>
                                </a>
                                <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmarketlync.com/insights/Financial-Management-and-Accounting-for-Small-Businesses" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-facebook-f fa-w-10">
                                        <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                    </svg>
                                </a>
                                <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fmarketlync.com/insights/Financial-Management-and-Accounting-for-Small-Businesses%2F&amp;via=MarketLync&amp;text=Financial%20Management%20And%20Accounting%20For%20Small%20Businesses" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-twitter fa-w-16">
                                        <path
                                            fill="currentColor"
                                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                        ></path>
                                    </svg>
                                </a>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box component="section" className="bpost-wrapper">
                <Box className="container">
                    <Box className="row">
                        <Box className="col-12 col-md-5 col-lg-4">
                            <div className="insight-content-header">
                                <h1 className="insight-content-title">Financial Management and Accounting for Small Businesses</h1>
                                <p className="insight-content-author">Amit Sachdeva</p>
                                <div className="insights-list-item-tags">
                                    <span>
                                        <span className="insights-list-item-tag">Financial Management &amp; Accounting</span>
                                    </span>
                                </div>
                            </div>
                        </Box>
                        <Box className="col-12 col-md-7 col-lg-8">
                            <Box className="insight-content-body long-form-text">
                                <p>You wouldn’t expect to comfortably maintain your family if you were in the dark about your monthly income and outgoings, so why would things be any different for your business? Managing your cash flow might not be as glamorous as planning a new product launch or rebranding your website, but few things are more crucial to a firm’s survival.</p>
                                <p>
                                    In fact, some statistics suggest{' '}
                                    <a href="https://www.businessinsider.com/why-small-businesses-fail-infographic-2017-8?r=US&&IR=T" target="_blank" rel="noreferrer" className="underline">
                                        82% of small businesses
                                    </a>{' '}
                                    fail because of this very reason.
                                </p>
                                <p>Financial management might seem daunting if you don’t have a background in accounting or a head for numbers, but don’t let that hold you back. Here are three steps all small businesses can take to get started.</p>
                                <h2>
                                    <strong>1. Learn the basics of accounting </strong>
                                </h2>
                                <p>Even if you ultimately hope to outsource your accounting to someone else, it’s best to at least have a rudimentary understanding of what’s going on. If nothing else, you’ll at least be able to follow what your accountant is telling you and make some kind of plan for the future.</p>

                                <p>
                                    Don’t know where to start? One of the most crucial elements of accounting is knowing{' '}
                                    <a href="https://corporatefinanceinstitute.com/resources/knowledge/accounting/statement-of-cash-flows/" target="_blank" rel="noreferrer" className="underline">
                                        how to manage a cash flow statement
                                    </a>
                                    , so make sure you can tick that one off first.
                                </p>

                                <p>
                                    If you really want to give yourself a good grounding in the area, why not sign up for a
                                    <a href="https://www.udemy.com/courses/finance-and-accounting/accounting-bookkeeping/" target="_blank" rel="noreferrer" className="underline">
                                        course on Udemy
                                    </a>{' '}
                                    or a similar platform? The{' '}
                                    <a href="https://www.youtube.com/c/BenchCo/videos" target="_blank" rel="noreferrer" className="underline">
                                        Bench Accounting Youtube channel
                                    </a>{' '}
                                    also offers some useful (and free) resources.
                                </p>

                                <hr />
                                <h2>
                                    <strong>2. Don’t be too reluctant to invest</strong>
                                </h2>
                                <p>
                                    It goes without saying that you shouldn’t spend money you don’t have (unless you’ve worked out a{' '}
                                    <a href="https://www.thebalancesmb.com/four-reasons-to-take-out-a-business-loan-393255" target="_blank" rel="noreferrer" className="underline">
                                        smart strategy for taking out a loan
                                    </a>{' '}
                                    ).
                                </p>

                                <p> But many business owners take things to the other extreme by cutting their spending at every possible opportunity. The cheapest graphic designer probably isn’t the person who will design the most eye-catching logo (unless you get very lucky), and spending $100 a month on software that’s going to save you ten times more is a no-brainer. </p>
                                <p>To avoid making terrible financial decisions disguised as sensible ones, think about the possible returns that every purchase you make could yield. Of course, you should still make sure you stay within your budget — but keeping track of your finances carefully might help you realize you have more money to play with than you initially thought.</p>

                                <hr />
                                <h2>
                                    <strong>3. Take advantage of digital accounting solutions</strong>
                                </h2>
                                <p>If you’ve been in the business world for more than five minutes, you probably already know about accounting software giants like Quickbooks and Xero. But in most cases, they’re a complement to an accountant rather than a total replacement.</p>
                                <p>Fortunately, you can work with an accountant while still keeping your operations digital and lean.</p>
                                <p>
                                    For instance,{' '}
                                    <a href="https://1800accountant.com/" target="_blank" rel="noreferrer" className="underline">
                                        1800 Accountant
                                    </a>{' '}
                                    will connect you with CPAs and other professionals via its 100% online software, ensuring you can find an expert for every industry from wherever you’re located.{' '}
                                </p>

                                <hr />
                                <h3>
                                    <strong> Time to get your finances under control </strong>
                                </h3>
                                <p>The tips outlined above are a great starting point for getting your financial management on the right track, but it might be time to recruit a dedicated expert if you really want to take things to the next level.</p>

                                <p>That doesn’t necessarily mean you have to make a full-time hire, though.</p>
                                <p>
                                    At{' '}
                                    <a href="https://www.marketlync.com/" className="underline">
                                        MarketLync
                                    </a>{' '}
                                    , we provide a platform for small business owners like you to find experts in an array of areas, including financial management &amp; accounting.
                                </p>
                                <hr />
                            </Box>
                            <div className="ctct-inline-form" data-form-id="e43177e8-a8fc-4ecf-889c-c7e1a5d74b31"></div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box className="ml-home-action">
                <Container maxWidth="lg">
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                            <Box className="ml-hm">
                                <Typography variant="h2" className="heading-1 mb-5 text-capitalize">
                                    Sustain &amp; grow your small business with MarketLync
                                </Typography>
                                <Box className="d-flex flex-wrap justify-content-center">
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <RouterLink to="/contact/product-demo-request">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" /> Schedule a Free Demo{' '}
                                                </Button>
                                            </RouterLink>
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <Link href={`${BASE_URL}/auth/signup`} rel="noreferrer">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> Join MarketLync{' '}
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default FinancialManagementAndAccounting;
