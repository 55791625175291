import * as React from 'react';
import { Box, Button, Container, Grid, Typography, Link } from '@material-ui/core';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { post12Detail } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../../AppConfig';

// import your fontawesome library

interface IMatterForSmallBussinessProps {}

const MatterForSmallBussiness: FC<IMatterForSmallBussinessProps> = (props) => {
    return (
        <>
            <Box className="top-inner-banner top-lg mb-0">
                <figure className="h-100">
                    <img src={post12Detail} alt="img" />
                </figure>
            </Box>
            <Box className="container">
                <Box className="row">
                    <Box className="col-12">
                        <Box className="insight-content-top d-flex justify-content-end">
                            <div className="insight-share">
                                <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fmarketlync.com/insights/Why-Sales-and-Customer-Relationship-Management-Matter-for-Small-Businesses%2F&amp;title=Why%20Sales%20and%20Customer%20Relationship%20Management%20Matter%20for%20Small%20Businesses" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-linkedin-in fa-w-14">
                                        <path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                                    </svg>
                                </a>
                                <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmarketlync.com/insights/Why-Sales-and-Customer-Relationship-Management-Matter-for-Small-Businesses" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-facebook-f fa-w-10">
                                        <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                    </svg>
                                </a>
                                <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fmarketlync.com/insights/Why-Sales-and-Customer-Relationship-Management-Matter-for-Small-Businesses%2F&amp;via=MarketLync&amp;text=Why%20Sales%20and%20Customer%20Relationship%20Management%20Matter%20for%20Small%20Businesses" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-twitter fa-w-16">
                                        <path
                                            fill="currentColor"
                                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                        ></path>
                                    </svg>
                                </a>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box component="section" className="bpost-wrapper">
                <Box className="container">
                    <Box className="row">
                        <Box className="col-12 col-md-5 col-lg-4">
                            <div className="insight-content-header">
                                <h1 className="insight-content-title">Why Sales and Customer Relationship Management Matter for Small Businesses</h1>
                                <p className="insight-content-author">Amit Sachdeva</p>
                                <div className="insights-list-item-tags">
                                    <span>
                                        <span className="insights-list-item-tag">Sales &amp; Customer Relations</span>
                                    </span>
                                </div>
                            </div>
                        </Box>
                        <Box className="col-12 col-md-7 col-lg-8">
                            <Box className="insight-content-body long-form-text">
                                <p>Spreading the word about your business has never been easier, yet getting people to listen is harder than ever. Anyone can create a social media account for their company and start posting content or paying for ads, but with countless other businesses doing the same thing, how can you expect anyone to care?</p>

                                <p>Fortunately, there’s a solution: focus on selling. Here’s a quick overview of how you can harness basic sales principles to boost your chance of cutting through the noise.</p>

                                <h2 className="mb-4">Hire the right people</h2>

                                <p>Many aspects of sales and marketing might have moved online, but in most cases, personal interactions are still part of the process — whether that’s through video calls or in-person meetings. </p>
                                <p>
                                    Your staff are the face of your business. Putting the right people in front of your customers often makes the difference between success and failure, so don’t take it lightly. If nobody in your business has what it takes to talk the talk and make a good impression, consider
                                    <a href="https://blog.hubspot.com/sales/signs-you-should-hire-salesperson" target="_blank" rel="noreferrer" className="underline">
                                        making a new hire
                                    </a>{' '}
                                    .
                                </p>
                                <p>Look out for people who are good listeners, can think on their feet, and take a proactive approach (e.g., those who follow up before an interview and ask questions). </p>
                                <hr />
                                <h2>Retain your customers</h2>
                                <p>Don’t make the mistake of thinking that sales is all about attracting new customers. While this is undoubtedly important, any salesperson worth their salt will tell you that retaining existing customers is easier and less expensive than looking for new leads.</p>
                                <p>To retain your customers, you need to keep track of them and follow up regularly. Thanks to technology, this is easier than ever — a customer relationship management (CRM) tool will create a database of your contacts. You can then sort them into categories based on their activities and send them tailored emails.</p>
                                <p>Wondering which CRM tool to use? We’re one step ahead of you.</p>
                                <hr />
                                <h4>The best CRM tools</h4>
                                <p>If you think we might be onto something with this CRM business, you’re probably wondering how to get started.</p>
                                <p>Some of the most popular CRM tools include:</p>
                                <ul>
                                    <li>
                                        <a href="https://www.hubspot.com/" target="_blank" rel="noreferrer" className="underline">
                                            Hubspot
                                        </a>{' '}
                                        — Offers a free plan and syncs with your email inbox so you can automatically sync new leads.
                                    </li>
                                    <li>
                                        <a href="https://www.salesforce.com/uk/`" target="_blank" rel="noreferrer" className="underline">
                                            Salesforce
                                        </a>{' '}
                                        — Has more options and features than Hubspot but doesn’t have a free plan.
                                    </li>
                                    <li>
                                        <a href="https://www.constantcontact.com/" target="_blank" rel="noreferrer" className="underline">
                                            ConstantContact
                                        </a>{' '}
                                        — Another more complex option with no free plan, but it does have a free trial.
                                    </li>
                                </ul>
                                <p>Hubspot is generally the preferred option among small businesses, but that doesn’t necessarily make it the right choice for you. Why not test out a few platforms before you commit to your favorite?</p>
                                <hr />
                                <h2>Get your sales process down to a fine art</h2>

                                <p>You can’t finetune your sales process without placing the right people in front of your customers and making the most of the CRM tools out there. But you don’t have to do it all alone.</p>

                                <p>
                                    On{' '}
                                    <a href="https://www.marketlync.com/" rel="noreferrer" className="underline">
                                        MarketLync
                                    </a>{' '}
                                    , we help small businesses find the guidance and expertise they need to make informed decisions. So, browse through our database and find out if there’s someone who meets your needs. .
                                </p>
                                <hr />
                            </Box>
                            <div className="ctct-inline-form" data-form-id="e43177e8-a8fc-4ecf-889c-c7e1a5d74b31"></div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box className="ml-home-action">
                <Container maxWidth="lg">
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                            <Box className="ml-hm">
                                <Typography variant="h2" className="heading-1 mb-5 text-capitalize">
                                    Sustain &amp; grow your small business with MarketLync
                                </Typography>
                                <Box className="d-flex flex-wrap justify-content-center">
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <RouterLink to="/contact/product-demo-request">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" /> Schedule a Free Demo{' '}
                                                </Button>
                                            </RouterLink>
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <Link href={`${BASE_URL}/auth/signup`} rel="noreferrer">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> Join MarketLync{' '}
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default MatterForSmallBussiness;
