import { Box, Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { SendEmail } from './SendEmail';
import { FC, useEffect } from 'react';
import { imgBanner3 } from '../../assets/images';
import { useParams } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

interface ParamTypes {
  id: string
}

const ContactForm: FC = () => {

  const [formData, setFormData] = React.useState({
    category: "General Inquiry",
    name: "Contact Us",
    key: "contact-us",
    subject: "General Inquiry"
  });
  const [sendComplete, setSendComplete] = React.useState<boolean | undefined>(undefined)
  const { id } = useParams<ParamTypes>()
  
  

  const sendEmail = (event: any) => {
    SendEmail(event, (result: any, error: any) => {
      if (result) {
        setSendComplete(true)
      } else {
        setSendComplete(false)
      }
    })
  }

  useEffect(() => {
    const formDataList = [
      {
        category: "General Inquiry",
        name: "Contact Us",
        key: "general-inquiry",
        subject: "General Inquiry"
      },
      {
        category: "Product Demo",
        name: "Schedule Demo",
        key: "product-demo-request",
        subject: "Request a Free Demo"
      },
    ]

    const formDataReducer = (key: string) => {
      const data = formDataList.find((data) => {
        return data.key === key
      })
      return data ? data : {
        category: "General Inquiry",
        name: "Contact Us",
        key: "contact-us",
        subject: "General Inquiry"
      }
    }
    
    setFormData(formDataReducer(id))
  }, [id]);



  return <>
    <Box className="top-inner-banner">
      <figure className="h-100">
        <img src={imgBanner3} alt="img" />
      </figure>
      <Box className="inner-banner-content">
        <Typography variant="h1" className="text-display">
          {formData.name}
        </Typography>
      </Box>
    </Box>
    <Container maxWidth="lg" className="fs-16">
      <Grid item xs={12}>
        {sendComplete
          ? <SuccessMessage/>
          :
          <form className="contact-form" onSubmit={sendEmail} id="contact_form">
            <input type="hidden" name="category_name" value={formData.category} />
            <input type="hidden" name="form_name" value={formData.subject} />
            <label>Name *</label>
            <input type="text" name="contact_name" required/>
            <label>Email *</label>
            <input type="email" name="contact_email" required/>
            <label>Contact Number</label>
            <input type="text" name="contact_number" />
            <label>Subject *</label>
            <input type="text" name="subject" required value={formData.name}/>
            <label>Message *</label>
            <textarea name="message" required/>
            <input type="submit" value="Send" />
          </form>
        }
      </Grid>
    </Container>
  </>;
};

export default ContactForm;



const SuccessMessage: FC = () => {
  return <>
    <div className="form-success">
      <div>
        <figure className="mr-10">
          <FontAwesomeIcon icon={faCheckCircle} size="10x" color="green" />
        </figure>
      </div>
      <div>
        <h2>Thank You!</h2>
        <h3>Your submission has been received</h3>
      </div>
    </div>
  </>;
};
