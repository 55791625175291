import * as React from 'react';
import { Box, Button, Container, Grid, Typography, Link } from '@material-ui/core';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { post11Detail } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../../AppConfig';

// import your fontawesome library

interface IYourSmallBussinessProps {}

const YourSmallBussiness: FC<IYourSmallBussinessProps> = (props) => {
    return (
        <>
            <Box className="top-inner-banner top-lg mb-0">
                <figure className="h-100">
                    <img src={post11Detail} alt="img" />
                </figure>
            </Box>
            <Box className="container">
                <Box className="row">
                    <Box className="col-12">
                        <Box className="insight-content-top d-flex justify-content-end">
                            <div className="insight-share">
                                <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fmarketlync.com/insights/6-Simple-Steps-to-Help-You-Grow-Strategize-and-Analyze-Your-Small-Business%2F&amp;title=6%20Simple%20Steps%20to%20Help%20You%20Grow%20Strategize%20and%20Analyze%20Your%20Small%20Business" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-linkedin-in fa-w-14">
                                        <path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                                    </svg>
                                </a>
                                <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmarketlync.com/insights/6-Simple-Steps-to-Help-You-Grow-Strategize-and-Analyze-Your-Small-Business" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-facebook-f fa-w-10">
                                        <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                    </svg>
                                </a>
                                <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fmarketlync.com/insights/6-Simple-Steps-to-Help-You-Grow-Strategize-and-Analyze-Your-Small-Business%2F&amp;via=MarketLync&amp;text=6%20Simple%20Steps%20to%20Help%20You%20Grow%20Strategize%20and%20Analyze%20Your%20Small%20Business" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-twitter fa-w-16">
                                        <path
                                            fill="currentColor"
                                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                        ></path>
                                    </svg>
                                </a>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box component="section" className="bpost-wrapper">
                <Box className="container">
                    <Box className="row">
                        <Box className="col-12 col-md-5 col-lg-4">
                            <div className="insight-content-header">
                                <h1 className="insight-content-title">Why Small Business Owners Need To Be Good Leaders (And Delegators)</h1>
                                <p className="insight-content-author">Amit Sachdeva</p>
                                <div className="insights-list-item-tags">
                                    <span>
                                        <span className="insights-list-item-tag">Strategy &amp; Business Analytics</span>
                                    </span>
                                </div>
                            </div>
                        </Box>
                        <Box className="col-12 col-md-7 col-lg-8">
                            <Box className="insight-content-body long-form-text">
                                <p>One of small business owners’ biggest challenges is getting bogged down in daily operations and forgetting to look up and see the big picture. All too often, we get stuck in the daily grind of prospect – sell – do, and fail to strategize for the future.</p>
                                <p>But, as Benjamin Franklin said, “If you fail to plan, you plan to fail.” You need to remember that the only true constant in your business is change, and if you forget that, your business simply won’t have the longevity you’re striving for.</p>
                                <p>Imagine you walk into your kitchen and decide to make a dish you’ve never made before – only, you decide to do it without a recipe. You throw some ingredients in a bowl, mix them, and cook it. What you get looks kinda like what you were hoping for… but it’s not quite right. Now imagine you follow a recipe to a T. What you get is exactly what you were hoping for, maybe even better.</p>
                                <p>That’s what developing a growth roadmap can do for your business. And, even better, you can use business analytics to see if what you’re doing is working so you can improve as you go and get ahead of your competition. So, how do you develop a growth roadmap and track its success? And, perhaps more importantly, how do you do so when you feel like you don’t have a minute to spare?</p>
                                <h2 className="mb-4">
                                    <strong>How to Develop a Growth Roadmap in an Afternoon </strong>
                                </h2>

                                <h4>1) Refamiliarize Yourself with Your Vision </h4>
                                <p>Your first step is to refamiliarize yourself with the vision you had when you first started your business. There’s a big difference between that excitement we have before we start serving customers and the mindset we’re in years down the line. It’s well worth going back to that vision (if you can find it!) and spend 30 minutes </p>
                                <hr />
                                <h4>2) Perform a SWOT Analysis </h4>
                                <p>
                                    Your next step should be to perform a SWOT analysis on your business where it stands right now. This is where you identify your business’s strengths, weaknesses, opportunities, and threats (usually your competition, but there can be other threats in certain industries). You can find out more about how to complete a
                                    <a href="https://www.mindtools.com/pages/article/newTMC_05.htm" target="_blank" rel="noreferrer" className="underline">
                                        SWOT analysis here
                                    </a>{' '}
                                    . If you find that a SWOT analysis doesn’t feel like a good fit for you,
                                    <a href="https://www.nmblstrategies.com/blog/four-alternatives-to-a-swot-analysis" target="_blank" rel="noreferrer" className="underline">
                                        try another form of business analysis here
                                    </a>{' '}
                                    .{' '}
                                </p>
                                <hr />
                                <h4>3) Develop a “North Star Metric” </h4>
                                <p>
                                    The
                                    <a href="https://www.revelx.co/blog/growth-roadmap-step-by-step-how-to/" target="_blank" rel="noreferrer" className="underline">
                                        "north star metric"
                                    </a>{' '}
                                    is something you can track that defines whether you’re delivering the core value your service or product offers to customers. You may be able to measure this by tracking customer retention rates, revenue, customer acquisition rates, or something else entirely.
                                </p>
                                <hr />
                                <h4>4) Set Your Goals</h4>

                                <p>Set, or redefine, your goals, and then think about what tasks or ideas you have that would move your business toward achieving these goals. Remember, your goals should be growth-based (more revenue, customers, retention, or engagement – whatever matters most to your business over the next 90 days – 1 year). Your tasks are then things that can help you achieve those goals. For example, if you want to improve revenue, perhaps you can implement a new product, service, or simply make it easier for your clients to book and pay for your services.</p>

                                <hr />
                                <h4>5) Map Out Your Growth Strategy</h4>
                                <p>
                                    Now you’ve got the goals and the tasks, you’re almost ready to get back to your day-to-day, implementing a few of these tasks and ideas whenever you can. However, to get the best results, you need to map out your growth strategy. This includes identifying growth opportunities, solidifying your goals, your strategy, how you’ll execute on these opportunities and goals, and what you need to do to be ready to take these new opportunities. Revelx has a
                                    <a href="https://revelx.docsend.com/view/5bxa6nj" target="_blank" rel="noreferrer" className="underline">
                                        great free growth strategy template you can find here
                                    </a>{' '}
                                    . Once you complete this, you can wrap up your afternoon and head home!{' '}
                                </p>

                                <hr />
                                <h4>6) Track Metrics &amp; Perform Quarterly Reviews</h4>
                                <p>Okay, so you’re done with the growth mapping afternoon, but that doesn’t mean you can simply follow the to-do list and hope for the best. You need to perform regular analyses to see what’s working and what’s not. If possible, track your metrics on a weekly, bi-weekly, or monthly basis (depending on what works best for your business), and review the results each month. At the end of each quarter, review how your business has progressed on your growth roadmap. Is there anything you need to change? If you’ve had to throw your goals out for any reason (Coronavirus taught us that it can happen to us all!), review and reset. If you keep that long-term vision in mind and strive to meet it, your business will never become stagnant. </p>
                                <p>It’s easy to get swept away in the daily minutiae of running your small business, but as the owner, only you can create the vision and set the goals and tasks that will allow you to reach your growth goals. Make sure you repeat this process every 90 days to 1 year to ensure your ship is still pointing toward your ideal destination. </p>

                                <p>
                                    Do you still feel stuck and unsure about how to move forward, or is your business growing so fast you’re struggling to keep up with it? Both situations are a great opportunity to bring in a small business expert to help you gain clarity, solve problems, and see opportunities you are too close to see. You can find an expert to help you on{' '}
                                    <a href="https://www.marketlync.com/" rel="noreferrer" className="underline">
                                        MarketLync
                                    </a>{' '}
                                    .
                                </p>
                                <hr />
                            </Box>
                            <div className="ctct-inline-form" data-form-id="e43177e8-a8fc-4ecf-889c-c7e1a5d74b31"></div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box className="ml-home-action">
                <Container maxWidth="lg">
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                            <Box className="ml-hm">
                                <Typography variant="h2" className="heading-1 mb-5 text-capitalize">
                                    Sustain &amp; grow your small business with MarketLync
                                </Typography>
                                <Box className="d-flex flex-wrap justify-content-center">
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <RouterLink to="/contact/product-demo-request">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" /> Schedule a Free Demo{' '}
                                                </Button>
                                            </RouterLink>
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <Link href={`${BASE_URL}/auth/signup`} rel="noreferrer">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> Join MarketLync{' '}
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default YourSmallBussiness;
