import * as React from 'react';
import { Box, Button, Container, Grid, Typography, Link } from '@material-ui/core';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { post07Detail } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../../AppConfig';

// import your fontawesome library

interface IExpertLegalEntitiesProps {}

const LegalEntitiesToSetupYourBusiness: FC<IExpertLegalEntitiesProps> = (props) => {
    return (
        <>
            <Box className="top-inner-banner top-lg mb-0">
                <figure className="h-100">
                    <img src={post07Detail} alt="img" />
                </figure>
            </Box>
            <Box className="container">
                <Box className="row">
                    <Box className="col-12">
                        <Box className="insight-content-top d-flex justify-content-end">
                            <div className="insight-share">
                                {/* https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fglginsights.com%2Farticles%2Fis-inflation-coming-to-the-united-states%2F&amp;title=Is%20Inflation%20Coming%20to%20the%20United%20States%3F */}
                                <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fmarketlync.com/insights/4-Types-Of-Legal-Entities-To-Setup-Your-Business-Pros-&amp;-Cons-Of-Each%2F&amp;title=4%20Types%20Of%20Legal%20Entities%20To%20Setup%20Your%20Business:%20Pros%20&%20Cons%20Of%20Each" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-linkedin-in fa-w-14">
                                        <path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                                    </svg>
                                </a>
                                <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmarketlync.com/insights/4-Types-Of-Legal-Entities-To-Setup-Your-Business-Pros-&amp;-Cons-Of-Each" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-facebook-f fa-w-10">
                                        <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                    </svg>
                                </a>
                                <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fmarketlync.com/insights/4-Types-Of-Legal-Entities-To-Setup-Your-Business-Pros-&amp;-Cons-Of-Each%2F&amp;via=MarketLync&amp;text=4%20Types%20Of%20Legal%20Entities%20To%20Setup%20Your%20Business:%20Pros%20&%20Cons%20Of%20Each" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-twitter fa-w-16">
                                        <path
                                            fill="currentColor"
                                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                        ></path>
                                    </svg>
                                </a>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box component="section" className="bpost-wrapper">
                <Box className="container">
                    <Box className="row">
                        <Box className="col-12 col-md-5 col-lg-4">
                            <div className="insight-content-header">
                                <h1 className="insight-content-title">4 Types Of Legal Entities To Setup Your Business: Pros &amp; Cons Of Each</h1>
                                <p className="insight-content-author">Amit Sachdeva</p>
                                <div className="insights-list-item-tags">
                                    <span>
                                        <span className="insights-list-item-tag">Legal</span>
                                    </span>
                                </div>
                            </div>
                        </Box>
                        <Box className="col-12 col-md-7 col-lg-8">
                            <Box className="insight-content-body long-form-text">
                                <p>
                                    Once you’ve got your business idea, know what market you’re going to serve, and understand the finances you need to put into place to get started, your next step will be to register your business entity. This doesn’t often seem like a stumbling block until you’re at it and realize that you’re not entirely sure which is best. While you can always change{' '}
                                    <a href="https://www.irs.gov/businesses/small-businesses-self-employed/business-structures" target="_blank" rel="noreferrer" className="underline">
                                        your business structure
                                    </a>{' '}
                                    later down the line, it’s often costly and rarely easy, so it’s worth putting thought into the decision now.
                                </p>
                                <p>To help you make your decision, we’ve listed your options, the differences, the pros and cons, and who each is best for below.</p>
                                <p>Financial management might seem daunting if you don’t have a background in accounting or a head for numbers, but don’t let that hold you back. Here are three steps all small businesses can take to get started.</p>
                                <h2>
                                    <strong>Which business structure should I choose? </strong>
                                </h2>
                                <h3>
                                    <strong>Sole Proprietorship </strong>
                                </h3>
                                <p>This is where one person is self-employed, and there is no legal difference between the business and person. This makes operating your business simple, from admin to taxes, but it also means that you are fully liable for any legal trouble or debt the business gets into. For example, if you took out a loan for $10,000 to buy equipment and your business failed, you would be liable for that $10,000 debt.</p>
                                <h3>
                                    <strong>Pros: </strong>
                                </h3>

                                <ul>
                                    <li>Fast to set up </li>
                                    <li>Cheap to set up </li>
                                    <li>Taxes are very straightforward </li>
                                </ul>
                                <h3>
                                    <strong>Cons: </strong>
                                </h3>

                                <ul>
                                    <li> You take on the legal risk from operating your business </li>
                                    <li> Only suitable if you’re going into business alone </li>
                                    <li> Finding financing can be challenging </li>
                                </ul>

                                <p>
                                    <strong>Best for :</strong> Freelancers and self-employed who feel they don’t need liability protection and for side-hustles that are making just a little money at this time.
                                </p>
                                <p>
                                    <strong>Avoid if :</strong> You are worried about taking on the legal risks associated with running a business, or if there’s more than 1 person founding the business.
                                </p>
                                <p>
                                    <strong>What taxes are you liable for ?</strong>
                                    {' '}
                                    <a href="https://www.irs.gov/businesses/small-businesses-self-employed/sole-proprietorships" target="_blank" rel="noreferrer" className="underline">
                                        Find all taxation requirements for a sole proprietorship here.
                                    </a>{' '}
                                </p>

                                <hr />
                                <h2>
                                    <strong>LLC</strong>
                                </h2>
                                <p>LLC stands for limited liability company, which means you as the business owner (and anyone going into business with you) have legal distance from the business itself. If the business was to get sued, your personal assets could not be accessed in the legal proceedings. This also works to protect the business if a member has legal trouble in their personal life (such as debt or divorce).</p>
                                <h3>
                                    <strong>Pros: </strong>
                                </h3>

                                <ul>
                                    <li> Asset protection</li>
                                    <li> Pass-through taxation – you file taxes as though you are a sole proprietor, yet have limited liability </li>
                                    <li> Alternatively, you can choose to be taxed as a corporation </li>
                                    <li> You don’t have additional administrative tasks, as a corporation does </li>
                                    <li> Some customers view you as more trustworthy </li>
                                </ul>
                                <h3>
                                    <strong>Cons: </strong>
                                </h3>

                                <ul>
                                    <li> It’s more expensive than being a sole proprietor (annual fees vary from state to state) </li>
                                    <li> You’ll struggle to find angel investors and venture capital (if you want it) </li>
                                </ul>
                                <p>
                                    <strong>Best for :</strong> Full-time businesses or those in high-risk markets that want the benefits of legal protection. Anyone working with other partners.
                                </p>
                                <p>
                                    <strong>Avoid if :</strong> You need or want to seek investors, or if it’s a side-hustle and the costs involved will drastically eat into your profits.
                                </p>

                                <p>
                                    <strong>What taxes are you liable for ?</strong>
                                    {' '}
                                    <a href="https://www.irs.gov/businesses/small-businesses-self-employed/limited-liability-company-llc" target="_blank" rel="noreferrer" className="underline">
                                        Find all taxation requirements for an LLC here.
                                    </a>{' '}
                                </p>

                                <hr />
                                <h2>
                                    <strong>Partnership </strong>
                                </h2>
                                <p>Partnerships are businesses founded by two (or more) people. There are two types of partnership: </p>
                                <ul>
                                    <li>
                                        <em>Limited Partnership (LP)</em> – not often used, one main controller of the company has unlimited liability, while others have limited liability and usually far less control{' '}
                                    </li>
                                    <li>
                                        <em>Limited Liability Partnership (LLP)</em> – everyone involved has limited liability from one another and the business (and the business from them). It’s like an LLC but the business must be taxed as a partnership.{' '}
                                    </li>
                                </ul>

                                <h3>
                                    <strong>Pros: </strong>
                                </h3>

                                <ul>
                                    <li>Partnership agreement ensures the company is run a certain way </li>
                                    <li>Some states make an LLP more cost-efficient than an LLC </li>
                                    <li>A good choice for professionals who are not allowed an LLC </li>
                                </ul>
                                <h3>
                                    <strong>Cons: </strong>
                                </h3>

                                <ul>
                                    <li>Partnerships in business have a reputation of going sour (you need to be sure that you’re going to be good partners) </li>
                                    <li>Rarely any reason to choose an LP or LLP over an LLC </li>
                                    <li>LPs leave one member with full liability </li>
                                </ul>

                                <p>
                                    <strong>Best for :</strong> Professionals who need limited liability but cannot get an LLC, or are in a state where taxation for an LLP is more cost-effective.
                                </p>
                                <p>
                                    <strong>Avoid if :</strong> You’re one person or if an LLC would be just as suitable.
                                </p>
                                <p>
                                    <strong>What taxes are you liable for ?</strong>
                                    {' '}
                                    <a href="https://www.irs.gov/businesses/partnerships" target="_blank" rel="noreferrer" className="underline">
                                        Find all taxation requirements for a partnership here.
                                    </a>{' '}
                                </p>
                                <hr />
                                <h2>
                                    <strong> Corporation</strong>
                                </h2>
                                <p>This is the “top level” business structure, but that doesn’t mean it’s the best or right for everyone. Generally, incorporating is only worth doing once you’re handling substantial amounts of money.</p>

                                <p>There are 3 types of corporation:</p>
                                <ul>
                                    <li>
                                        <em>C-corp:</em> a separate legal entity from the owners with more protection than an LLC, but more admin is needed and additional tax must be paid on profits. Often taxed a second time when dividends are paid to shareholders. Can go public to raise funds.{' '}
                                    </li>
                                    <li>
                                        <em>S-corp:</em> designed to avoid the double taxation of a c-corp, but allows some profits and losses to pass on to the owners. Some states don’t properly recognize s-corps, and you must register separately with the IRS.{' '}
                                    </li>
                                    <li>
                                        <em>B-corp:</em> recognized in most states, taxed the same way as a c-corp but are built to benefit society and so are more transparent.{' '}
                                    </li>
                                </ul>
                                <h3>
                                    <strong>Pros: </strong>
                                </h3>

                                <ul>
                                    <li> S-corp allows you to avoid double taxation </li>
                                    <li> You can go public </li>
                                    <li> Much easier to get funding </li>
                                </ul>
                                <h3>
                                    <strong>Cons: </strong>
                                </h3>

                                <ul>
                                    <li> Complicated for new business owners </li>
                                    <li> Not all states recognize s- and b-corps </li>
                                    <li> S-corps require all shareholders to be US citizens and cannot have more than 100 shareholders </li>
                                </ul>

                                <p>
                                    <strong>Best for :</strong> Big startups and companies that need to raise funds early in the business’s life.
                                </p>
                                <p>
                                    <strong>Avoid if :</strong> You don’t meet the above criteria.
                                </p>
                                <p>
                                    <strong>What taxes are you liable for ?</strong>
                                    {' '}
                                    <a href="https://www.irs.gov/businesses/small-businesses-self-employed/forming-a-corporation" target="_blank" rel="noreferrer" className="underline">
                                        Find all taxation requirements for a corporation here
                                    </a>{' '}
                                    and
                                    <a href="https://www.irs.gov/businesses/small-businesses-self-employed/s-corporations" target="_blank" rel="noreferrer" className="underline">
                                        requirements for an s-corp here
                                    </a>{' '}
                                    .
                                </p>

                                <p>
                                    If you’re ever in any doubt about which business structure to choose, the best thing to do is to talk to an accountant or taxation expert in your state that has a good understanding of your profession or industry. The information provided on this website does not, and is not intended to, constitute legal advice; instead, all information, content, and materials available on this site are for general informational purposes only. If you want to consult a business expert before moving forward, or to help you start your business on the right foot,{' '}
                                    <a href="http://marketlync.com/" className="underline">
                                        connect with business experts on MarketLync here
                                    </a>{' '}
                                    .
                                </p>

                                <hr />
                            </Box>
                            <div className="ctct-inline-form" data-form-id="e43177e8-a8fc-4ecf-889c-c7e1a5d74b31"></div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box className="ml-home-action">
                <Container maxWidth="lg">
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                            <Box className="ml-hm">
                                <Typography variant="h2" className="heading-1 mb-5 text-capitalize">
                                    Sustain &amp; grow your small business with MarketLync
                                </Typography>
                                <Box className="d-flex flex-wrap justify-content-center">
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <RouterLink to="/contact/product-demo-request">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" /> Schedule a Free Demo{' '}
                                                </Button>
                                            </RouterLink>
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <Link href={`${BASE_URL}/auth/signup`} rel="noreferrer">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> Join MarketLync{' '}
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default LegalEntitiesToSetupYourBusiness;
