import * as React from 'react';
import { Box, Button, Container, Grid, Typography, Link } from '@material-ui/core';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { post10Detail } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../../AppConfig';

// import your fontawesome library

interface IBusinessHiringProps {}

const SmallBusinessHiring: FC<IBusinessHiringProps> = (props) => {
    return (
        <>
            <Box className="top-inner-banner top-lg mb-0">
                <figure className="h-100">
                    <img src={post10Detail} alt="img" />
                </figure>
            </Box>
            <Box className="container">
                <Box className="row">
                    <Box className="col-12">
                        <Box className="insight-content-top d-flex justify-content-end">
                            <div className="insight-share">
                                <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fmarketlync.com/insights/Small-Business-Hiring-4-Priorities-When-Investing-In-Human-Resources%2F&amp;title=Small%20Business%20Hiring%204%20Priorities%20When%20Investing%20In%20Human%20Resources" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-linkedin-in fa-w-14">
                                        <path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                                    </svg>
                                </a>
                                <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmarketlync.com/insights/Small-Business-Hiring-4-Priorities-When-Investing-In-Human-Resources" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-facebook-f fa-w-10">
                                        <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                    </svg>
                                </a>
                                <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fmarketlync.com/insights/Small-Business-Hiring-4-Priorities-When-Investing-In-Human-Resources%2F&amp;via=MarketLync&amp;text=Small%20Business%20Hiring%204%20Priorities%20When%20Investing%20In%20Human%20Resources" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-twitter fa-w-16">
                                        <path
                                            fill="currentColor"
                                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                        ></path>
                                    </svg>
                                </a>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box component="section" className="bpost-wrapper">
                <Box className="container">
                    <Box className="row">
                        <Box className="col-12 col-md-5 col-lg-4">
                            <div className="insight-content-header">
                                <h1 className="insight-content-title">Small Business Hiring: 4 Priorities When Investing In Human Resources</h1>
                                <p className="insight-content-author">Amit Sachdeva</p>
                                <div className="insights-list-item-tags">
                                    <span>
                                        <span className="insights-list-item-tag">HR &amp; Staffing</span>
                                    </span>
                                </div>
                            </div>
                        </Box>
                        <Box className="col-12 col-md-7 col-lg-8">
                            <Box className="insight-content-body long-form-text">
                                <p>You’re finally ready to start hiring. This is a major milestone for any small business owner, especially since it means you can stop overstretching yourself and focus on growing the business. While there are benefits to hiring employees, there are also many things you should consider before you bring someone on board. The wrong decision can hurt your reputation, your customer base, and ultimately your profits. </p>
                                <p>How should you approach small business hiring? Here are our best tips straight from our MarketLync experts.</p>
                                <h2>
                                    <strong>1. Decide Whether to Hire Contractors or Employees</strong>
                                </h2>

                                <p>Before you hire, consider whether a permanent employee or contractor makes sense for the role. </p>
                                <p>Contractors are self-employed and typically specialize in specific skills, such as consulting, web design, writing, legal, and coding, for example. Contractors set their own hours, provide their own equipment, and have full control over how, when, and where their work is performed. They are also responsible for their own taxes, including the portion usually paid by the employer. If you only need this position for a short period of time and can’t provide ongoing work, a contractor might be a better option. </p>

                                <p>Employees are permanent and become part of your company ecosystem. They are required to work according to your terms, but you are responsible for providing them with equipment, technology, and other things they need for work. This is a better option if you need ongoing help and want more control over how work is performed.</p>
                                <p>
                                    However, there are {' '}
                                    <a href="https://www.irs.gov/businesses/small-businesses-self-employed/independent-contractor-self-employed-or-employee" target="_blank" rel="noreferrer" className="underline">
                                        legal rules to follow
                                    </a> {' '}
                                    when classifying individuals as contractors or employees. Check with your state’s requirements to ensure you don’t misclassify an employee.
                                </p>
                                <hr />
                                <h2>
                                    <strong>2. Find the Balance of Personality and Skill</strong>
                                </h2>
                                <p>
                                    Knowing how to do a job well is important, but so is an employee’s ability to fit in with your company. Every employee represents your company’s image. If you have multiple employees, you want to ensure they “mesh” so that collaboration is easier and more productive. Don’t let skill overshadow
                                    {' '}    <a href="https://gethppy.com/company-culture/infographic-why-company-culture-matters" target="_blank" rel="noreferrer" className="underline">
                                        culture fit
                                    </a> {' '}
                                    .
                                </p>

                                <hr />
                                <h2>
                                    <strong>3. Use Employment Contracts</strong>
                                </h2>
                                <p>Contracts are useful for employees and small business owners alike. They define the terms of the job, including things like pay, benefits, work hours, and holidays, so there are no misunderstandings later. </p>

                                <hr />

                                <h2>
                                    <strong>4. Work With a Recruitment Agency</strong>
                                </h2>
                                <p>For small business owners wearing many hats, finding time to properly recruit an employee can be next to impossible. Using a recruitment agency can help you get closer to finding that perfect candidate without taking much time away from your business.</p>
                                <p>Recruitment agencies do most of the heavy lifting for you, including vetting candidates, conducting background checks, and even doing pre-employment drug testing, if that’s important to you. They can also handle all the legal paperwork on your behalf so you can focus on doing a good interview and finding the right candidate for your business.</p>
                                <p>
                                    If you’re ever in any doubt about how to hire people for your business, the best thing to do is to talk to a certified expert in your state who has a good understanding of your profession or industry. The information provided on this website does not, and is not intended to, constitute legal advice; instead, all information, content, and materials available on this site are for general informational purposes only. Bringing on the right candidate can do wonders for your small business growth. For advice on making a successful hire,
                                    {' '}   <a href="http://marketlync.com/" className="underline">
                                        consult with a MarketLync expert now
                                    </a>
                                    .
                                </p>

                              
                               <hr />

                               
                            </Box>
                            <div className="ctct-inline-form" data-form-id="e43177e8-a8fc-4ecf-889c-c7e1a5d74b31"></div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box className="ml-home-action">
                <Container maxWidth="lg">
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                            <Box className="ml-hm">
                                <Typography variant="h2" className="heading-1 mb-5 text-capitalize">
                                    Sustain &amp; grow your small business with MarketLync
                                </Typography>
                                <Box className="d-flex flex-wrap justify-content-center">
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <RouterLink to="/contact/product-demo-request">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" /> Schedule a Free Demo{' '}
                                                </Button>
                                            </RouterLink>
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <Link href={`${BASE_URL}/auth/signup`} rel="noreferrer">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> Join MarketLync{' '}
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default SmallBusinessHiring;
