import * as React from 'react';
import { Box, Button, Container, Grid, Typography, Link } from '@material-ui/core';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { post09Detail } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../../AppConfig';

// import your fontawesome library

interface IAutomationsBusinessProps {}

const AutomationsBusinessWeek: FC<IAutomationsBusinessProps> = (props) => {
    return (
        <>
            <Box className="top-inner-banner top-lg mb-0">
                <figure className="h-100">
                    <img src={post09Detail} alt="img" />
                </figure>
            </Box>
            <Box className="container">
                <Box className="row">
                    <Box className="col-12">
                        <Box className="insight-content-top d-flex justify-content-end">
                            <div className="insight-share">
                               
                                <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fmarketlync.com/insights/6-Automations-You-Can-Implement-In-Your-Small-Business-This-Week%2F&amp;title=6%20Automations%20You%20Can%20Implement%20In%20Your%20Small%20Business%20This%20Week" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-linkedin-in fa-w-14">
                                        <path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                                    </svg>
                                </a>
                                <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmarketlync.com/insights/6-Automations-You-Can-Implement-In-Your-Small-Business-This-Week" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-facebook-f fa-w-10">
                                        <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                    </svg>
                                </a>
                                <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fmarketlync.com/insights/6-Automations-You-Can-Implement-In-Your-Small-Business-This-Week%2F&amp;via=MarketLync&amp;text=6%20Automations%20You%20Can%20Implement%20In%20Your%20Small%20Business%20This%20Week" target="_blank" rel="noreferrer" className="insight-share-link">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-twitter fa-w-16">
                                        <path
                                            fill="currentColor"
                                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                        ></path>
                                    </svg>
                                </a>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box component="section" className="bpost-wrapper">
                <Box className="container">
                    <Box className="row">
                        <Box className="col-12 col-md-5 col-lg-4">
                            <div className="insight-content-header">
                                <h1 className="insight-content-title">6 Automations You Can Implement In Your Small Business This Week</h1>
                                <p className="insight-content-author">Amit Sachdeva</p>
                                <div className="insights-list-item-tags">
                                    <span>
                                        <span className="insights-list-item-tag">Technology &amp; Automation</span>
                                    </span>
                                </div>
                            </div>
                        </Box>
                        <Box className="col-12 col-md-7 col-lg-8">
                            <Box className="insight-content-body long-form-text">
                                <p>No one owning a small business would ever say that building and running it is easy – whether you’re a team of 1 or 30, it often feels like the cycle of market-sell-do never ends. While there are certainly no shortcuts to getting your business to work for you more than you work for it, one thing every small business owner can start thinking about is implementing automation technologies. The fast advancement of technology means that smart systems are no longer reserved for big businesses – every small business can start automating processes to free up more time, create more ease, and reduce occurrences of human error.</p>
                                <p>Not sure how automation can apply to your business? Here are some of the key areas you could have automated as soon as this week. </p>
                                <h2>
                                    <strong>1) Email Marketing </strong>
                                </h2>

                                <p>Email marketing is one of the easiest areas to automate but also one that can be easily overlooked. Many small businesses with brick-and-mortar stores or who offer services often fail to set up email marketing automation. They either avoid collecting email addresses entirely or do so and then manually send out a newsletter when they remember or when they have a special offer. When you set up email automation, you can set up email sequences to market and build a relationship with prospects and new customers whenever they come into contact with your business.</p>
                                <p>Here are some of the best and easiest email marketing tools to use: </p>

                                <ul>
                                    <li>
                                        <a href="https://www.mailerlite.com/" target="_blank" rel="noreferrer" className="underline">
                                            {' '}
                                            Mailerlite
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://mailchimp.com/" target="_blank" rel="noreferrer" className="underline">
                                            {' '}
                                            MailChimp
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://convertkit.com/" target="_blank" rel="noreferrer" className="underline">
                                            {' '}
                                            ConvertKit
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://keap.com/" target="_blank" rel="noreferrer" className="underline">
                                            {' '}
                                            Keap
                                        </a>{' '}
                                        (this has advanced If This Then That—IFTTT—automation that goes well beyond email marketing)
                                    </li>
                                </ul>

                                <hr />
                                <h2>
                                    <strong>2) Social Media Marketing </strong>
                                </h2>
                                <p>Nowadays, small businesses can’t afford not to be on social media. It’s one of the key places customers will look for you, especially if you cater to millennials and younger, and without it, your customers may even doubt whether you’re open for business or trustworthy. Fortunately, it’s easy to stay up-to-date on social media if you schedule posts ahead of time. Try to pick one or two social media platforms your customers use and focus on those. Here are a few of the easiest social media scheduling tools: </p>

                                <ul>
                                    <li>
                                        <a href="https://www.planoly.com/" target="_blank" rel="noreferrer" className="underline">
                                            Planoly
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://buffer.com/" target="_blank" rel="noreferrer" className="underline">
                                            Buffer
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.hootsuite.com/" target="_blank" rel="noreferrer" className="underline">
                                            Hootsuite
                                        </a>
                                    </li>
                                    <li>
                                        If you pay for the graphics tool {' '} <a href="https://www.canva.com/en_gb/" target="_blank" rel="noreferrer" className="underline">
                                   
                                            Canva{' '}
                                        </a>
                                        , they now also have a scheduler built-in to their premium subscription
                                    </li>
                                </ul>

                                <hr />
                                <h2>
                                    <strong>3) Booking &amp; Scheduling </strong>
                                </h2>
                                <p>Forget your handwritten diary, technology has a much better solution. There are numerous affordable booking and scheduling tools that integrate with your website or social media profiles that allow your customers or clients to book with you according to your availability. This is a great tool for restaurants, salons, and other service businesses to sell appointments whenever your customer is ready to book. </p>

                                <p>However, this can also be used for lead generation to allow a potential customer to book a call or meeting with you for a consultation or demonstration so you don’t have to waste time getting potential customers on sales calls. This minimizes the likelihood that a customer will change their mind before reaching you and makes you look much more professional. Here are some of the best options:</p>
                                <ul>
                                    <li>
                                        {' '}
                                        <a href="https://calendly.com/" target="_blank" rel="noreferrer" className="underline">
                                            {' '}
                                            Calendly
                                        </a>
                                    </li>
                                    <li>
                                        {' '}
                                        <a href="https://fynder.io/" target="_blank" rel="noreferrer" className="underline">
                                            {' '}
                                            Fynder
                                        </a>
                                    </li>
                                    <li>
                                        {' '}
                                        <a href="https://www.hubspot.com/pricing/sales" target="_blank" rel="noreferrer" className="underline">
                                            {' '}
                                            HubSpot Sales
                                        </a>
                                    </li>
                                    <li>
                                        {' '}
                                        Many website platforms (such as{' '}
                                        <a href="http://www.wix.com/" target="_blank" rel="noreferrer" className="underline">
                                            Wix
                                        </a>
                                        ) also have integrated booking systems
                                    </li>
                                </ul>
                                <hr />

                                <h2>
                                    <strong>4) Finance </strong>
                                </h2>
                                <p>Automating invoicing, payments, bookkeeping, and/or payroll will give you hours of your time back, reduce your stress, and likely put more money in your pocket. Automated finance systems can help you track expenses, automatically send out invoices and invoice reminders, and help you get paid sooner. If you’re still waiting until the end of the day (or week) to send out your invoices, you're stemming the flow of your cashflow, and that doesn’t make for a healthy business. Here are some of the best tools to automate your business finances: </p>

                                <ul>
                                    <li>
                                        <a href="https://www.xero.com/uk/" target="_blank" rel="noreferrer" className="underline">
                                            Xero
                                        </a>
                                        – easy to use, automates invoices, schedules payments, and offers cashflow management{' '}
                                    </li>
                                    <li>
                                        <a href="https://www.expensify.com/?" target="_blank" rel="noreferrer" className="underline">
                                            Expensify
                                        </a>
                                        – tracks expenses{' '}
                                    </li>
                                    <li>
                                        <a href="https://quickbooks.intuit.com/" target="_blank" rel="noreferrer" className="underline">
                                            QuickBooks
                                        </a>
                                        – for bookkeeping{' '}
                                    </li>
                                    <li>
                                        <a href="http://taxify.co/features/" target="_blank" rel="noreferrer" className="underline">
                                            Taxify
                                        </a>
                                        – understand your tax obligations nationally and internationally{' '}
                                    </li>
                                </ul>
                                <hr />

                                <h2>
                                    <strong>5) Order Management </strong>
                                </h2>
                                <p>Your order management dictates how quickly your team can get orders to customers and impacts how likely those orders are to be incorrect. When you automate your order management, you can cut human error and speed up order-to-delivery times. Here are some of the best tools: </p>

                                <ul>
                                    <li>
                                        <a href="https://www.shipbob.com/" target="_blank" rel="noreferrer" className="underline">
                                            ShipBob
                                        </a>
                                        (full order to fulfillment service)
                                    </li>
                                    <li>
                                        <a href="https://www.esker.com/" target="_blank" rel="noreferrer" className="underline">
                                            Esker
                                        </a>
                                    </li>
                                    <li>
                                        <a href="http://www.netsuite.com/" target="_blank" rel="noreferrer" className="underline">
                                            Netsuite
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.orderhive.com/" target="_blank" rel="noreferrer" className="underline">
                                            OrderHive
                                        </a>
                                    </li>
                                </ul>
                                <hr />

                                <h2>
                                    <strong>6) Customer Service </strong>
                                </h2>
                                <p>Your customer service is essential to ensuring your happy customers stay that way, and return to you again and again. When you automate as much as possible about your customer service, you can help your customers faster and provide them with better solutions. Here are some of the best tools to help you automate your customer service: </p>

                                <ul>
                                    <li>
                                        <a href="https://freshdesk.com/eu/" target="_blank" rel="noreferrer" className="underline">
                                            FreshDesk
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.zendesk.co.uk/" target="_blank" rel="noreferrer" className="underline">
                                            ZenDesk
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.teamsupport.com/" target="_blank" rel="noreferrer" className="underline">
                                            Team Support
                                        </a>
                                    </li>
                                </ul>

                                <p>
                                    Finding the right support to help your small business grow isn’t always easy, but the right technology and people will help you make better decisions and experience more consistent growth. If you’re looking for the right experts to help you streamline your processes and make sure you’re pointing in the right direction, you can find them on{' '}
                                    <a href="https://www.marketlync.com/" className="underline">
                                        MarketLync
                                    </a>
                                    .
                                </p>

                                <hr />
                            </Box>
                            <div className="ctct-inline-form" data-form-id="e43177e8-a8fc-4ecf-889c-c7e1a5d74b31"></div>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box className="ml-home-action">
                <Container maxWidth="lg">
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                            <Box className="ml-hm">
                                <Typography variant="h2" className="heading-1 mb-5 text-capitalize">
                                    Sustain &amp; grow your small business with MarketLync
                                </Typography>
                                <Box className="d-flex flex-wrap justify-content-center">
                                    <Grid container alignItems="center" justify="center">
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <RouterLink to="/contact/product-demo-request">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" /> Schedule a Free Demo{' '}
                                                </Button>
                                            </RouterLink>
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={4} className="px-5">
                                            <Link href={`${BASE_URL}/auth/signup`} rel="noreferrer">
                                                <Button variant="contained" color="primary" size="large" fullWidth={true} className="my-2">
                                                    {' '}
                                                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2" /> Join MarketLync{' '}
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default AutomationsBusinessWeek;
