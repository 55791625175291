import * as React from 'react';
import { Button } from '@material-ui/core';
import { Jumbotron } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface INotFoundProps {
}

const NotFound: React.FunctionComponent<INotFoundProps> = (props) => {
    return (
        <Jumbotron>
            <h1>404 - Page Not Found</h1>
            <p>
            The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage.
            </p>
            <p>
                <Button variant="outlined" color="primary">
                    <Link to="/">Home</Link>
                </Button>
            </p>
        </Jumbotron>
    );
};

export default NotFound;
