import { Box } from '@material-ui/core';
import { FC } from 'react';
import MainAppBar from './MainAppBar';
import MainFooter from './MainFooter';

interface IMainContainerProps {}

const MainContainer: FC<IMainContainerProps> = (props) => {
    return (
        <>
            <MainAppBar />
            <Box component="main" height="100%">
                {props.children}
            </Box>
            <MainFooter />
        </>
    );
};

export default MainContainer;
