import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { FC } from 'react';

import { Box, Container, Menu, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { logoMarketlync, mlogo } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Divider from '@material-ui/core/Divider';
import { BASE_URL } from '../../AppConfig';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
    })
);

interface IMainAppBarProps {}

const MainAppBar: FC<IMainAppBarProps> = () => {
    const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
    const [anchorElement1, setAnchorElement1] = React.useState<null | HTMLElement>(null);

    const menuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElement(event.currentTarget);
    };
    const menuClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElement1(event.currentTarget);
    };
    const menuClose = () => {
        setAnchorElement(null);
    };
    const menuClose1 = () => {
        setAnchorElement1(null);
    };
    const classes = useStyles();
    const [scrolled, setScrolled] = React.useState(false);
    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 20) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    });

    let navbarClasses = ['top-header prelogin-header'];
    if (scrolled) {
        navbarClasses.push('sticky');
    }
    return (
        <>
            <div className={classes.root}>
                <Box className={navbarClasses.join(' ')}>
                    <Container maxWidth="lg">
                        <AppBar position="static" color="transparent" elevation={0}>
                            <Toolbar className="flex justify-content-between " disableGutters>
                                <Box className="mr-1 mr-lg-4">
                                    <a className="prelog-logo-box d-none d-md-block" href="/">
                                        <img src={logoMarketlync} alt="MarketLync" />
                                    </a>
                                    <a className="prelog-logo-box d-md-none" href="/">
                                        <img src={mlogo} alt="MarketLync" />
                                    </a>
                                </Box>

                                <Box className="prelogin-menu">
                                    <Button aria-controls="ourPlatform" color="inherit" aria-haspopup="true" onClick={menuClick}>
                                        Our Platform
                                        <FontAwesomeIcon icon={faCaretDown} className="ml-1" />
                                    </Button>
                                    <Menu id="ourPlatform" anchorEl={anchorElement} keepMounted open={Boolean(anchorElement)} onClose={menuClose} getContentAnchorEl={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} className="transparent-menu">
                                        <MenuItem onClick={menuClose}>
                                            <Link to="/community-interaction">Community Interaction</Link>
                                        </MenuItem>
                                        <MenuItem onClick={menuClose}>
                                            <Link to="/one-on-one-consultations">1-on-1 Consultations</Link>
                                        </MenuItem>
                                        <MenuItem onClick={menuClose}>
                                            <Link to="/multi-expert-insights">Multi-Expert Insights</Link>
                                        </MenuItem>
                                        <Divider className="mx-3" />
                                        <MenuItem onClick={menuClose}>
                                            <Link to="/become-an-expert">Become an Expert</Link>
                                        </MenuItem>
                                    </Menu>
                                    <Button color="inherit">
                                        <Link to="/insights">Insights</Link>
                                    </Button>

                                    <Button color="inherit" className="d-none d-md-inline-flex">
                                        <a href={`${BASE_URL}/auth/signin`} rel="noreferrer">
                                            Sign In
                                        </a>
                                    </Button>
                                    <Button color="inherit">
                                        <a href={`${BASE_URL}/auth/signup`} rel="noreferrer">
                                            {' '}
                                            Sign Up{' '}
                                        </a>
                                    </Button>

                                    <Button aria-controls="more" color="inherit" aria-haspopup="true" onClick={menuClick1} className="d-inline-flex d-md-none">
                                        More
                                        <FontAwesomeIcon icon={faCaretDown} className="ml-1" />
                                    </Button>

                                    <Menu id="more" anchorEl={anchorElement1} keepMounted open={Boolean(anchorElement1)} onClose={menuClose1} getContentAnchorEl={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} className="transparent-menu">
                                        <MenuItem onClick={menuClose1}>
                                            <a href="https://knowledge-qa.marketlync.com/auth/signin" rel="noreferrer">
                                            <FontAwesomeIcon icon={faSignInAlt} className="mr-1" />    Sign In
                                            </a>
                                        </MenuItem>

                                        <Divider className="mx-3" />
                                        <MenuItem onClick={menuClose1}>
                                            <a href="https://knowledge-qa.marketlync.com/auth/signup" rel="noreferrer">
                                                {' '}
                                                <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" />    Sign Up{' '}
                                            </a>
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            </Toolbar>
                        </AppBar>
                    </Container>{' '}
                </Box>
            </div>
        </>
    );
};

export default MainAppBar;
