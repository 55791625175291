const baseURLMap = {
    QAS: {
        QA: "https://knowledge-qa.marketlync.com",
        PROD: "https://knowledge.marketlync.com"
    }
}

export const BASE_URL =  (() => {
    const pathname  = window.location.hostname
    const baseURL = (pathname.indexOf("qa") > 0 ) ? baseURLMap.QAS.QA : baseURLMap.QAS.PROD
     console.log(baseURL)
     return baseURL
})()
