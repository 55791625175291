import { Box, Container, Grid, Typography } from '@material-ui/core';
import { FC } from 'react';

import { imgBanner3 } from '../../assets/images';

// import your fontawesome library

interface ITermsProps {}

const Terms: FC<ITermsProps> = () => {
    return (
        <>
            <Box className="top-inner-banner">
                <figure className="h-100">
                    <img src={imgBanner3} alt="img" />
                </figure>
                <Box className="inner-banner-content">
                    <Typography variant="h1" className="text-display">
                        Terms of Service
                    </Typography>
                </Box>
            </Box>

            <Container maxWidth="lg" className="fs-16">
                <Grid item xs={12}>
                    <Typography component="h2" className="hdd3 line">
                        An Introduction to MarketLync’s Terms of Service
                    </Typography>
                    <ul className="bullet-list">
                        <li>
                            <strong> Our mission is to share and grow the world’s knowledge : </strong> The MarketLync<sup>&reg;</sup> platform offers a place to ask questions and connect with people who contribute unique insights and quality answers. This empowers people to learn from each other and to better understand the world.
                        </li>
                        <li>
                            <strong> You own the content that you post;</strong>
                            you also grant us and other users of the MarketLync<sup>&reg;</sup> platform certain rights and license to use it. The details of these licenses are described in section 3(c) below.
                        </li>
                        <li>
                            <strong> You are responsible for the content that you post : </strong>
                            This includes ensuring that you have the rights needed for you to post that content and that your content does not violate the legal rights of another party or any applicable laws.
                        </li>
                        <li>
                            <strong>
                                {' '}
                                You can repost content from MarketLync<sup>&reg;</sup> elsewhere,{' '}
                            </strong>
                            provided that you attribute the content back to the MarketLync<sup>&reg;</sup> platform and respect the rights of the original poster, including any “not for reproduction” designation, and do not use automated tools.
                        </li>
                        <li>
                            <strong> We do not endorse or verify content posted by users : </strong>
                            Our content and materials are provided to you “as is,” without any guarantees. You are solely responsible for your own use of the MarketLync<sup>&reg;</sup> platform. Posts from lawyers, doctors, and other professionals should not be treated as a substitute for professional advice for your specific situation.
                        </li>
                        <li>
                            <strong> You agree to follow the rules of our platform : </strong>
                            When you use the MarketLync<sup>&reg;</sup> platform, you also agree to our Terms of Service, accept our Privacy Policy.
                        </li>
                        <li>
                            <strong> You agree to alternative dispute resolution : </strong>
                            You agree to attempt initially to resolve matters cooperatively with us and, if that fails, to use individual arbitration.
                        </li>
                        <li>
                            <strong> We offer tools for you to give feedback and report complaints : </strong> If you think someone has violated your intellectual property rights, other laws, or MarketLync<sup>&reg;</sup>'s policies, you can initiate a report at <a href="mailto:contact@MarketLync.com">contact@MarketLync.com </a>
                        </li>
                    </ul>
                    <Typography component="h2" className="hdd3 line">
                        MarketLync<sup>&reg;</sup> Terms of Service
                    </Typography>
                    <p>
                        Welcome to MarketLync<sup>&reg;</sup>! MarketLync<sup>&reg;</sup> is a platform to gain and share knowledge, empowering people to learn from others and better understand the world.
                    </p>
                    <p>
                        These terms of service (“Terms of Service”) set forth the agreement (“Agreement”) between you and MarketLync, Inc. (“MarketLync” “we” or “us”). It governs your use of the products and services we offer through our websites and applications (collectively the “MarketLync<sup>&reg;</sup> Platform”).
                    </p>

                    <p>
                        Please make sure to read it, because, by using the MarketLync<sup>&reg;</sup> Platform, you consent to these Terms of Service.
                    </p>

                    <p>
                        IMPORTANT ARBITRATION NOTICE: IF YOU ARE IN THE UNITED STATES, MEXICO, OR CANADA, YOU AGREE THAT DISPUTES BETWEEN YOU AND MarketLync<sup>&reg;</sup> WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. WE EXPLAIN SOME EXCEPTIONS AND HOW YOU CAN OPT OUT OF ARBITRATION BELOW IN SECTION 10.
                    </p>
                    <ol className="number">
                        <li>
                            <p>
                                <strong>
                                    The Mission of the MarketLync<sup>&reg;</sup> Platform
                                </strong>
                            </p>
                            <p>
                                <strong>
                                    MarketLync<sup>&reg;</sup>'s mission is to share and grow the world’s knowledge :{' '}
                                </strong>
                                The MarketLync<sup>&reg;</sup> Platform is a place to ask questions and connect with people who contribute unique insights and quality answers. This empowers people to learn from each other and to better understand the world.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>
                                    {' '}
                                    Using the MarketLync<sup>&reg;</sup> Platform
                                </strong>
                            </p>
                            <ol className="alpha">
                                <li>
                                    <strong>Who Can Use It : </strong> Use of the MarketLync<sup>&reg;</sup> Platform by anyone under 13 years of age is prohibited. You represent that you are at least the age of majority in the jurisdiction where you live or, if you are not, your parent or legal guardian must consent to these Terms of Service and affirm that they accept this Agreement on your behalf and bear responsibility for your use. If you are accepting these Terms of Service on behalf of someone else or an entity, you confirm that you have the legal authority to bind that person or entity to this Agreement.
                                </li>
                                <li>
                                    <strong>Registration : </strong> To Use certain aspects of our Services, you must create an Account. If you choose to create an Account with us, then you agree to provide true, accurate, current and complete information as prompted by our registration form, and to maintain and promptly update the information you provide to us in order to keep such information true, accurate, current and complete. It is your obligation to maintain and control passwords to your Account. YOU AGREE THAT YOU ARE SOLELY AND FULLY RESPONSIBLE FOR ANY ACTIVITIES OR ACTIONS TAKEN UNDER YOUR ACCOUNT, WHETHER OR NOT YOU HAVE AUTHORIZED SUCH ACTIVITIES OR ACTIONS. You agree to immediately notify us of any unauthorized uses of your username and password and/or any other breaches of
                                    security. There is no assumption by us of your responsibility to notify your local law enforcement agency of any identity theft. You agree we will not be liable for any loss or damages caused by your failure to comply with your security obligations.
                                </li>
                                <li>
                                    <strong> Privacy Policy : </strong> By use of the MarketLync<sup>&reg;</sup> Platform, you agree to accept our Privacy Policy regardless of whether you are a registered user.
                                </li>
                                <li>
                                    <strong> Termination : </strong> You may close your account at any time by going to account settings and disabling your account. We may terminate or suspend your MarketLync<sup>&reg;</sup> account if you violate any MarketLync<sup>&reg;</sup> policy or for any other reason.
                                </li>
                                <li>
                                    <strong>
                                        {' '}
                                        Changes to the MarketLync<sup>&reg;</sup> Platform :{' '}
                                    </strong>{' '}
                                    We are always trying to improve your experience on the MarketLync<sup>&reg;</sup> Platform. We may need to add or change features and may do so without notice to you.
                                </li>
                                <li>
                                    <strong> Feedback : </strong> We welcome your feedback and suggestions about how to improve the MarketLync<sup>&reg;</sup> Platform. Feel free to submit feedback at contact@MarketLync.com. By submitting feedback, you agree to grant us the right, at our discretion, to use, disclose and otherwise exploit the feedback, in whole or part, freely and without compensation to you.
                                </li>
                                <li>
                                    <strong> User Categories : </strong> There are 2 types of users on the MarketLync<sup>&reg;</sup> platform, “Members” and “Experts”. Members, are those who sign up to use the platform. Experts are Members that sign up to use the platform and can also provide 1;1 consultations and answer questions asked privately by other Members. All Members can ask and answer questions publicly.
                                </li>
                                <li>
                                    <strong> Ratings : </strong> Any rating displayed on the Services of a Member reflects the assessment of a given Member by other Members, based upon the Content obtained by or submitted to the Services. Any such ratings do not reflect the views of MarketLync. Further, someone else's assessment of the same Member may be very different, or be based upon different information. A rating displayed on our Services is not an endorsement of any particular Member, and is not a guarantee of a Member’s quality, competency, or character. Nor is any rating a predictor of the outcome of any matter in which such Member is providing information. Rather, ratings are intended to be a starting point to gather information about persons who offer services which may be
                                    suitable for your needs - but you should not rely solely on a rating displayed on our Services when deciding whether to contact or hire any given Member.
                                </li>
                                <li>
                                    <strong> Payment, Refunds, and Taxes : </strong> In connection with any Services you purchase using the Services, you agree to pay us the amounts displayed on the Website. The policies and prices that are disclosed to you when you purchase Services are a part of these Terms. Further, you authorize us to charge your chosen payment method (credit card, debit card, et cetera) in connection with all fees, costs, and other amounts incurred by you in the Services. In connection with any fees, costs, and other amounts paid by you, you agree: (i) to only provide valid and current payment information; (ii) that we may use the tools, software or services of our payment processor to process fees, costs, and other amounts as well as transactions on our behalf; (iii)
                                    to promptly pay all amounts which are due and payable to us upon demand; and (iv) to abide by the terms and policies of our payment processor. We are not responsible or liable for any activities or conduct of our payment processor, and you agree to hold us harmless, indemnify, defend, and expressly release us, from any and all liability relating to the conduct of our payment processor. All amounts shall be paid in US Dollars. We do not offer refunds of any type in connection with amounts paid to us in connection with the Services. You understand and agree that you are solely responsible for determining your applicable Tax reporting requirements in consultation with your tax advisors. MarketLync<sup>&reg;</sup> cannot and does not offer Tax-related advice
                                    to any Members of the Site, Application and Services.
                                </li>
                                <li>
                                    <strong> Terms of Use : </strong> You may not use or plan, encourage or help others to use our Services for any purpose or in any manner that is prohibited by these Terms or by applicable law. In using our Services, you agree at all times that you shall not: (a) infringe on the copyrights or other intellectual property rights of MarketLync, a Member, or a third party (b) copy, distribute, or modify any part of our Services without our prior written authorization; (c) transmit inappropriate, inaccurate, false, or misleading Content to our Services; (d) transmit any Content which contains software viruses, or other harmful computer code, files or programs; (e) Transmit Content that falsely states, impersonates or otherwise misrepresents your identity,
                                    including but not limited to the use of a pseudonym, or misrepresenting your current or previous positions and qualifications, or your affiliations with a person or entity, past or present; (f) make threats or use profanity; (g) harass, stalk or intimidate other Members; (h) manipulate or exclude identifiers in order to disguise the origin of any Content; (i) disrupt the networks connected to our Services, including but not limited to by: attempting to probe, scan or test the vulnerability of our Services, attempting to breach security or authentication measures without proper authorization, or attempting to interfere with our Services or a Member, by means such as overloading, ‘flooding’, ‘mailbombing’ or ‘crashing.’; (j) circumvent, disable or
                                    otherwise interfere with security-related features of our Services or features that prevent or restrict use or copying of any Content or that enforce limitations on use of our Services; (k) collect Content, personally identifying information, and/or other information from our Services, or otherwise access our Services, by using any automated means, including but not limited to, ‘robots’, ‘spiders’, ‘scrapers’ and ‘offline readers’, without our prior written approval which we may withhold in our discretion; (l) modify, translate, reverse engineer, decompile, disassemble, create derivative works based on, sublicense, sell, or distribute the Services; (m) rent or lease any rights in the Services in any form to any third party or make the Services available
                                    or accessible to third parties; (n) use any communications systems provided by our Services to send unsolicited or unauthorized commercial communications, including but not limited to by email, SMS, MMS, or any other means; (o) remove, alter or obscure any proprietary notice or identification, including copyright, trademark, patent or other notices displayed on our Services; (p) mislead or attempt to mislead or defraud or attempt to defraud or conceal any information relating to Content or other information that you provide to us; (q) link, deep link, ‘frame’ or ‘mirror’ any part of the Services without our prior consent; or (r) use our Services to violate any applicable laws, rules or regulations, or for any unlawful, harmful, or inappropriate purpose,
                                    or in any manner that breaches these Terms or is otherwise objectionable, as determined by us in our sole discretion.
                                </li>
                                <li>
                                    <strong> Expert designation : </strong> Members may apply to become Experts on our platform. As Experts they may be able to earn money on our platform by answering questions and by providing 1:1 consultations. MarketLync<sup>&reg;</sup> will review their applications, either ourselves, or with support from a panel. If the application is approved, we will convert them to Experts and have them sign a contract. We may also reject their application for any reasons determined by us. MarketLync<sup>&reg;</sup> reserves the right to approve or deny any Expert application for any reason whatsoever. MarketLync<sup>&reg;</sup> reserves the exclusive right to determine the procedure for which an Expert will be approved and such procedure will not be disclosed to
                                    any Member or Expert. You agree that MarketLync will not be liable for any approval or rejection of an Expert application. MarketLync<sup>&reg;</sup> will not be liable for any Member who purports to be an Expert after such application was rejected. MarketLync<sup>&reg;</sup> does not guarantee that any Expert will earn money using the MarketLync<sup>&reg;</sup> platform and will not be liable to any Expert or Member on any matter related to money earned or not earned.
                                </li>
                                <li>
                                    <strong> Transactions between Members : </strong> Members and Experts will be solely responsible for any transactions they may enter into on the MarketLync<sup>&reg;</sup> platform or outside of the platform. MarketLync<sup>&reg;</sup> will not be liable for any private agreements made between Members. You expressly agree that MarketLync<sup>&reg;</sup> will not be made a party to any agreement between Members, nor will they be liable for any disagreement, lawsuit, or any other liability arising from such agreement.
                                </li>
                                <li>
                                    <strong> Expert Consultations : </strong> If an Expert receives a 1:1 consultation request from a Member, the Expert will be required to either confirm or reject the request within 24 hours of receipt of the request. If the Expert does not confirm or reject the request within 24 hours the request will automatically be cancelled. MarketLync<sup>&reg;</sup> reserves the right to change the deadline to accept or reject a request and may do so at its sole discretion. When a consultation is requested via the MarketLync<sup>&reg;</sup> Site or Application, we will share (i) the first and last name of the Member who has requested the consultation and (ii) a link to the Member’s profile page. It is expected that the Expert will review the Member’s profile page
                                    in order to review the information before accepting or rejecting the request. MarketLync<sup>&reg;</sup> reserves the right to reject any request that violates these Terms of Service, the Privacy Policy, or any applicable law. If a request is confirmed MarketLync<sup>&reg;</sup> will send an email, text message, or message via the Application confirming the consultation. The method of confirmation will depend on the communication settings the Member and Expert has chosen.
                                </li>
                                <li>
                                    <strong> Expert Rates : </strong> MarketLync<sup>&reg;</sup> does not determine the rates an Expert will charge for 1:1 consultations and private questions. These rates are determined by the Expert. However, MarketLync<sup>&reg;</sup> reserves the right to set minimum and maximum rates that the Experts on the Platform may charge.
                                </li>
                                <li>
                                    <strong> Private Questions : </strong> Members may ask questions privately on MarketLync<sup>&reg;</sup> to limit the visibility of their questions to only Experts. Experts may then submit financial bids to the Member to answer such questions. Members may choose to accept or reject one or multiple bids from Experts. If a bid is accepted, the Expert should submit an answer within 24 hours of when the bid is accepted or the bid will be automatically rejected and the Member will not be responsible for paying the Expert. MarketLync<sup>&reg;</sup> reserves the right to change the deadline to accept or reject a request and may do so at its sole discretion. Please see Section 2(n) above regarding Expert rates.
                                </li>
                                <li>
                                    <strong> Expert Fees : </strong> . In consideration of the Services, MarketLync<sup>&reg;</sup> charges a fee (the “Service Fee”) based on a percentage of 1:1 Consultation Fees, or fees charged by Experts to answer questions asked privately, collected on your behalf. The current applicable percentage is twenty percent (20%). The Service Fees are subject to change at any time at the sole discretion of MarketLync. Members and Experts will be notified by e-mail of any changes to Service Fees. Additionally, the Service Fees ees may differ among Members and Expert. MarketLync<sup>&reg;</sup> has sole discretion to determine what Service Fee will be charged for all Members and Experts. If MarketLync<sup>&reg;</sup> changes a Service Fee the Member or Expert
                                    will be notified via e-mail. The Service Fee is deducted from the fees payable to Experts. At the conclusion of each 1:1 Consultation, MarketLync<sup>&reg;</sup> calculates the appropriate Fee payable by the Member to the Expert based on the duration of the consultation and the applicable consultation Fee Rate. After an Expert submits an answer to a question a Member asked privately, MarketLync<sup>&reg;</sup> determines the service fee. After deducting the applicable Service Fee, MarketLync<sup>&reg;</sup> remits the balance of the Fees to Experts via its third-party provider www.stripe.com or such other payment methods as may be listed on the Site or via the Application, in U.S. dollars. Except as otherwise provided herein, Service Fees are
                                    non-refundable.
                                </li>
                                <li>
                                    <strong> Non-Disclosure : </strong> The Expert will not knowingly use, disclose or transfer any Member Confidential Information. Confidential Information is all information disclosed during a 1:1 consultation or a private communication, all information the Expert saw on the Member’s business or personal profile, and all information disclosed by the Member to the Expert. The provisions of this Section shall not apply (i) when disclosure is required by law or by any court, arbitrator, mediator or administrative or legislative body (including any committee thereof) with actual or apparent jurisdiction to order the Executive to disclose or make accessible any information; (ii) with respect to any other litigation, arbitration or mediation involving this
                                    Agreement, including, but not limited to, the enforcement of this Agreement; MarketLync<sup>&reg;</sup> is not liable for any disclosure of Confidential Information by any Expert.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong> Your Content </strong>
                            <ol className="alpha">
                                <li>
                                    <strong> Definition of Your Content : </strong> The MarketLync<sup>&reg;</sup> Platform enables you to add posts, texts, photos, videos, links, and other files and information about yourself to share with others. All material that you upload, publish or display to others via the MarketLync<sup>&reg;</sup> Platform will be referred to collectively as “Your Content.” You acknowledge and agree that, as part of using the MarketLync<sup>&reg;</sup> Platform, Your Content may be viewed by the general public.
                                </li>
                                <li>
                                    <strong> Ownership : </strong>You, or your licensors, as applicable, retain ownership of the copyright and other intellectual property in Your Content, subject to the non-exclusive rights granted below.
                                </li>
                                <li>
                                    <strong> License and Permission to Use Your Content : </strong>

                                    <ul className="roman">
                                        <li>
                                            By submitting, posting, or displaying Your Content on the MarketLync<sup>&reg;</sup> Platform, you grant MarketLync<sup>&reg;</sup> and its affiliated companies a nonexclusive, worldwide, royalty free, fully paid up, transferable, sublicensable (through multiple tiers), license to use, copy, reproduce, process, adapt, modify, create derivative works from, publish, transmit, store, display and distribute, translate, communicate and make available to the public, and otherwise use Your Content in connection with the operation or use of the MarketLync<sup>&reg;</sup> Platform or the promotion, advertising or marketing of the MarketLync<sup>&reg;</sup> Platform or our business partners, in any and all media or distribution methods (now known or later
                                            developed), including via means of automated distribution, such as through an application programming interface (also known as an “API”). You agree that this license includes the right for MarketLync<sup>&reg;</sup> to make Your Content available to other companies, organizations, business partners, or individuals who collaborate with MarketLync<sup>&reg;</sup> for the syndication, broadcast, communication and making available to the public, distribution or publication of Your Content on the MarketLync<sup>&reg;</sup> Platform or through other media or distribution methods. This license also includes the right for other users of the MarketLync<sup>&reg;</sup> Platform to use, copy, reproduce, adapt, modify, create derivative works from, publish,
                                            transmit, display, and distribute, translate, communicate and make available to the public Your Content, subject to our Terms of Service. Except as expressly provided in these Terms of Service, this license will not confer the right for you to use automated technology to copy or post questions and answers or to aggregate questions and answers for the purpose of making derivative works.{' '}
                                        </li>
                                        <li>
                                            {' '}
                                            Once you post an answer to a question, you may not edit or delete your answer. If you wish to remove content you must email contact@MarketLync.com to request that the content be hidden. However, we may not be able to control removal of the answer from display on syndicated channels or other previously distributed methods outside of https://www.MarketLync.com. MarketLync<sup>&reg;</sup> may remove suspected spam from your answers. The right for MarketLync<sup>&reg;</sup> to copy, display, transmit, publish, perform, distribute, store, modify, and otherwise use any question you post, and sublicense those rights to others, is perpetual and irrevocable, to the maximum extent permitted by law, except as otherwise specified in this Agreement.
                                        </li>
                                        <li>
                                            {' '}
                                            You acknowledge and agree that MarketLync<sup>&reg;</sup> may preserve Your Content and may also disclose Your Content and related information, even after an account is deleted, if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with legal process, applicable laws or government requests; (b) enforce these Terms of Service; (c) respond to claims that any of Your Content violates the rights of third parties; (d) detect, prevent, or otherwise address fraud, security or technical issues; (e) protect the rights, property, or personal safety of MarketLync, its users, or the public, or ensuring continuation of Service to Members
                                        </li>
                                        <li> You understand that we may modify, adapt, or create derivative works from Your Content in order to transmit, display or distribute it over computer networks, devices, service providers, and in various media. We also may remove or refuse to publish Your Content, in whole or part, at any time.</li>
                                        <li>
                                            {' '}
                                            You further give us the permission and authority to act as your nonexclusive agent to take enforcement action against any unauthorized use by third parties of any of Your Content outside of the MarketLync<sup>&reg;</sup> Platform or in violation of our Terms of Service.{' '}
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <strong> Your Responsibilities for Your Content : </strong>By posting Your Content on the MarketLync<sup>&reg;</sup> Platform, you represent and warrant to us that: i) you have the ownership rights, or you have obtained all necessary licenses or permissions to use Your Content and grant us the rights to use Your Content as provided for under this Agreement, and ii) that posting Your Content violates no intellectual property or personal right of others or any applicable law or regulation, including any laws or regulations requiring disclosure that you have been compensated for Your Content. You accept full responsibility for avoiding infringement of the intellectual property or personal rights of others or violation of laws and regulations in connection
                                    with Your Content. You are responsible for ensuring that Your Content does not violate MarketLync’s Terms of Service, Privacy Policy, other published MarketLync<sup>&reg;</sup> policy, or any applicable law or regulation. You agree to pay all royalties, fees, and any other monies owed to any person by reason of Your Content. You represent and warrant that you own or otherwise possess all necessary rights with respect to your Content, and that your Content and/or the disclosure of your Content does not and will not infringe, misappropriate, will not breach any duty of confidentiality, or violate any copyright, trade secret right or other intellectual property or other property right of any third party. You represent and warrant that the Content you
                                    Transmit is not unlawful, fraudulent, threatening, abusive, libelous, defamatory, or obscene. You represent and warrant that any Content Transmitted by you does not violate the provisions of the Securities Act of 1933 or the Securities Exchange Act of 1934 (the “Acts”) or the provisions of Securities and Exchange Commission’s rules relating to the Acts (including but not limited Rule 240 relating to disclosure or use of material non-public inside information material non-public inside information).
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong> Our Content and Materials </strong>
                            <ul className="alpha">
                                <li>
                                    <strong> Definition of Our Content and Materials : </strong> All intellectual property in or related to the MarketLync<sup>&reg;</sup> Platform (specifically including, but not limited to our software, the MarketLync<sup>&reg;</sup> marks, the MarketLync<sup>&reg;</sup> logo, but excluding Your Content) is the property of MarketLync, Inc., or its subsidiaries and affiliates, or content posted by other MarketLync<sup>&reg;</sup> users licensed to us (collectively “Our Content and Materials”).
                                </li>
                                <li>
                                    <strong> Data : </strong>All data MarketLync<sup>&reg;</sup> collects (“Data”) about use of the MarketLync<sup>&reg;</sup> Platform by you or others is the property of MarketLync, Inc., its subsidiaries, and affiliates. For clarity, Data does not include Your Content and is separate from Our Content and Materials.
                                </li>
                                <li>
                                    <strong> Our License to You : </strong>
                                    <ul className="roman">
                                        <li>
                                            {' '}
                                            We grant you a personal, limited, non-exclusive license to use and access Our Content and Materials and Data as made available to you on the MarketLync<sup>&reg;</sup> Platform in connection with your use of the MarketLync<sup>&reg;</sup> Platform, subject to the terms and conditions of this Agreement.
                                        </li>
                                        <li>
                                            MarketLync<sup>&reg;</sup> gives you a worldwide, royalty-free, revocable, personal, non- assignable and non-exclusive license to re-post Our Content and Materials anywhere on the web, subject to these Terms and Conditions and provided that: (a) the user who created the content has not explicitly marked the content as not for reproduction on the MarketLync<sup>&reg;</sup> Platform; (b) you do not modify the content; (c) you attribute MarketLync<sup>&reg;</sup> by name in readable text and with a human and machine-followable link that links back to the page displaying the original source of the content on MarketLync.com on every page that contains Our Content and Materials; (d) upon request, either by MarketLync<sup>&reg;</sup> or a user, you
                                            remove the user's name from content which the user has subsequently made anonymous; (e) upon request, either by MarketLync<sup>&reg;</sup> or by a user who contributed to the content, you make a reasonable effort to update a particular piece of content to the latest version on the MarketLync<sup>&reg;</sup> Platform; and (f) upon request, either by MarketLync<sup>&reg;</sup> or by a user who contributed to the content, you make a reasonable attempt to delete content that has been deleted or marked as not for reproduction on the MarketLync<sup>&reg;</sup> Platform; (g) you don’t republish more than a small portion of Our Content and Materials; (h) you do not use any automated tools to aggregate or create derivative works. In exercising these
                                            rights, you may not implicitly or explicitly assert any connection with, sponsorship or endorsement by MarketLync, or any MarketLync user, without separate, express prior written permission from us.
                                        </li>
                                        <li>
                                            {' '}
                                            We may terminate our license to you at any time for any reason. We have the right but not the obligation to refuse to distribute any content on the MarketLync<sup>&reg;</sup> Platform or to remove content. Except for the rights and license granted in these Terms of Service, we reserve all other rights and grant no other rights or licenses, implied or otherwise.
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <strong> Permitted uses : </strong>
                                    If you operate a search engine, web crawler, bot, scraping tool, data mining tool, bulk downloading tool, wget utility, or similar data gathering or extraction tool, you may access the MarketLync<sup>&reg;</sup> Platform, subject to the following additional rules: i) you must use a descriptive user agent header; ii) you must follow robots.txt at all times; iii) your access must not adversely affect any aspect of the MarketLync<sup>&reg;</sup> Platform’s functioning; and iv) you must make it clear how to contact you, either in your user agent string, or on your website if you have one. You represent and warrant that you will not use any automated tools such as artificial intelligence or machine learning i) to create derivative works of Our Content and
                                    Materials; ii) to create any service competitive to the MarketLync<sup>&reg;</sup> Platform; or iii) for other commercial purposes except as expressly permitted by these Terms of Service or the written consent of MarketLync.
                                </li>
                                <li>
                                    <strong> No Endorsement or Verification : </strong>
                                    Please note that the MarketLync<sup>&reg;</sup> Platform contains access to third-party content, products and services, and it offers interactions with third parties. Participation or availability on the MarketLync<sup>&reg;</sup> Platform does not amount to endorsement or verification by us. We make no warranties or representations about the accuracy, completeness, or timeliness of any content posted on the MarketLync<sup>&reg;</sup> Platform by anyone.
                                </li>
                                <li>
                                    <strong> Ownership : </strong>You acknowledge and agree that Our Content and Materials remain the property of MarketLync<sup>&reg;</sup>'s users or MarketLync. The content, information and services made available on the MarketLync<sup>&reg;</sup> Platform are protected by U.S. and international copyright, trademark, and other laws, and you acknowledge that these rights are valid and enforceable.
                                </li>
                                <li>
                                    <strong> Copyright : </strong> Except in the case of Content under license to us, we claim a copyright, and all copyright protection afforded, under international, United States and the laws of the State of Delaware to all text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, software (ours or our software suppliers), and all other Content on our Services. The compilation of all Content on our Services is our exclusive property, and it is similarly protected. We also claim a copyright, and all copyright protection afforded, under international, United States and the laws of the State of Delaware to all material described in the trademarks section above. Your access to all information and Content located on our
                                    Services is strictly permitted through the license granted to you under these Terms. Except for the license granted in these Terms, all rights, title and interest in Content, in all languages, formats and media throughout the world, including all copyrights, are and will continue to be the exclusive property of ours and other parties. Except as permitted by these Terms, you are prohibited from modifying, copying, distributing, displaying, publishing, selling, licensing, creating derivative works, or using any Content available on or through our Services without our prior written permission, or in the case of Content owned by a third party, without first receiving permission from the owner of that Content. You may not alter or remove any trademark,
                                    copyright or other notice from copies of the Content.
                                </li>
                            </ul>
                        </li>{' '}
                        <li>
                            <strong> Integrated Service Provider : </strong> You may enable another online service provider, such as a social networking service (“Integrated Service Provider”), to be directly integrated into your account on the MarketLync<sup>&reg;</sup> Platform. By enabling an integrated service, you are allowing us to pass to, and receive from, the Integrated Service Provider your log-in information and other user data. For more information about MarketLync’s use, storage, and disclosure of information related to you and your use of integrated services within MarketLync, please see our Privacy Policy. Note that your use of any Integrated Service Provider and its own handling of your data and information is governed solely by their terms of use, privacy policies, and
                            other policies.
                        </li>
                        <li>
                            <strong>
                                {' '}
                                More About Certain Offerings on the MarketLync<sup>&reg;</sup> Platform{' '}
                            </strong>
                            <ul className="alpha">
                                <li>
                                    <strong> Advertisements : </strong>The MarketLync<sup>&reg;</sup> Platform may include advertisements, which may be targeted to content or information on the MarketLync<sup>&reg;</sup> Platform, queries made through the MarketLync<sup>&reg;</sup> Platform, or other information, in an effort to make them relevant to you. The types and extent of advertising by MarketLync<sup>&reg;</sup> are subject to change. In consideration for MarketLync<sup>&reg;</sup> granting you access to and use of the MarketLync<sup>&reg;</sup> Platform, you agree that MarketLync<sup>&reg;</sup> and its third-party providers and partners may place such advertising on the MarketLync<sup>&reg;</sup> Platform. If you wish to become an advertiser, you will be required to enter into
                                    separate and supplemental terms with us about providing advertising services on the MarketLync<sup>&reg;</sup> Platform.
                                </li>
                                <li>
                                    <strong> Subscriptions : </strong>
                                    Certain content of the MarketLync<sup>&reg;</sup> Platform may be available only by subscription.
                                </li>
                                <li>
                                    <strong> Legal, Medical &amp; Other Professional Contributors : </strong>
                                    Some users who post content are Members of legal, medical, and other licensed professions (collectively, “Professional Contributors”). Content posted by Professional Contributors should not be relied on as a substitute for advice from a professional that is appropriate for your specific situation. Ethics rules differ by state or location, and it is the responsibility of Professional Contributors to determine and provide disclaimers appropriate for their profession and the content provided.
                                </li>
                                <li>
                                    <strong> Buttons, Links and Widgets : </strong>
                                    You have permission to use MarketLync<sup>&reg;</sup>'s buttons, links, and widgets, subject to these Terms of Service (including the disclaimers and limitations of liability) and provided that: (a) your use of such buttons, links and widgets link only to the MarketLync<sup>&reg;</sup> Platform; (b) you will not modify such buttons, links, or widgets or associated code in any manner; (c) you will not use any such buttons, links, or widgets in any manner which implies or suggests that MarketLync<sup>&reg;</sup> endorses, sponsors, or recommends the website on which such buttons, links and widgets are used.
                                </li>
                                <li>
                                    <strong> Web resources and third-party services : </strong>
                                    The MarketLync<sup>&reg;</sup> Platform may also offer you the opportunity to visit links to other websites or to engage with third-party products or services. You assume all risk arising out of your use of such websites or resources.
                                </li>
                                <li>
                                    <strong> Services that Require Separate Agreement : </strong>
                                    Certain features or services may require that you enter into a separate and supplemental written agreement prior to use.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>
                                <strong>
                                    Reporting Violations of MarketLync<sup>&reg;</sup> Policies or Applicable Laws :{' '}
                                </strong>
                            </p>
                            <p>
                                If you believe content on the MarketLync<sup>&reg;</sup> Platform violates MarketLync’s Policies, intellectual property rights, copyright, trademark rights or otherwise violates applicable law you can report such violation by clicking the “Report Content” button. All reports will be investigated, however we have no obligation to delete content that you personally may find objectionable or offensive. We endeavor to respond promptly to requests for content removal, consistent with our policies and applicable law. If a user’s content is reported more than 3 times by other users, MarketLync<sup>&reg;</sup> may automatically block such content, either temporarily or permanently, from appearing anywhere on the platform.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong> DISCLAIMERS AND LIMITATION OF LIABILITY </strong>
                            </p>
                            <p>
                                PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF MarketLync<sup>&reg;</sup> ENTITIES TO YOU.
                            </p>
                            <p>
                                “MarketLync<sup>&reg;</sup> ENTITIES” MEANS MARKETLYNC, INC., AND ANY SUBSIDIARIES, AFFILIATES, RELATED COMPANIES, SUPPLIERS, LICENSORS AND PARTNERS, AND THE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS AND REPRESENTATIVES OF EACH OF{' '}
                            </p>
                            <ul className="alpha mt-4">
                                <li>
                                    WE ARE PROVIDING YOU THE MarketLync<sup>&reg;</sup> PLATFORM, ALONG WITH OUR CONTENT AND MATERIALS AND THE OPPORTUNITY TO CONNECT WITH OTHERS, ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING, MarketLync<sup>&reg;</sup> ENTITIES EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, TITLE, ACCURACY AND COMPLETENESS, UNINTERRUPTED OR ERROR-FREE SERVICE, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR TRADE USAGE.
                                </li>
                                <li>
                                    MarketLync<sup>&reg;</sup> MAKES NO PROMISES WITH RESPECT TO, AND EXPRESSLY DISCLAIMS ALL LIABILITY FOR: (i) CONTENT POSTED BY ANY USER OR THIRD PARTY; (ii) ANY THIRD-PARTY WEBSITE, THIRD-PARTY PRODUCT, OR THIRD-PARTY SERVICE LISTED ON OR ACCESSIBLE TO YOU THROUGH THE MarketLync<sup>&reg;</sup> PLATFORM, INCLUDING AN INTEGRATED SERVICE PROVIDER OR PROFESSIONAL CONTRIBUTOR; (iii) THE QUALITY OR CONDUCT OF ANY THIRD PARTY YOU ENCOUNTER IN CONNECTION WITH YOUR USE OF THE MarketLync<sup>&reg;</sup> PLATFORM; (iv) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR CONTENT,(v) ADVICE PROVIDED AND RECEIVED THROUGH 1:1 AUDIO VISUAL CONSULTATIONS, OR (vi) THE AUTHENTICITY OF ANY USER, PROFESSIONAL, OR OTHER SERVICE PROVIDER’S PROFILE, CREDENTIALS, EDUCATION, AND
                                    IDENTITY MarketLync<sup>&reg;</sup> MAKES NO WARRANTY THAT: (a) THE MarketLync<sup>&reg;</sup> PLATFORM WILL MEET YOUR REQUIREMENTS; (b) THE MarketLync<sup>&reg;</sup> PLATFORM WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (c) THE RESULTS OR INFORMATION THAT YOU MAY OBTAIN FROM THE USE OF THE MarketLync<sup>&reg;</sup> PLATFORM, A PROFESSIONAL CONTRIBUTOR, OR ANY OTHER USER WILL BE ACCURATE OR RELIABLE; OR (d) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL OBTAINED OR PURCHASED BY YOU THROUGH THE MarketLync<sup>&reg;</sup> PLATFORM WILL BE SATISFACTORY.
                                </li>
                                <li>
                                    YOU AGREE THAT TO THE MAXIMUM EXTENT PERMITTED BY LAW, MarketLync<sup>&reg;</sup> ENTITIES WILL NOT BE LIABLE TO YOU UNDER ANY THEORY OF LIABILITY. WITHOUT LIMITING THE FOREGOING, YOU AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, MarketLync<sup>&reg;</sup> ENTITIES SPECIFICALLY WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR EXEMPLARY DAMAGES, LOSS OF PROFITS, BUSINESS INTERRUPTION, REPUTATIONAL HARM, OR LOSS OF DATA (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR SUCH DAMAGES ARE FORESEEABLE) ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR USE OF, OR INABILITY TO USE, THE MarketLync<sup>&reg;</sup> PLATFORM.
                                </li>
                                <li>
                                    YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE MarketLync<sup>&reg;</sup> PLATFORM IS TO STOP USING THE MarketLync<sup>&reg;</sup> PLATFORM.
                                </li>
                                <li>SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES. AS A RESULT, THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU IN WHOLE OR IN PART, AND THE FOREGOING SECTIONS 8(c), 8(d), AND 8(e) WILL NOT APPLY TO A RESIDENT OF NEW JERSEY, TO THE EXTENT DAMAGES TO SUCH NEW JERSEY RESIDENT ARE THE RESULT OF MARKETLYNC’S NEGLIGENT, FRAUDULENT, RECKLESS, OR INTENTIONAL MISCONDUCT.</li>
                            </ul>
                        </li>
                        <li>
                            <p>
                                <strong>Indemnification </strong>
                            </p>
                            You agree to release, indemnify, and defend MarketLync<sup>&reg;</sup> Entities from all third-party claims and costs (including reasonable attorneys’ fees) arising out of or related to: i) your use of the MarketLync<sup>&reg;</sup> Platform, ii) Your Content, iii) your conduct or interactions with other users of the MarketLync<sup>&reg;</sup> Platform, or iv) your breach of any part of this Agreement. We will promptly notify you of any such claim and will provide you (at your expense) with reasonable assistance in defending the claim. You will allow us to participate in the defense and will not settle any such claim without our prior written consent. We reserve the right, at our own expense, to assume the exclusive defense of any matter otherwise subject to
                            indemnification by you. In that event, you will have no further obligation to defend us in that matter.
                        </li>
                        <li>
                            <p>
                                <strong>Legal Disputes and Arbitration Agreement for users in the United States and Canada </strong>
                            </p>
                            <p>Please Read the Following Clause Carefully – It May Significantly Affect Your Legal Rights, Including Your Right to File a Lawsuit in Court</p>
                            <ol className="alpha">
                                <li>
                                    <p>
                                        <strong> Initial Dispute Resolution : </strong>We are available by email at contact@MarketLync.com, to address any concerns you may have regarding your use of the MarketLync<sup>&reg;</sup> Platform. Most concerns may be quickly resolved in this manner. The parties agree to use best efforts to settle any dispute, claim, question, or disagreement directly through consultation and good faith negotiations with us, before initiating a lawsuit or arbitration.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Agreement to Binding Arbitration : </strong> If an agreed upon solution is not reached through informal dispute resolution as outlined above within a period of thirty (30) days pursuant to the initial dispute resolution procedure provided in Section 10(a) above, then either party may initiate binding arbitration to formally resolve claims, subject to the terms and exclusion set forth below. All claims arising out of or relating to these Terms of Service (including their formation, performance, and breach), the parties’ relationship with each other, and/or your use of the MarketLync<sup>&reg;</sup> Platform will be finally settled by binding arbitration in the State of Delaware. Each party will have the right to use legal counsel in
                                        connection with arbitration at its own expense.. The arbitrator, and not any federal, state or local court or agency, will have exclusive authority to resolve all disputes arising out of or relating to the interpretation, applicability, enforceability, or formation of these Terms of Service, including, but not limited to, any claim that all or any part of these Terms of Service is void or voidable. Either party may elect to appear at the arbitration by phone or, if mutually agreed, to conduct it online, in lieu of appearing live. The arbitrator will be empowered to grant whatever relief would be available in a court under law or in equity. The arbitrator’s award will be in writing and provide a statement of the essential findings and conclusions,
                                        will be binding on the parties and may be entered as a judgment in any court of competent jurisdiction. The interpretation and enforcement of these Terms of Service will be subject to the Federal Arbitration Act.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Rules : </strong> The AAA rules governing the arbitration may be accessed at https://www.adr.org/ . Each party will be responsible for its arbitration fees as set forth in the AAA Consumer Arbitration Rules. You will not be required to pay fees and costs incurred by us if you do not prevail in arbitration. The parties understand that, absent this mandatory provision, they would have the right to sue in court and have a jury trial. They further understand that the right to discovery may be more limited in arbitration than in court.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Class Action and Class Arbitration Waiver : </strong> The parties each further agree that any arbitration will be conducted in our respective individual capacities only and not as a class action or other representative action, and each expressly waives its respective right to file a class action or seek relief on a class basis. If any court or arbitrator determines that the class action waiver set forth in this paragraph is void or unenforceable for any reason or that an arbitration can proceed on a class basis, then the arbitration provision set forth above in Section 10(b) will be deemed null and void in its entirety and the parties will be deemed to have not agreed to arbitrate disputes.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Exception : </strong> Small Claims Court Claims. Notwithstanding the parties’ agreement to resolve all disputes through arbitration, either party may seek relief in a small claims court for disputes or claims within the scope of that court’s jurisdiction and on an individual (non-class) basis only.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Exception : </strong> Intellectual Property and Content Claims. Notwithstanding the parties’ agreement to resolve all disputes through arbitration, this binding arbitration section does not apply to disputes relating to either party’s intellectual property rights (trademark, copyright, patent, and trade secret).{' '}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> 30 Day Right to Opt-Out : </strong> You have the right to opt-out and not be bound by the arbitration and class action waiver provisions set forth in Sections 10(b) and 10(c) by sending written notice of your decision to opt-out by emailing us at contact@MarketLync.com. The notice must be sent within thirty (30) days of registering to use the MarketLync<sup>&reg;</sup> Platform, otherwise you will be bound to arbitrate disputes in accordance with the terms of those sections. If you opt out of these arbitration provisions, we also will not be bound by them.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Exclusive Venue for Litigation : </strong> To the extent that the arbitration provisions set forth in Section 10(b) do not apply, or if you have opted out of arbitration, the parties agree that any litigation between them must be filed exclusively in state or federal courts located in New Castle County Delaware. The parties expressly consent to exclusive jurisdiction in Delaware for any litigation. In the event of litigation relating to these Terms of Service, the parties agree to waive, to the maximum extent permitted by law, any right to a jury trial, except where a jury trial waiver is not permissible under applicable law.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>
                                <strong> General Terms </strong>
                            </p>
                            <ol className="alpha">
                                <li>
                                    <p>
                                        <strong> Changes to these Terms of Service : </strong> We may amend this Agreement (including any policies, such as the Privacy Policy, that are incorporated into this Agreement) at any time, in our sole discretion. If we amend material terms to this Agreement, such amendment will be effective after we send you notice of the amended agreement. Such notice will be in our sole discretion, and the manner of notification could include, for example, via email, posted notice on the MarketLync<sup>&reg;</sup> Platform, or other manner. Your failure to cancel your account, or cease use of the MarketLync<sup>&reg;</sup> Platform, after receiving notification of the amendment, will constitute your acceptance of the amended terms. If you do not agree to the
                                        amendments or to any of the terms in this Agreement, your only remedy is to cancel your account or to cease use of the MarketLync<sup>&reg;</sup> Platform.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Use Outside of the United States : </strong> MarketLync<sup>&reg;</sup> expressly disclaims any representation or warranty that the MarketLync<sup>&reg;</sup> Platform complies with all applicable laws and regulations outside of the United States. If you use the MarketLync<sup>&reg;</sup> Platform outside of the United States, you expressly understand and agree that you are responsible for determining compliance with different laws, regulations, or customs that may apply in connection with your use of the MarketLync<sup>&reg;</sup> Platform.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Export : </strong> The MarketLync<sup>&reg;</sup> Platform is controlled and operated from our United States offices in Delaware. MarketLync<sup>&reg;</sup> software is subject to United States export controls. No software for MarketLync<sup>&reg;</sup> may be downloaded or otherwise exported or re-exported in violation of any applicable laws or regulations. You represent that you are not (1) located in a country that is subject to a U.S. government embargo, or that has been designated by the U.S. government as a “terrorist supporting” country, and (2) listed on any U.S. government list of prohibited or restricted parties.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Applications and Mobile Devices : </strong> If you access the MarketLync<sup>&reg;</sup> Platform through a MarketLync<sup>&reg;</sup> application, you acknowledge that this Agreement is between you and MarketLync<sup>&reg;</sup> only, and not with another application service provider or application platform provider (such as Apple Inc. or Google Inc.), which may provide you the application subject to its own terms. To the extent you access the MarketLync<sup>&reg;</sup> Platform through a mobile device, your wireless carrier’s standard charges, data rates, and other fees may apply.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Survival : </strong> The following provisions will survive expiration or termination of this Agreement: Section 2(e)(Termination), 2(g)(Feedback), Section 3(Your Content), Section 4(a)-(b) and (d)-(f)(Our Content and Materials), Section 8 (Disclaimers and Limitation of Liability), Section 9 (Indemnification), Sections 10 (Legal Disputes and Arbitration Agreement), and Section 11 (General Terms).
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Notice for California Users : </strong>Under California Civil Code Section 1789.3, California web users are entitled to the following specific consumer rights notice: The MarketLync<sup>&reg;</sup> Platform is provided by MarketLync, Inc., located inDelaware. If you have a question or complaint regarding the MarketLync<sup>&reg;</sup> Platform, please contact MarketLync<sup>&reg;</sup> at support@MarketLync.com . California residents may reach the Department of Consumer Affairs Consumer Information Division at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone at (916)445-1254 or (800) 952-5210 or Hearing Impaired at TDD (800) 326-2297 or TDD (916) 322-1700.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Assignment : </strong> You may not assign or transfer this Agreement (or any of your rights or obligations under this Agreement) without our prior written consent; any attempted assignment or transfer without complying with the foregoing will be void. We may freely assign or transfer this Agreement. This Agreement inures to the benefit of and is binding upon the parties and their respective legal representatives, successors, and assigns.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Electronic Communications : </strong> You consent to receive communications from us by email in accordance with this Agreement and applicable law. You acknowledge and agree that all agreements, notices, disclosures and other communications that we provide to you electronically will satisfy any legal requirement that such communications be in writing.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Entire Agreement/ Severability : </strong> This Agreement supersedes all prior terms, agreements, discussions and writings regarding the MarketLync<sup>&reg;</sup> Platform and constitutes the entire agreement between you and us regarding the MarketLync<sup>&reg;</sup> Platform (except as to services that require separate written agreement with us, in addition to this Agreement). If any provision in this Agreement is found to be unenforceable, then that provision will not affect the enforceability of the remaining provisions of the Agreement, which will remain in full force and effect.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Interpretation : </strong> In construing or interpreting the terms of this Agreement: (i) the headings in this Agreement are for convenience only, and are not to be considered, and (ii) no presumption is to operate in either party’s favor as a result of its counsel’s role in drafting this Agreement.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Notices : </strong> All notices permitted or required under this Agreement, unless specified otherwise in this Agreement, must be sent in writing as follows in order to be valid: (i) if to you, by us via email to the address associated with your account, and (ii) if to us, by you via contact@MarketLync.com. Notices will be deemed given (a) if to you, when emailed, and (b) if to us, on receipt by us.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Relationship : </strong> This Agreement does not create a joint venture, agency, partnership, or other form of joint enterprise between you and us. Except as expressly provided herein, neither party has the right, power, or authority to create any obligation or duty, express or implied, on behalf of the other.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Waiver : </strong> No waiver of any terms will be deemed a further or continuing waiver of such term or any other term. Our failure to assert a right or provision under this Agreement will not constitute a waiver of such right or provision.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Further Assurances : </strong> You agree to execute a hard copy of this Agreement and any other documents, and to take any actions at our expense that we may request to confirm and effect the intent of this Agreement and any of your rights or obligations under this Agreement.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong> Contact : </strong> Feel free to contact us through contact@MarketLync.com with any questions about these terms. MarketLync<sup>&reg;</sup> is a Delaware corporation whose registered agent is United States Corporation Agents, Inc. located at 221 N. Broad Street, Suite 3A, Middletown, DE 19709.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        {/* <li>
                            <strong>sd </strong>
                        </li>
                        <li>
                            <strong>sd </strong>
                        </li> */}
                    </ol>
                </Grid>
            </Container>
        </>
    );
};

export default Terms;
