import { FC, useEffect } from 'react';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import NotFound from './common/components/NotFound';
import { Help } from './modules/help';
import Home from './modules/home/Home';
import MainContainer from './modules/navigation/MainContainer';
import Aboutus from './modules/aboutus/Aboutus';
import Learinganddiscovery from './modules/learinganddiscovery/Learinganddiscovery';
import Expertconsultations from './modules/expert-consultations/Expertconsultations';
import Terms from './modules/terms-of-service/terms';
import Policy from './modules/terms-of-service/policy';
import OneonOneConsultations from './modules/OneonOneConsultations/OneonOneConsultations';
import MultiExpertInsights from './modules/MultiExpertInsights/MultiExpertInsights';
import CommunityInteraction from './modules/CommunityInteraction/CommunityInteraction';
import BecomeAnExpert from './modules/BecomeAnExpert/BecomeAnExpert';
import ContactForm from './modules/contact/ContactForm';
import { BASE_URL } from './AppConfig';
import Insights from './modules/insights/insights';
import MarketingForSmallBusinesses from './modules/insights/3-Key-Types-of-Digital-and-Social-Media-Marketing-for-Small-Businesses';
import BeatYourCompetition from './modules/insights/The-4-Step-Small-Biz-Marketing-Strategy-to-Beat-Your-Competition';
import FundingForSmallBusinesses from './modules/insights/4-Small-Business-Funding-Options-to-Fuel-Your-Growth';
import YourCustomerExperience from './modules/insights/3-Ways-To-Quickly-Improve-Your-Customer-Experience';
import FinancialManagementAndAccounting from './modules/insights/Financial-Management-and-Accounting-for-Small-Businesses';
import OperationsAndLogistics from './modules/insights/Managing-Operations-in-a-Small-Business-What-Are-Your-Priorities';
import LegalEntitiesToSetupYourBusiness from './modules/insights/4-Types-Of-Legal-Entities-To-Setup-Your-Business-Pros-&-Cons-Of-Each';
import WhySmallBusinessOwners from './modules/insights/Why-Small-Business-Owners-Need-To-Be-Good-Leaders';
import AutomationsBusinessWeek from './modules/insights/6-Automations-You-Can-Implement-In-Your-Small-Business-This-Week';
import SmallBusinessHiring from './modules/insights/Small-Business-Hiring-4-Priorities-When-Investing-In-Human-Resources';
import YourSmallBussiness from './modules/insights/6-Simple-Steps-to-Help-You-Grow-Strategize-and-Analyze-Your-Small-Business';
import MatterForSmallBussiness from './modules/insights/Why-Sales-and-Customer-Relationship-Management-Matter-for-Small-Businesses';


interface IAppRouterProps {}

const AppRouter: FC<IAppRouterProps> = () => {
    const ScrollToTop = () => {
        const { pathname } = useLocation();
        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);

        return null;
    };

    return (
        <>
            <BrowserRouter>
                <ScrollToTop />
                <Switch>
                    <Route exact path="/auth/signin" render={() => (window.location.href = `${BASE_URL}/auth/signin`)} />

                    <Route exact path="/auth/signup" render={() => (window.location.href = `${BASE_URL}/auth/signup`)} />

                    <Route exact path="/aboutus/:path?">
                        <MainContainer>
                            <Switch>
                                <Route exact path="/aboutus/" component={Aboutus} />
                            </Switch>
                        </MainContainer>
                    </Route>
                    <Route exact path="/learinganddiscovery/:path?">
                        <MainContainer>
                            <Switch>
                                <Route exact path="/learinganddiscovery/" component={Learinganddiscovery} />
                            </Switch>
                        </MainContainer>
                    </Route>

                    <Route exact path="/terms-of-service/:path?">
                        <MainContainer>
                            <Switch>
                                <Route exact path="/terms-of-service" component={Terms} />
                            </Switch>
                        </MainContainer>
                    </Route>
                    <Route exact path="/privacy-policy/:path?">
                        <MainContainer>
                            <Switch>
                                <Route exact path="/privacy-policy" component={Policy} />
                            </Switch>
                        </MainContainer>
                    </Route>
                    <Route exact path="/expert-consultations/:path?">
                        <MainContainer>
                            <Switch>
                                <Route exact path="/expert-consultations/" component={Expertconsultations} />
                            </Switch>
                        </MainContainer>
                    </Route>
                    <Route exact path="/one-on-one-consultations/:path?">
                        <MainContainer>
                            <Switch>
                                <Route exact path="/one-on-one-consultations" component={OneonOneConsultations} />
                            </Switch>
                        </MainContainer>
                    </Route>

                    <Route exact path="/Insights/:path?">
                        <MainContainer>
                            <Switch>
                                <Route exact path="/Insights" component={Insights} />
                                <Route exact path="/Insights/3-Key-Types-of-Digital-and-Social-Media-Marketing-for-Small-Businesses" component={MarketingForSmallBusinesses} />
                                <Route exact path="/Insights/The-4-Step-Small-Biz-Marketing-Strategy-to-Beat-Your-Competition" component={BeatYourCompetition} />
                                <Route exact path="/Insights/4-Small-Business-Funding-Options-to-Fuel-Your-Growth" component={FundingForSmallBusinesses} />
                                <Route exact path="/Insights/3-Ways-To-Quickly-Improve-Your-Customer-Experience" component={YourCustomerExperience} />
                                <Route exact path="/Insights/Financial-Management-and-Accounting-for-Small-Businesses" component={FinancialManagementAndAccounting} />
                                <Route exact path="/Insights/Managing-Operations-in-a-Small-Business-What-Are-Your-Priorities" component={OperationsAndLogistics} />
                                <Route exact path="/Insights/4-Types-Of-Legal-Entities-To-Setup-Your-Business-Pros-&amp;-Cons-Of-Each" component={LegalEntitiesToSetupYourBusiness} />
                                <Route exact path="/Insights/Why-Small-Business-Owners-Need-To-Be-Good-Leaders" component={WhySmallBusinessOwners} />
                                <Route exact path="/Insights/6-Automations-You-Can-Implement-In-Your-Small-Business-This-Week" component={AutomationsBusinessWeek} />
                                <Route exact path="/Insights/Small-Business-Hiring-4-Priorities-When-Investing-In-Human-Resources" component={SmallBusinessHiring} />
                                <Route exact path="/Insights/6-Simple-Steps-to-Help-You-Grow-Strategize-and-Analyze-Your-Small-Business" component={YourSmallBussiness} />
                                <Route exact path="/Insights/Why-Sales-and-Customer-Relationship-Management-Matter-for-Small-Businesses" component={MatterForSmallBussiness} />
                            </Switch>
                        </MainContainer>
                    </Route>

                    <Route exact path="/multi-expert-insights/:path?">
                        <MainContainer>
                            <Switch>
                                <Route exact path="/multi-expert-insights" component={MultiExpertInsights} />
                            </Switch>
                        </MainContainer>
                    </Route>
                    <Route exact path="/community-interaction/:path?">
                        <MainContainer>
                            <Switch>
                                <Route exact path="/community-interaction" component={CommunityInteraction} />
                            </Switch>
                        </MainContainer>
                    </Route>
                    <Route exact path="/become-an-expert/:path?">
                        <MainContainer>
                            <Switch>
                                <Route exact path="/become-an-expert/" component={BecomeAnExpert} />
                            </Switch>
                        </MainContainer>
                    </Route>
                    <Route path="/contact/:path?">
                        <MainContainer>
                            <Switch>
                                <Route exact path="/contact/:id" component={ContactForm} />
                            </Switch>
                        </MainContainer>
                    </Route>
                    <Route exact path="/test" render={ () =>  window.location.href="test.html"} />
                    <Route exact path="/">
                        <MainContainer>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/help" component={Help} />
                        </MainContainer>
                    </Route>
                    <Route component={NotFound} />
                </Switch>
            </BrowserRouter>
        </>
    );
};

export { AppRouter, MainContainer };
