import { faFacebookF, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Container, Grid, Link } from '@material-ui/core';
import { FC } from 'react';

interface IMainFooterProps {}

const MainFooter: FC<IMainFooterProps> = (props) => {
    return (
        <>
            <Box component="footer" className="main-footer">
                <Container maxWidth="lg">
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4} lg={3} className="text-center text-lg-left">
                            &copy; {new Date().getFullYear()} MarketLync<sup>&reg;</sup>. All rights reserved.
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} className="ml-auto">
                            <Box className="footer-list justify-content-lg-end">
                                <Link href="/terms-of-service" color="secondary">
                                    Terms of Service
                                </Link>
                                <Link href="/privacy-policy" color="secondary">
                                    Privacy Policy
                                </Link>

                                <Link href="/contact/general-inquiry" color="secondary">
                                    Contact Us
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={2} lg={3}>
                            <Box className="social-list">
                                <Link href="https://twitter.com/marketlync" color="secondary" rel="noreferrer" target="_blank">
                                    <FontAwesomeIcon icon={faTwitter} size="lg" className="ml-0" />
                                </Link>
                                <Link href="https://www.linkedin.com/company/marketlync/" color="secondary" rel="noreferrer" target="_blank">
                                    <FontAwesomeIcon icon={faLinkedinIn} size="lg" className="ml-0" />
                                </Link>
                                <Link href="https://www.facebook.com/marketlync" color="secondary" rel="noreferrer" target="_blank">
                                    <FontAwesomeIcon icon={faFacebookF} size="lg" className="ml-0" />
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default MainFooter;
