import * as React from 'react';
import { Avatar, Box, CardContent, Container, Grid, InputLabel, Link, MenuItem, Typography } from '@material-ui/core';
import { FC } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { imgBanner3, imgUser, expertBadge } from '../../assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroadcastTower, faQuestion, faStar, faUserShield, faVideo } from '@fortawesome/free-solid-svg-icons';
// import your fontawesome library

interface IExpertconsultationsProps {}

const Expertconsultations: FC<IExpertconsultationsProps> = (props) => {
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
    return (
        <>
            <Box className="top-inner-banner">
                <figure className="h-100">
                    <img src={imgBanner3} alt="img" />
                </figure>
                <Box className="inner-banner-content">
                    <Typography variant="h1" className="text-display">
                        1:1 Consultations
                    </Typography>
                </Box>
            </Box>
            <Container maxWidth="lg">
                <Grid container justify="center" spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="typo-center secondary-holder">
                        {/* <Typography variant="h2" className="ml-display-2">
                        One line of benefits sentence goes here
                    </Typography> */}
                        <Typography className="text-center mb-4 fs-16">
                            Harness the power of 1:1 consultations to jumpstart your decisions effectively. If you need a private consultation to tackle your business challenges in a more discreet manner, feel free to ask your questions privately, and get expert advice from a true professional, verified with MarketLync<sup>&reg;</sup> - guaranteed to share valuable insights. For a small fee, you will be assigned a vetted consultant who has the right skills to offer comprehensive, actionable advice. Schedule an audio or video conference to facilitate in-depth discussions.
                        </Typography>
                    </Grid>
                </Grid>
                {/* <Grid container justify="center">
                <Grid item xs={12} md={6} className="d-flex justify-content-center align-items-center mb-5">
                    <img src={imgVideo} alt="img" />
                </Grid>
            </Grid> */}
            </Container>
            <Container maxWidth="lg">
                <Grid container justify="center" spacing={3}>
                    <Grid item xs={12} md={10} className="typo-center">
                        <Grid container justify="space-between" alignItems="center">
                            <Grid xs={12} sm={8} className="mb-3">
                                <Typography variant="h2" className="ml-display-3 text-left mb-0">
                                    Featured Experts
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={4} className="mb-3 text-lg-right d-flex justify-content-end">
                                <FormControl variant="outlined">
                                    <InputLabel id="demo-simple-select-outlined-label">Select Function</InputLabel>
                                    <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Select Function">
                                        <MenuItem value="">Select Function</MenuItem>
                                        <MenuItem value={10}>Tendsfsdfsdfsdf</MenuItem>
                                        <MenuItem value={20}>Twentydsfsdfsdfsdfsdf</MenuItem>
                                        <MenuItem value={30}>Thirtydsfsdfsdsdf</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={3}>
                    <Grid item xs={12} md={10}>
                        <Grid container justify="center">
                            <Grid item xs={12} md={12} className="mb-3">
                                <Box component="div" className="main-card user-card  user-mini-card expert-card">
                                    <Avatar alt="Remy Sharp" src={imgUser} />
                                    <CardContent className="py-0 px-3">
                                        <Typography component="h1" variant="h1" className="user-title-name d-flex">
                                            <Link>Joe Smith</Link>
                                            <Box className="pl-2">
                                                <Box component="span">
                                                    <img src={expertBadge} alt="expertBadge" />
                                                </Box>
                                            </Box>
                                        </Typography>
                                        <Typography component="h6" className="user-short-info mb-1">
                                            Strategic Marketer &amp; Seller | 20-Years Media Industry Veteran
                                        </Typography>
                                        <Typography component="h5" className="user-site-link mb-1">
                                            <Link href="#" onClick={preventDefault} className="text-black">
                                                MarketLync<sup>&reg;</sup>, Inc.
                                            </Link>
                                        </Typography>
                                        <Grid container direction="row" alignItems="center" className="user-inline-list mb-3">
                                            <Grid item>
                                                Primary Function:
                                                <Link href="#" onClick={preventDefault} className="text-black">
                                                    Marketing
                                                </Link>
                                            </Grid>
                                            <Grid item>
                                                Primary Industry:
                                                <Link href="#" onClick={preventDefault} className="text-black">
                                                    Media
                                                </Link>
                                            </Grid>
                                        </Grid>

                                        <Box component="div">
                                            <CardContent className="card-top w-100 d-flex justify-content-between aign-items-center">
                                                <Typography variant="h5" component="h2" className="title">
                                                    Professional Bio
                                                </Typography>
                                            </CardContent>

                                            <Typography variant="body2" color="textSecondary" component="p" className="mb-3">
                                                Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor ac odio mauris justo. Luctus arcu, urna praesent at id quisque ac. Arcu es massa vestibulum malesuada, integer vivamus elit eu mauris eus, cum eros quis aliquam wisi. Nulla wisi…
                                            </Typography>
                                        </Box>

                                        <Box className="static-expert-list">
                                            <Grid container spacing={2}>
                                                <Grid item xs>
                                                    <Box className="exp-card">
                                                        <Box className="head-1">
                                                            <Box className="icon-box">
                                                                <FontAwesomeIcon icon={faVideo} size="lg" />{' '}
                                                            </Box>
                                                            <Box className="number-box">450</Box>
                                                        </Box>
                                                        <Typography component="p" className="stat">
                                                            1:1 Consultations
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <Box className="exp-card">
                                                        <Box className="head-1">
                                                            <Box className="icon-box">
                                                                <FontAwesomeIcon icon={faUserShield} size="lg" />{' '}
                                                            </Box>
                                                            <Box className="number-box">450</Box>
                                                        </Box>
                                                        <Typography component="p" className="stat">
                                                            Private Answers
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <Box className="exp-card">
                                                        <Box className="head-1">
                                                            <Box className="icon-box">
                                                                <FontAwesomeIcon icon={faBroadcastTower} size="lg" />{' '}
                                                            </Box>
                                                            <Box className="number-box">450</Box>
                                                        </Box>
                                                        <Typography component="p" className="stat">
                                                            Public Answers
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <Box className="exp-card">
                                                        <Box className="head-1">
                                                            <Box className="icon-box">
                                                                <FontAwesomeIcon icon={faQuestion} size="lg" />{' '}
                                                            </Box>
                                                            <Box className="number-box">450</Box>
                                                        </Box>
                                                        <Typography component="p" className="stat">
                                                            Questions
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <Box className="exp-card">
                                                        <Box className="head-1 star-rating">
                                                            <Box className="rating">
                                                                <FontAwesomeIcon icon={faStar} size="sm" />
                                                                <FontAwesomeIcon icon={faStar} size="sm" />
                                                                <FontAwesomeIcon icon={faStar} size="sm" />
                                                                <FontAwesomeIcon icon={faStar} size="sm" />
                                                                <FontAwesomeIcon icon={faStar} size="sm" />{' '}
                                                            </Box>
                                                            <Box className="number-box small">/ 450</Box>
                                                        </Box>
                                                        <Typography component="p" className="stat">
                                                            Avg. User Rating
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </CardContent>
                                </Box>
                                {/* start */}
                            </Grid>
                            <Grid item xs={12} md={12} className="mb-3">
                                <Box component="div" className="main-card user-card  user-mini-card expert-card">
                                    <Avatar alt="Remy Sharp" src={imgUser} />
                                    <CardContent className="py-0 px-3">
                                        <Typography component="h1" variant="h1" className="user-title-name d-flex">
                                            <Link>Joe Smith</Link>
                                            <Box className="pl-2">
                                                <Box component="span">
                                                    <img src={expertBadge} alt="expertBadge" />
                                                </Box>
                                            </Box>
                                        </Typography>
                                        <Typography component="h6" className="user-short-info mb-1">
                                            Strategic Marketer &amp; Seller | 20-Years Media Industry Veteran
                                        </Typography>
                                        <Typography component="h5" className="user-site-link mb-1">
                                            <Link href="#" onClick={preventDefault} className="text-black">
                                                MarketLync<sup>&reg;</sup>, Inc.
                                            </Link>
                                        </Typography>
                                        <Grid container direction="row" alignItems="center" className="user-inline-list mb-3">
                                            <Grid item>
                                                Primary Function:
                                                <Link href="#" onClick={preventDefault} className="text-black">
                                                    Marketing
                                                </Link>
                                            </Grid>
                                            <Grid item>
                                                Primary Industry:
                                                <Link href="#" onClick={preventDefault} className="text-black">
                                                    Media
                                                </Link>
                                            </Grid>
                                        </Grid>

                                        <Box component="div">
                                            <CardContent className="card-top w-100 d-flex justify-content-between aign-items-center">
                                                <Typography variant="h5" component="h2" className="title">
                                                    Professional Bio
                                                </Typography>
                                            </CardContent>

                                            <Typography variant="body2" color="textSecondary" component="p" className="mb-3">
                                                Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor ac odio mauris justo. Luctus arcu, urna praesent at id quisque ac. Arcu es massa vestibulum malesuada, integer vivamus elit eu mauris eus, cum eros quis aliquam wisi. Nulla wisi…
                                            </Typography>
                                        </Box>

                                        <Box className="static-expert-list">
                                            <Grid container spacing={2}>
                                                <Grid item xs>
                                                    <Box className="exp-card">
                                                        <Box className="head-1">
                                                            <Box className="icon-box">
                                                                <FontAwesomeIcon icon={faVideo} size="lg" />{' '}
                                                            </Box>
                                                            <Box className="number-box">450</Box>
                                                        </Box>
                                                        <Typography component="p" className="stat">
                                                            1:1 Consultations
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <Box className="exp-card">
                                                        <Box className="head-1">
                                                            <Box className="icon-box">
                                                                <FontAwesomeIcon icon={faUserShield} size="lg" />{' '}
                                                            </Box>
                                                            <Box className="number-box">450</Box>
                                                        </Box>
                                                        <Typography component="p" className="stat">
                                                            Private Answers
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <Box className="exp-card">
                                                        <Box className="head-1">
                                                            <Box className="icon-box">
                                                                <FontAwesomeIcon icon={faBroadcastTower} size="lg" />{' '}
                                                            </Box>
                                                            <Box className="number-box">450</Box>
                                                        </Box>
                                                        <Typography component="p" className="stat">
                                                            Public Answers
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <Box className="exp-card">
                                                        <Box className="head-1">
                                                            <Box className="icon-box">
                                                                <FontAwesomeIcon icon={faQuestion} size="lg" />{' '}
                                                            </Box>
                                                            <Box className="number-box">450</Box>
                                                        </Box>
                                                        <Typography component="p" className="stat">
                                                            Questions
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <Box className="exp-card">
                                                        <Box className="head-1 star-rating">
                                                            <Box className="rating">
                                                                <FontAwesomeIcon icon={faStar} size="sm" />
                                                                <FontAwesomeIcon icon={faStar} size="sm" />
                                                                <FontAwesomeIcon icon={faStar} size="sm" />
                                                                <FontAwesomeIcon icon={faStar} size="sm" />
                                                                <FontAwesomeIcon icon={faStar} size="sm" />{' '}
                                                            </Box>
                                                            <Box className="number-box small">/ 450</Box>
                                                        </Box>
                                                        <Typography component="p" className="stat">
                                                            Avg. User Rating
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </CardContent>
                                </Box>
                                {/* start */}
                            </Grid>
                            <Grid item xs={12} md={12} className="mb-3">
                                <Box component="div" className="main-card user-card  user-mini-card expert-card">
                                    <Avatar alt="Remy Sharp" src={imgUser} />
                                    <CardContent className="py-0 px-3">
                                        <Typography component="h1" variant="h1" className="user-title-name d-flex">
                                            <Link>Joe Smith</Link>
                                            <Box className="pl-2">
                                                <Box component="span">
                                                    <img src={expertBadge} alt="expertBadge" />
                                                </Box>
                                            </Box>
                                        </Typography>
                                        <Typography component="h6" className="user-short-info mb-1">
                                            Strategic Marketer &amp; Seller | 20-Years Media Industry Veteran
                                        </Typography>
                                        <Typography component="h5" className="user-site-link mb-1">
                                            <Link href="#" onClick={preventDefault} className="text-black">
                                                MarketLync<sup>&reg;</sup>, Inc.
                                            </Link>
                                        </Typography>
                                        <Grid container direction="row" alignItems="center" className="user-inline-list mb-3">
                                            <Grid item>
                                                Primary Function:
                                                <Link href="#" onClick={preventDefault} className="text-black">
                                                    Marketing
                                                </Link>
                                            </Grid>
                                            <Grid item>
                                                Primary Industry:
                                                <Link href="#" onClick={preventDefault} className="text-black">
                                                    Media
                                                </Link>
                                            </Grid>
                                        </Grid>

                                        <Box component="div">
                                            <CardContent className="card-top w-100 d-flex justify-content-between aign-items-center">
                                                <Typography variant="h5" component="h2" className="title">
                                                    Professional Bio
                                                </Typography>
                                            </CardContent>

                                            <Typography variant="body2" color="textSecondary" component="p" className="mb-3">
                                                Lorem ipsum dolor sit amet, sapien etiam, nunc amet dolor ac odio mauris justo. Luctus arcu, urna praesent at id quisque ac. Arcu es massa vestibulum malesuada, integer vivamus elit eu mauris eus, cum eros quis aliquam wisi. Nulla wisi…
                                            </Typography>
                                        </Box>

                                        <Box className="static-expert-list">
                                            <Grid container spacing={2}>
                                                <Grid item xs>
                                                    <Box className="exp-card">
                                                        <Box className="head-1">
                                                            <Box className="icon-box">
                                                                <FontAwesomeIcon icon={faVideo} size="lg" />{' '}
                                                            </Box>
                                                            <Box className="number-box">450</Box>
                                                        </Box>
                                                        <Typography component="p" className="stat">
                                                            1:1 Consultations
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <Box className="exp-card">
                                                        <Box className="head-1">
                                                            <Box className="icon-box">
                                                                <FontAwesomeIcon icon={faUserShield} size="lg" />{' '}
                                                            </Box>
                                                            <Box className="number-box">450</Box>
                                                        </Box>
                                                        <Typography component="p" className="stat">
                                                            Private Answers
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <Box className="exp-card">
                                                        <Box className="head-1">
                                                            <Box className="icon-box">
                                                                <FontAwesomeIcon icon={faBroadcastTower} size="lg" />{' '}
                                                            </Box>
                                                            <Box className="number-box">450</Box>
                                                        </Box>
                                                        <Typography component="p" className="stat">
                                                            Public Answers
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <Box className="exp-card">
                                                        <Box className="head-1">
                                                            <Box className="icon-box">
                                                                <FontAwesomeIcon icon={faQuestion} size="lg" />{' '}
                                                            </Box>
                                                            <Box className="number-box">450</Box>
                                                        </Box>
                                                        <Typography component="p" className="stat">
                                                            Questions
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <Box className="exp-card">
                                                        <Box className="head-1 star-rating">
                                                            <Box className="rating">
                                                                <FontAwesomeIcon icon={faStar} size="sm" />
                                                                <FontAwesomeIcon icon={faStar} size="sm" />
                                                                <FontAwesomeIcon icon={faStar} size="sm" />
                                                                <FontAwesomeIcon icon={faStar} size="sm" />
                                                                <FontAwesomeIcon icon={faStar} size="sm" />{' '}
                                                            </Box>
                                                            <Box className="number-box small">/ 450</Box>
                                                        </Box>
                                                        <Typography component="p" className="stat">
                                                            Avg. User Rating
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </CardContent>
                                </Box>
                                {/* start */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default Expertconsultations;
